<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">Completed Classes</h3>
            <p class="d-block">You can see all completed classes here</p>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mb-6">
          <a href="/tutors" class="btn btn-default btn-sm text-white">Book a Class</a>
        </div>
      </div>
      <div class="dashboard-vendor-list">
        <ul class="list-unstyled pl-0" v-if="completed_classes.length">
          <li v-for="c_class in completed_classes" v-bind:key="c_class.id">
            <div class="dashboard-list-block">
              <div class="row">
                <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-img">
                    <a :href="'/tutors/tutor-details?id='+c_class.tutor.tutor_id">
                      <img :src="c_class.tutor.profile_pic" alt="" class="img-fluid" v-if="c_class.tutor.profile_pic" style="pointer-events:none;">
                      <img src="../../assets/images/dummy-tutor.jpg" alt="" class="img-fluid" v-if="!c_class.tutor.profile_pic" style="pointer-events:none;">
                    </a>    
                  </div>
                </div>
                <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div class="dashboard-list-content">
                    <h3 class="mb-0"><a href="" class="title">{{c_class.tutor.name}} | {{c_class.subject.subject_name}} | {{c_class.grade.name}} Grade</a></h3>
                    <p style="font-size:14px;">Class Time : {{c_class.start_date.split("-").reverse().join("-")}}<span v-if="c_class.package!=1"> to {{c_class.end_date.split("-").reverse().join("-")}}</span>  / {{c_class.time}}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <div class="empty-data" style="margin-left:250px;">No Completed Classes Found</div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
var moment = require('moment-timezone');

export default {
    name: 'completed-classes',
    data(){
      return{
      completed_classes:[],
      grades:[]
      }
    },
    beforeMount(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/listcompletedclass', {headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.message == 'Completed class listed successfully'){
          res.data.data.forEach(booked =>{
            if(booked.is_active == 'Y'){
              let start_timezone = moment.tz(booked.start_date+' '+booked.start_time, booked.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
              let end_timezone = moment.tz(booked.end_date+' '+booked.end_time, booked.tutor.timezone).clone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
              booked.start_date = start_timezone.format('YYYY-MM-DD');
              booked.start_time = start_timezone.format('HH:mm');
              booked.end_date = end_timezone.format('YYYY-MM-DD');
              booked.end_time = end_timezone.format('HH:mm');
              booked.time = this.tConvert(booked.start_time)+' - '+this.tConvert(booked.end_time)
              this.completed_classes.push(booked);
            }
          })
        }
      });
      // this.listGrades();
    },
    methods:{
      tConvert (time) {
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join ('');
      },
    },
}
</script>

<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
}
.dashboard-list-btn {
    padding-right: 29px;
    padding-top: 20px;
    text-align: right;
}
.dashboard-list-img img {
    width: 70%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}
</style>