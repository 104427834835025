<template>
  <div class="testimonials">
    <div class="dashboard-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="dashboard-page-header mb-0 pb-3">
              <h3 class="dashboard-page-title">Slider</h3>
              <p class="d-block">Add or Delete Slider</p>
            </div>
          </div>
        </div>
        <div class="row">   
          <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 setbtn">
              <v-btn color="primary" dark @click="dialog=true" class="btn btn-default text-right"> ADD SLIDER </v-btn>
          </div>
        </div> <br>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card request-list-table table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th rowspan="1" colspan="1" style="width: 10px;">Sn.</th>
                    <th rowspan="1" colspan="1" class="text-center">Image</th>
                    <th rowspan="1" colspan="1">Name</th>
                    <th rowspan="1" colspan="1" class="text-center">Show/Hide</th>
                    <th rowspan="1" colspan="1">Action</th>
                  </tr>
                </thead>
                <tbody v-if="sliders.length">
                  <tr v-for="(slider,index) in sliders" :key="index">
                    <td style="width: 10px;">{{index+1}}</td>
                    <td v-if="slider.thumbnail" class="text-center"><img :src="slider.thumbnail" height="50px" style="width: 80px;pointer-events:none;"></td>
                    <td v-if="!slider.thumbnail" class="text-center"><img height="50px" width="80px" src="../../assets/images/dummy-testi.png" style="pointer-events:none;"></td>
                    <td>{{slider.name}}</td>
                    <td class="text-center">  
                      <input type="checkbox" class="ml-5" id="home_display" @change="moveToHome($event, slider, 'home_display')" v-model="slider.home_flag"></td>
                    <td>
                      <a v-on:click.prevent="editSlider(slider,index)" class="btn btn-outline-violate btn-xs mb-1">EDIT</a>
                      <a v-on:click.prevent="deleteSlider(slider.id,index)" class="btn btn-outline-pink btn-xs">DELETE</a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="empty-data">
                    <td></td>
                    <td></td>
                    <td>No sliders listed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <!-- <template v-slot:activator="{on, attrs}">
        </template> -->
        <v-card>
          <v-card-title>
            <span class="text-h5" v-if="record.type!='EDIT'">Add Slider</span>
            <span class="text-h5" v-if="record.type=='EDIT'">Edit Slider</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation v-model="valid">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="6" class="">
                    <label for="name" class="mb-1">Name<span class="red--text"><strong>* </strong></span></label>
                    <v-text-field v-model="slider.name" :rules="[v => !!v || 'Select name']" outlined></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="ml-3">
                    <label for="thumbnail" class="mb-1">Thumbnail<span class="red--text"><strong>* </strong></span></label>
                    <v-file-input class="mt-1" accept="image/*" v-model="slider.thumbnail" prepend-icon="mdi-camera" placeholder='Choose file' outlined></v-file-input>
                  </v-col>
                </v-row >
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-btn class="mr-2" color="primary" dark @click="cancelDialog">CANCEL</v-btn>
                    <v-btn type="submit" color="teal" dark v-on:click.prevent="submitSlider" v-if="!loading&&record.type!='EDIT'">ADD</v-btn>
                    <v-btn type="submit" color="teal" dark v-on:click.prevent="toEditSlider" v-if="!loading&&record.type=='EDIT'">SAVE</v-btn>
                    <v-btn color="teal" dark v-if="loading">...</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to delete these records? This process cannot be undone.</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="confirmDelete">Delete</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AWS from "aws-sdk";
import axios from 'axios';
export default {
  name: "admin-shop",
  data(){
    return {
      slider:{
        name:null,
        thumbnail:null
      },
      record:{
        id:null,
        index:null,
      },
      selected_slider:null,
      sliders:[],
      valid:false,
      loading:false,
      dialog:false,
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_sliders',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res=>{
      if(res.data.message == 'Sliders listed successfully'){
        res.data.data.forEach(test => {
          if(test.status === 1){
            this.sliders.push(test);
          }
        })
      }
    })
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    cancelDialog(){
      this.$refs.form.reset()
      this.dialog = false;
    },
    submitSlider(){
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        this.loading = true;
        if(this.slider.thumbnail){
          var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
          var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
          let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
        
          AWS.config.update({
            accessKeyId: keys.accessKeyId,
            secretAccessKey: keys.secretAccessKey,
            region: 'ap-south-1'
          });

          let s3BucketName = "tution-site"; 
          var thumbnail = new Blob([this.slider.thumbnail], { type: "application/image" });
          var extension = this.slider.thumbnail.name.split('.')[this.slider.thumbnail.name.split('.').length-1]
          var d = new Date();
          var month = d.getMonth() + 1;
          var day = d.getDate();
          var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          var timestamp = Math.floor(Date.now() / 1000);
          var s3obj = new AWS.S3({
              params: {
                Bucket: s3BucketName,
                Key:
                  'slider/thumb/'+ current_date+"/"+this.slider.thumbnail.name + timestamp + "."+extension,
                ACL: "public-read",
              },
            });
            var upload = s3obj.upload({ Body: thumbnail });
            upload.on("httpUploadProgress", function (evt) {
              // uploadStatus == 0;
              //checkConnetionOffnOn(upload);
              var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
              console.log(per_uploaded);
            }).send((err, data) => {
              this.slider.thumbnail = data.Location;
              this.addSlider()
            });
        }else{
          this.addSlider();
        }
      }
    },
    addSlider(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/add_slider',this.slider,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Slider added'){
          this.loading = false;
          this.sliders.unshift(res.data.slider);
          this.dialog = false;
        }
      });
    },
    editSlider(slider,index){
      this.slider.name = slider.name;
      this.selected_slider = slider;
      this.record.id = slider.id;
      this.record.index = index;
      this.record.type = 'EDIT';
      this.dialog = true;
    },
    toEditSlider(){
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        this.loading = true;
        if(this.slider.thumbnail != null){
          var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
          var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
          let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
          
          AWS.config.update({
            accessKeyId: keys.accessKeyId,
            secretAccessKey: keys.secretAccessKey,
            region: 'ap-south-1'
          });

          let s3BucketName = "tution-site"; 
          var thumbnail = new Blob([this.slider.thumbnail], { type: "application/image" });
          var extension = this.slider.thumbnail.name.split('.')[this.slider.thumbnail.name.split('.').length-1]
          var d = new Date();
          var month = d.getMonth() + 1;
          var day = d.getDate();
          var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
          var timestamp = Math.floor(Date.now() / 1000);
          var s3obj = new AWS.S3({
              params: {
                Bucket: s3BucketName,
                Key:
                  'slider/thumb/'+ current_date+"/"+this.slider.thumbnail.name + timestamp + "."+extension,
                ACL: "public-read",
              },
            });
            var upload = s3obj.upload({ Body: thumbnail });
            upload.on("httpUploadProgress", function (evt) {
              // uploadStatus == 0;
              //checkConnetionOffnOn(upload);
              var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
              console.log(per_uploaded);
          }).send((err, data) => {
            this.submitEditForm(data.Location);
          });
        }else{
          this.submitEditForm(this.selected_slider.thumbnail);
        }
      }
    },
    submitEditForm(data){
      this.slider.thumbnail=data;
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/slider/'+this.record.id,this.slider,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message === 'Slider updated'){
          this.loading = false;
          this.dialog = false;
          this.sliders[this.record.index].name = this.slider.name;
          this.sliders[this.record.index].thumbnail = this.slider.thumbnail;
          this.record.type = null;
          this.record.index= null;
          this.record.id = null;
          // this.valid = false;
          this.$refs.form.reset()
        }
      });
    },
    moveToHome(event, slider){
      let value = event.target.checked?1:0;
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/slider/'+slider.id,{checked:value},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message === 'Slider updated'){
          this.home_flag = value;
        }
      });
    },
    deleteSlider(id,index){
      this.$refs['confirm-modal'].show();
      this.record.id = id;
      this.record.index = index;
    },
    confirmDelete(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/disable_slider/'+this.record.id,{},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Slider disabled'){
          this.$refs['confirm-modal'].hide();
          this.sliders.splice(this.record.index,1);
        }
      })
    }
  }
}
</script>
<style scoped>
p:last-child {
    margin: 0px;
}
.setbtn a.btn {
    float: right !important;
    width: 200px;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.request-list-table .table {
    font-size: 14px;
}
.table {
    margin-bottom: 0px;
    color: #6f6d72;
}
.request-list-table table th {
    background-color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252328;
}
.table thead th {
    vertical-align: bottom;
    font-weight: normal;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    padding: 8px;
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-xs {
    padding: 4px 8px !important;
    font-size: 11px !important;
    line-height: 1.5;
}
.btn-outline-violate {
    color: #9047fb;
    background-color: #f2e9ff;
    border-color: #9047fb;
}
.btn-outline-pink {
    color: #ff7b7b;
    background-color: #ffeded;
    border-color: #ff7b7b;
}
.btn-outline-violate:hover {
    color: #fff;
    background-color: #9047fb;
    border-color: #9047fb;
}
.btn-outline-pink:hover {
    color: #fff;
    background-color: #ff7b7b;
    border-color: #ff7b7b;
}
.btn-default:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled):active, .show>.btn-default.dropdown-toggle {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.form-control {
    display: block;
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #252328;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cfced0;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    /* background: #fff url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) no-repeat right .75rem center; */
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}
.btn-success {
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.red--text{
  color: red;
}
</style>
