<template>
  <div class="contact-us">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
                    <!-- contact-section-title -->
                    <div class="text-center">
                        <p class="lead">If you're unsure about what type of course or coaching will work best for you, just tell us a little more about your needs. We will get back to you as soon as possible with the answers you need!
                        </p>
                    </div>
                    <!-- /.contact-section-title -->
                </div>
                <div class="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-12">
                    <v-form ref="form" lazy-validation v-model="valid">
                        <!-- form -->
                        <div class="contact-form">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb10 ">
                                    <h3>Get in touch</h3>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
                                    <!-- Text input-->
                                    <div class="form-group service-form-group">
                                        <label class="control-label sr-only" for="fname"></label>
                                        <v-text-field id="fname" v-model="email.fname" type="text" name="fname" placeholder="First Name" :rules="[v => !!v || 'Required!']" outlined></v-text-field>
                                    </div>
                                </div>
                                <!-- Text input-->
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  ">
                                    <div class="form-group service-form-group">
                                        <label class="control-label sr-only" for="lname"></label>
                                        <v-text-field id="lname" type="text" v-model="email.lname" name="lname" placeholder="Last Name" :rules="[v => !!v || 'Required!']" outlined></v-text-field>
                                    </div>
                                </div>
                                <!-- Text input-->
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                    <div class="form-group service-form-group">
                                        <label class="control-label sr-only" for="email"></label>
                                        <v-text-field id="email" type="email" v-model="email.email" name="email" placeholder="Email" :rules="[v => !!v || 'Required!']" outlined></v-text-field>
                                    </div>
                                </div>
                                <!-- Text input-->
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                    <div class="form-group service-form-group">
                                        <label class="control-label sr-only" for="phone"></label>
                                        <v-text-field id="phone" v-model="email.phone" type="text" name="phone" placeholder="Phone" :rules="[v => !!v || 'Required!']" outlined></v-text-field>
                                    </div>
                                </div>
                                <!-- select -->
                              
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                    <!-- textarea -->
                                    <div class="form-group">
                                        <label class="control-label sr-only" for="message"></label>
                                        <v-textarea v-model="email.subject" id="message" name="message" rows="3" placeholder="Messages" :rules="[v => !!v || 'Required!']" outlined></v-textarea>
                                    </div>
                                </div>
                                <!--button -->
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                                    <button v-on:click.prevent="contactUs" name="singlebutton" class="btn btn-default">submit</button>
                                </div>
                            </div>
                        </div>
                        <!-- /.form -->
                    </v-form>
                </div>
            </div>
        </div>
    </div>
    <div class="space-medium bg-white">
        <div class="container">
            <div class="row">
                <!-- contact-block -->
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="contact-block">
                        <div class="contact-icon"><i class="far fa-user-circle"></i></div>
                        <div class="contact-content">
                            <h3>Customer Support</h3>
                            <p>Call our 24-hour helpline.</p>
                            <p><strong>Phone number:</strong><span style="color:#0079df !important"> +44 7984 559199</span>
                            <br>  <strong>Director : </strong><span style="color:#0079df !important">+1(246)8283460</span>
                                <br> <strong>Email Us:</strong><span style="color:#0079df !important"> admin@fromyourtutor.com</span></p>
                        </div>
                    </div>
                </div>
                <!-- /.contact-block -->
                <!-- contact-block -->
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="contact-block">
                        <div class="contact-icon"><i class="fa fa-map-marker-alt"></i></div>
                        <div class="contact-content">
                            <h3>Our Address</h3>
                            <p>Our offices are located in India.</p>
                            <!-- <p><strong>Address:</strong> Karakurissi , Mannarkkad, Palakkad, Kerala, India</p> -->
                        </div>
                    </div>
                </div>
                <!-- /.contact-block -->
                <!-- contact-block -->
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="contact-block">
                        <div class="contact-icon"><i class="far fa-envelope"></i></div>
                        <div class="contact-content">
                            <h3>Other Enquiries</h3>
                            <p>Please contact us at the email below for all other inquiries.</p>
                            <p><strong>Email Us:</strong> <span style="color:#0079df !important">admin@fromyourtutor.com</span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- /.contact-block -->
            </div>
        </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="fa fa-check-circle text-success" style="font-size:40px;" aria-hidden="true"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Success</h4>	
        <div class="modal-body text-center">
          <p>Your message has sent to the fromyourtutor admin.They will contact you soon.</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">OK</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import AWS from "aws-sdk";
export default {
  name: "Contact",
  data(){
    return {
      email:{
        fname:null,
        lname:null,
        email:null,
        subject:null,
        phone:null,
      },
      valid:false,
    }
  },
  methods:{
    hideModal(){
      this.$refs['confirm-modal'].hide();
      // this.resetModal();
    },
    resetModal(){
      this.email.fname = null;
      this.email.lname = null;
      this.email.email = null;
      this.email.subject = null;
      this.email.phone = null;
    },
    contactUs(){
      this.valid = this.$refs.form.validate();
      var self = this;
      if(this.valid){
        var access_key = process.env.VUE_APP_SES_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_SES_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        })
        var params = {
          Destination: { /* required */
            // CcAddresses: [
            //   'info@fromyourtutor.com',
            //   /* more items */
            // ],
            ToAddresses: [
              'admin@fromyourtutor.com',
              /* more items */
            ]
          },
          Message: { /* required */
            Body: { /* required */
              Html: {
                Charset: "UTF-8",
                Data: '<table style="width: 600px; margin: 0 auto;" width="600"><tr><td style="text-align: center;"><br><img src="https://tution-site.s3.ap-south-1.amazonaws.com/test/logo.png" alt="logo"><br></td></tr><tr><td><br><table cellpadding="10" style="background:#f5f5f5; border-radius: 15px; width:560px; margin:0 auto; "><tr><td style="font-family: arial; font-size:18px; text-align: center; color: #086e00;"><br>Contact Mail</td></tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;"><strong>Name:'+self.email.fname+' '+self.email.lname+'</strong><br></td></tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;"><strong>Email:'+self.email.email+'</strong><br></td></tr><tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;"><strong>Phone:'+self.email.phone+'</strong><br></td></tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;"><strong>Subject:'+self.email.subject+'</strong><br></td></tr></tr></table></td></tr></table>'
              },
              // Text: {
              //   Charset: "UTF-8",
              //   Data: this.email.subject,
              // }
            },
            Subject: {
              Charset: 'UTF-8',
              Data: 'Contact us'
            }
            },
          Source: 'info@fromyourtutor.com', /* required */
          ReplyToAddresses: [
            this.email.email,
            /* more items */
          ],
        };

        // Create the promise and SES service object
        var sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();

        // Handle promise's fulfilled/rejected states
        sendPromise.then(
          function(data) {
            if(data.MessageId){
              self.$refs['confirm-modal'].show();
            }
          }).catch(
          function(err) {
            console.error(err, err.stack);
        });
      }
    }
  }
}
</script>
<style scoped>
.content {
    padding-top: 40px;
    padding-bottom: 60px;
}
.lead {
    font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.contact-form h3{
    font-size: 20px;
    line-height: 28px;
}
button.btn {
    font-size: 13px;
    height: 50px;
    font-weight: 400;
}

.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.btn-default:hover {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.space-medium {
    padding-top: 40px;
    padding-bottom: 80px;
}
.contact-block {
    margin-bottom: 25px;
}
.contact-icon {
    float: left;
    font-size: 26px;
    display: inline-block;
    border: 2px solid #0079df;
    border-radius: 100%;
    padding: 21px;
    height: 70px;
    width: 70px;
    line-height: 1.2;
    color: #0079df;
    text-align: center;
}
.contact-content {
    font-size: 15px;
    font-weight: 400;
    padding-left: 100px;
}
.contact-content h3{
    font-size: 20px;
    line-height: 28px;
}
.contact-content p{
    margin: 0px 0px 20px 0px;
    -webkit-font-smoothing: antialiased;
}
</style>