<template>
  <div class="admin_header">
    <div class="dashboard-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-10 col-lg-10 col-md-8 col-sm-12 col-6">
            <div class="header-logo">
              <a href="/"><img src="../../assets/images/logo.png" style="pointer-events:none;"></a>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 text-right col-md-4 col-sm-12 col-6">
            <div class="user-vendor">
              <div class="dropdown">
                <a class="dropdown-toggle" id="dropdownMenuButton" @click="dropdown=!dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="user-icon">
                    <img src="../../assets/images/dashboard-profile.jpg" v-if="!profile_pic" alt="" class="rounded-circle mb10" style="pointer-events:none;">
                    <img :src="profile_pic" alt="" class="rounded-circle" v-if="profile_pic" width="100" height="70" style="pointer-events:none;">
                  </span>
                  <span class="user-vendor-name">{{tutor.name}}</span> 
                </a>
                <div class=" dashboard-dropdown-menu dropdown-menu" aria-labelledby="dropdownMenuButton" v-if="dropdown">
                  <a class="dropdown-item" href="/cart">Cart</a>
                  <a class="dropdown-item" href="" v-on:click="logout">Log Out</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-expand-lg">
      <button class="navbar-toggler" type="button" data-toggle="offcanvas">
        <span id="icon-toggle" class="fa fa-bars"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'tutor-header',
  data(){
    return {
      tutor:null,
      dropdown:false,
      profile_pic:null,
    }
  },
  beforeMount(){
    this.tutor = (this.$cookies.get('tution-tutor'))?this.$cookies.get('tution-tutor'):null;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.tutor.token}}).then(res => {
      if(res.data.message == 'User found'){
        this.profile_pic = res.data.data.profile_pic;
      }
    })
  },
  methods:{
    logout(e){
      e.preventDefault();
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/logout',{headers:{"Authorization":'Bearer '+this.tutor.token}}).then(res => {
      if(res.data.status == 1){
          this.$cookies.remove("cart-session-token");
          this.$cookies.remove("cart-session-key");
          this.$cookies.remove("tution-tutor");
          this.$router.replace('/tutor_login')
        }
      });
    }
  }
}
</script>

<style scoped>
.dashboard-header {
    background-color: #fff;
    padding-bottom: 12px;
    position: fixed;
    z-index: 9;
    border-bottom: 1px solid #f1f1f1;
    width: 100%;
}
.dashboard-header .header-logo {
    padding-top: 5px;
}
.header-logo {
    width: 200px;
    padding-right: 15px;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}

a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
.user-vendor {
    font-size: 18px;
    color: #252328;
}
a {
    color: #252328 !important;
    -webkit-font-smoothing: antialiased;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d !important;
    text-decoration: none;
}
.user-icon {
    height: 35px;
    width: 35px;
    display: inline-block;
    margin-right: 12px;
}
.user-vendor-name {
    font-size: 14px;
    position: relative;
    bottom: 3px;
}
.dashboard-dropdown-menu {
    position: absolute !important;
    top: 47px !important;
    left: 40px;
    border-radius: 0px 0px 3px 3px;
    border: 1px solid rgb(235, 235, 235);
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: 4px 66px 4px 20px;
    clear: both;
    font-weight: 400;
    color: #626262;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 14px;
}
.dropdown-toggle::after {
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: 6px;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.navbar-expand-lg {
    position: relative;
    top: 0px;
    z-index: 8;
    width: 100%;
}
.dropdown-menu {
    display: block;
}
.dropdown-item:focus, .dropdown-item:hover {
    color: #0079df !important;
    background-color: #e1e1e1;
    text-decoration: none;
}
</style>