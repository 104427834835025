<template>
  <div class="about-us">
    <div class="space-vision pb-0">
        <div class="space-medium">
        <div class="container">
            <div class="row">
                <!--  about-details  -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                    
                    


    <h2> Refund Policy</h2>
    <p>Updated at 2022-07-25</p>
    <h2>Definitions and key terms</h2>
    <p>To help explain things as clearly as possible in this Return &amp; Refund Policy, every time any of these terms are referenced, are strictly defined as:</p>

    <ul  >
        <!---->
        <li>
            <strong>Company:</strong> when this policy mentions “Company,” “we,” “us,” or “our,” it refers to <a>fromyourtutor</a> that is responsible for your information under this Return &amp; Refund Policy.<br />
        </li>
        <li>
            <strong>Customer:</strong> refers to the company, organization or person that signs up to use the Service to manage the relationships with your consumers or service users.<br />
        </li>
        <li>
            <strong>Device:</strong> any internet connected device such as a phone, tablet, computer or any other device that can be used to visit and use the services.<br />
        </li>
        <li>
            <strong>Service:</strong> refers to the service provided by <a>fromyourtutor</a> as described in the relative terms (if available) and on this platform.<br />
        </li>
        <li>
            <strong>Website:</strong> <a>fromyourtutor</a>’s site, which can be accessed via this URL: <a>.</a><br />
        </li>
        <li>
            <strong>You:</strong> a person or entity that is registered with <a>fromyourtutor</a> to use the Services.<br />
        </li>
    </ul>
    <p><br /></p>
    <h2> Refund Policy</h2>
    <p>
        
            Thanks for shopping at <a>fromyourtutor</a>. We appreciate the fact that you like to buy the stuff we build. We also want to make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our
            products. <br />
            <br />
            As with any shopping experience, there are terms and conditions that apply to transactions at <a>fromyourtutor</a>. We’ll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or making
            a purchase at <a>fromyourtutor</a>, you agree to the terms set forth below along with <a>fromyourtutor</a>’s&nbsp;Privacy Policy. <br />
            <br />
            If there's something wrong with the product you bought (it means the product is corrupted or damaged file), or not recive the product, you have () to issue a refund.
            <br />
        
        
    </p>


    <h2>Your Consent</h2>
    <p>By using our platform, registering an account, or making a purchase, you hereby consent to our Refund Policy and agree to its terms.</p>
    <p><br /></p>
    <h2>Changes To Our Refund Policy</h2>
    <p>
        
            Should we update, amend or make any changes to this document so that they accurately reflect our Service and policies. Unless otherwise required by law, those changes will be prominently posted here. Then, if you continue to use
            the Service, you will be bound by the updated Return &amp; Refund Policy. If you do not want to agree to this or any updated Return &amp; Refund Policy, you can delete your account.
        
    </p>

    <h2>Contact Us</h2>
    <p>
        If, for any reason, You are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.
    </p>
    <ul>
        <li >
            Via Email: <a>fromyourtutor@gmail.com</a>
        </li>
        <li >
            Via Phone Number: <a>+44 7984 559199</a>
        </li>
        <li >
            Via this Link: <a>https://fromyourtutor.com/contact-us</a>
        </li>
        <!---->
    </ul>



                </div>
            </div>
        </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Refund"
}
</script>

<style scoped>
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
/* .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #bebbc1;
    content: "\f105";
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
} */
.circle-icon {
    height: 125px;
    width: 125px;
    font-size: 60px;
    line-height: 2.1;
    padding: 0px 33px;
    color: #fff;
    display: inline-block;
}
.bg-default {
    background-color: #0079df !important;
}
h2 {
    font-size: 28px;
    line-height: 38px;
}
.lead{
  font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.metrics {
    padding: 70px !important;
    text-align: center;
}
.metrics-title {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
}
.metrics-subtitle {
    font-size: 56px;
    line-height: 64px;
}
.space-medium{
    padding-top: 50px;
    padding-bottom: 80px;
}
.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 36px;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 15px;
}
.section-title h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
}
.heading-btm-line {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #0079df;
    padding-bottom: 10px;
    display: inline-block;
}
p:last-child {
    margin: 0px;
}
</style>