<template>
  <div class="about-us">
    <div class="space-vision pb-0">
      <div class="space-medium">
        <div class="container">
          <div class="row">
            <!--  about-details  -->
            <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/QBO6TSvOFww" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h4>WHY FROM YOUR TUTOR?</h4>
              <p>We know that learning is easier when you have an excellent teacher. That's why most of our educators are hand picked and trained. Our instructors are passionate about the subjects they teach and bring this enthusiasm into their classes, webinars and courses.</p>
              <p>From Your Tutor  is an academic Educational Support Service that provides top notch range of tutoring in a variety of subjects catering to all ages. From our Tiny Tots for 2-4 year olds to our adult classes and counselling sessions. Along with core curriculum subjects we also provide Islamic and Arabic courses (Quran, Sunnah upon the understanding of Pious Predecessors)  DIY courses such as the Hijama: self help and speech therapy services. We offer both private and small group sessions, so you can choose exactly the tutoring style you like best.</p>
              <p>Our tutor agency is distinguished by the high level of service and support we provide to our students, deep knowledge of school and university entry requirements, a unique international network , and most importantly, the high calibre of our tutors.</p>
              <p>Our FREE Minis Meet session on Thursdays are always an attraction for parents and children who looking for socialising and developing skills .All these under one umbrella. You'll be given a choice of private or group classes. </p>
              <p>The classes are made to be as flexible as possible with your preferred days and times. The speed of classes depend on the students abilities.</p>
              <h4>WIDE VARIETY OF COURSES</h4>
              <p>We excel at preparing students for a wide range of subjects and school exams, according to the requirements of diverse programs everything based on Islamic Values (Quran, Sunnah upon the understanding of Pious Predecessors)</p>
              <h4>ISO CERTIFICATION</h4>
              <p><img src="../assets/images/iso-cert.jpg" alt=""  style="max-width:1000px; margin-top: 10px;"></p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about-us"
}
</script>

<style scoped>
h4{
    font-weight: bold;
}
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
/* .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #bebbc1;
    content: "\f105";
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
} */
.circle-icon {
    height: 125px;
    width: 125px;
    font-size: 60px;
    line-height: 2.1;
    padding: 0px 33px;
    color: #fff;
    display: inline-block;
}
.bg-default {
    background-color: #0079df !important;
}
h2 {
    font-size: 28px;
    line-height: 38px;
}
.lead{
  font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.metrics {
    padding: 70px !important;
    text-align: center;
}
.metrics-title {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
}
.metrics-subtitle {
    font-size: 56px;
    line-height: 64px;
}
.space-medium{
    padding-top: 50px;
    padding-bottom: 80px;
}
.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 36px;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 15px;
}
.section-title h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
}
.heading-btm-line {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #0079df;
    padding-bottom: 10px;
    display: inline-block;
}
p:last-child {
    margin: 0px;
}
</style>