<template>
  <div class="tutor_reg">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body">
                <v-form ref="form" lazy-validation v-model="valid">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Surname</label>
                      <v-text-field type="text" v-model="register.surname" outlined ></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.email" :rules="emailRules" outlined :error-messages="auth_msg" @keydown.space.prevent></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Password<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="password" v-model="register.password" :rules="passwordRules" outlined></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Country<span class="red--text"><strong>* </strong></span></label>
                       <v-autocomplete v-model="tutor.country" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" @input="getRegions()" searchable outlined clearable></v-autocomplete>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select preferred subjects<span class="red--text"><strong>* </strong></span></label>
                      <v-autocomplete  v-model="register.subjects" v-on:input="limiter" :rules="[v => !!v || 'Select subjects']" :items="subjects" item-text="text" item-value="value" multiple chips clearable deletable-chips searchable outlined></v-autocomplete>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div class="row">
                        <div class="col-lg-4 pt-0">
                          <label for="inputText3" class="col-form-label">Country code</label>
                          <v-autocomplete v-model="tutor.code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                        </div>
                        <div class="col-lg-8 pt-0">
                          <label for="inputText3" class="col-form-label">Contact num</label>
                          <v-text-field type="text" v-model="register.mobile" outlined :error-messages="mob_msg"></v-text-field>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select gender</label>
                      <v-autocomplete v-model="register.gender" :items="gender" item-text="text" item-value="value" outlined ></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Region</label>
                       <v-autocomplete v-model="register.region" :items="regions" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select highest educational qualifcation</label>
                       <v-autocomplete v-model="register.qualification" :items="qualifications" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select preferred grades</label>
                      <v-autocomplete v-model="register.grades" :items="grades" item-text="text" item-value="value" v-on:input="limiter" multiple searchable chips clearable deletable-chips outlined ></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Select language</label>
                      <v-autocomplete :items="languages" item-text="text" item-value="value" v-model="register.language" outlined ></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Tell me about yourself</label>
                      <v-textarea v-model="register.about" outlined ></v-textarea>
                    </div>
                    <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Upload Univercity Certificate<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" :rules="[v => !!v || 'Upload univercity certificate']" outlined ></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label for="inputText3" class="col-form-label">Upload Demo Class Video<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" outlined ></v-text-field>
                    </div> -->

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <b-button type="submit" class="btn btn-default" v-if="!loading" v-on:click.prevent="postRegister">Submit</b-button>
                        <b-button type="button"  class="btn btn-default" v-if="loading"><i class="fa fa-spinner fa-spin"></i></b-button>
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Registration successful</div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "tutor-registration",
  data(){
    return {
      valid: true,
      register:{
        name:null,
        surname:null,
        mobile:null,
        email:null,
        password:null,
        country:null,
        region:null,
        qualification:null,
        language:null,
        gender:null,
        grades:null,
        subjects:null,
        about:null,
      },
      tutor:{
        code:null,
        mobile:null,
        country:null,
      },
      grades:[],
      gender:[
        {value:1, text:'Male'},
        {value:2, text:'Female'},
        {value:3, text:'Other'}
      ],
      subjects:[],
      languages:[],
      countries:[],
      regions:[],
      qualifications:[],
      codes:[],
      auth_error: false,
      auth_msg: null,
      mob_error:false,
      mob_msg:null,
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number',
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      loading:false
    }
  },
  beforeMount(){
    this.getCountries();
    this.getLanguages();
    this.getSubjects();
    this.getGrades();
    this.getQualification();
  },
  methods:{
    openModal: function (type = "") {
      this.open = (type == "")? this.open : type;
      if (this.open == "success") {
        this.$refs["success"].show();
      }
    },
    limiter(e){
      if(e.length>4){
        e.pop();
      }
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },
    getLanguages(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/languages',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Languages.forEach(language => {
          if(language.is_active == 'Y'){
            this.languages.push({value:language.language_id,text:language.name});
          }
        })
      })
    },
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          if(subject.is_active == 'Y'){
            this.subjects.push({value:subject.subject_id, text:subject.subject_name});
          }
        })
      })
    },
    getRegions(){
      this.register.country = this.tutor.country;
      this.listRegions(this.register.country);
      this.countries.forEach(country => {
        if(country.value == this.register.country){
          this.tutor.code = {value:country.code, text:country.short+ '-' + country.code};
        }
      });
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.tutor.code){
          this.tutor.country = {value:country.value, text:country.text};
        }
      })
      this.register.country = this.tutor.country.value;
      this.listRegions(this.tutor.country.value);
    },
    getGrades(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Grades.forEach(grade => {
          this.grades.push({value:grade.grade_id,text:grade.name});
        })
      })
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    getQualification(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/qualification',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Qualification.forEach(qual => {
          if(qual.is_active == 'Y'){
            this.qualifications.push({value :qual.qualification_id,text:qual.name});
          }
        })
      })
    },

    postRegister(e){
      e.preventDefault();
      this.loading = true;
      this.auth_msg = null;
      this.auth_error = false;
      this.mob_error = false;
      this.mob_msg = null;
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        this.register.country_code = typeof this.tutor.code === 'object'?this.tutor.code.value:this.tutor.code;
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/tutor',this.register,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.status != 0){
            this.openModal("success");
            setTimeout(() => {
              this.$refs["success"].hide();
              this.$router.push('/tutor_login');
            },2000)
          }
          // let student = { token: res.access.token, name:this.register.name}
          // this.$cookies.set("tution-student",student);
          // location.reload();
          // this.$router.push('/student/dashboard');
        }).catch((err) => {
          this.loading = false;
          if(err.response.data.email){
            this.auth_error = true;
            this.auth_msg = 'Email is already registered with us'
          }
          if(err.response.data.mobile){
            this.mob_error = true;
            this.mob_msg = 'Mobile is already registered with us'
          }
        });
      }else{
        console.log('not valid', this.valid);
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
.tutor_reg {
    background-color: #f2f7fb !important;
}
.content {
  padding-top:35px;
  padding-bottom:30px;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.red--text{
  color: red;
}
.btn{
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
}
.btn-default{
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn-default:hover {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
</style>
<style>
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
  min-height: 42px !important;
}
.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width>.v-input__control>.v-input__slot, .v-text-field--outlined>.v-input__control>.v-input__slot {
    align-items: stretch;
    min-height: 45px !important;
}
.v-text-field--outlined, .v-text-field--solo {
    border-radius: 2px !important;
}
/* .v-input--is-focused .v-text-field--outlined{
    color: #252328 !important;
    border-color: #bebebe !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgb(243 243 243) !important;
} */
.v-application--is-ltr .v-text-field--outlined fieldset {
    padding: 14px 16px !important;
}
</style>