<template>
  <div class="admin-gallery">
    <div id="slide-reveal-overlay" v-on:click="closeSlideBar()" style="display: none; position: fixed; top: 0px; left: 0px; height: 100%; width: 100%; z-index: 1048; background-color: rgba(0, 0, 0, 0.5);"></div>
    <div class="dashboard-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="dashboard-page-header mb-0 pb-3">
              <h3 class="dashboard-page-title">Gallery</h3>
              <p class="d-block">Add, Edit or Delete Gallery items</p>
            </div>
          </div>
        </div>
        <div class="row">   
          <div id="slide-panel6" class="slide-panel-light slide-panel">
            <h3 v-if="record.method=='ADD'">Add Image/Video</h3>
            <h3 v-if="record.method=='EDIT'">Edit Imaage/Video</h3>
            <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="inputText1" class="col-form-label">Name<span> </span></label>
                    <v-text-field id="inputText3" type="text" v-model="image.name" :rules="[v => !!v || 'Enter name']" placeholder="Name*" outlined :error-messages="err_msg"></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="inputText2" class="col-form-label">Description<span> </span></label>
                    <v-textarea v-model="image.description" outlined></v-textarea>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="inputText3" class="col-form-label">Upload Image/Video</label>
                    <v-file-input accept="image/*,video/*" v-model="image.path" prepend-icon="mdi-paperclip" :rules="[v => !!v || 'Select item']" outlined></v-file-input>
                    <div v-if="image.uploaded_image" class="ml-1" style="border:1px solid #ddd;width:50px;">
                      <img :src="image.uploaded_image" alt='image' height="50" style="pointer-events:none;">
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <a v-on:click="addProduct" class="btn btn-success" v-if="!loading && record.method=='ADD'">Save</a>
                    <a v-on:click="getConfirmation" class="btn btn-success" v-if="!loading && record.method=='EDIT'">Save</a>
                    <a class="btn btn-success" v-if="loading"><i class="fa fa-spinner fa-spin"></i></a>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 setbtn">
            <a v-on:click="getAddProductBar()" class="btn btn-default" id="trigger6" style="font-size:12px !important;">ADD IMAGES</a>
          </div>
        </div> <br>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card request-list-table table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="images.length">
                  <tr v-for="(image,index) in images" :key="index">
                    <td>{{index+1}}</td>
                    <td>
                      <img :src="image.path" style="width: 80px;pointer-events:none;" v-if="image_extn.includes(image.path.split('.')[image.path.split('.').length-1])">
                      <video width="100" height="60" controls v-if="video_extn.includes(image.path.split('.')[image.path.split('.').length-1])">
                        <source :src="image.path+'#t=0.1'" type="video/mp4">
                      </video>
                    </td>
                    <td>{{image.name}}</td>
                    <td>{{image.description}}</td>
                    <td>
                      <a v-on:click.prevent="editImage(image,index)" class="btn btn-outline-violate btn-xs mr-1">EDIT</a>
                      <a v-on:click.prevent="confirmDelete(image.id,index)" class="btn btn-outline-pink btn-xs">DELETE</a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="empty-data">
                    <td></td>
                    <td></td>
                    <td>No images listed</td>
                  </tr>
                </tbody>
              </table>
              <div class="col pt-0" v-if="images.length">
                <v-pagination v-model="image" :length="length" class="float-left" @input="handlePageChange"></v-pagination>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header hide-backdrop centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p v-if="this.record.method == 'DELETE'">Do you really want to delete these records? This process cannot be undone.</p>
          <p v-if="this.record.method == 'EDIT'">Do you really want to update these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-if="this.record.method == 'DELETE'" v-on:click="deleteProduct">Delete</button>
            <button type="button" class="btn btn-danger" v-if="this.record.method == 'EDIT'" v-on:click="confirmShopEdit">Update</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AWS from "aws-sdk";
import axios from 'axios';
export default {
  name: 'admin-gallery',
  data(){
    return {
      images: [],
      image:{
        name: null,
        description: null,
        path: null,
      },
      update_image:{
        name: null,
        description: null,
        path: null,
      },
      record:{
        id:null,
        index:null,
        method:null,
        type:null
      },
      valid:true,
      loading:false,
      err_msg:'',
      page:1,
      length:0,
      offset: 0,
      limit: 25,
      total: 0,
      image_extn : ["jpg", "gif", "png","jpeg"],
      video_extn : ["mp4", "3gp", "ogg","MOV","mov","MP4","mp4","WMV","wmv","AVI","avi","mkv","MKV","WEBM","webm","HTML5","html5"]
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/gallery?page='+this.page+'&per_page='+this.limit,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      console.log(res);
      if(res.data.message == 'successful listing' &&res.data.gallery.length>0){
        res.data.gallery.forEach(image => {
          this.images.push(image);
        });
        // this.limit = res.data.images.per_page;
        // this.offset = res.data.images.from-1;
        // this.total = res.data.images.total;
        // this.length = Math.ceil(res.data.images.total/res.data.images.per_page);
      }
    })
  },
  methods:{
    openSlider(){
      document.getElementById('slide-panel6').style.right = '0px';
      document.getElementById('slide-panel6').style.width = '275px';
      document.getElementById('slide-reveal-overlay').style.display = 'block';
    },
    closeSlideBar(){
      document.getElementById('slide-panel6').style.right = '-275px';
      document.getElementById('slide-panel6').style.width = '0px';
      document.getElementById('slide-reveal-overlay').style.display = 'none';
    },
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    editImage(image,index){
      this.record.id = image.id;
      this.record.index = index;
      this.record.type = 'gallery';
      this.record.method = 'EDIT';
      this.image.name = image.name,
      this.image.description = image.description,
      this.image.uploaded_image = image.path,
      this.openSlider();
    },
    getAddProductBar(){
      this.record.method = 'ADD'
      this.record.type = 'gallery';
      this.openSlider();
    },
    searchURL(){
      this.urls = [];
      this.images=[];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/materials?page='+this.page+'&per_page='+this.limit).then(res => {
        if(res.data.images.data.length>0){
          res.data.images.data.forEach(image => {
            this.images.push(image);
          });
          this.limit = res.data.images.per_page;
          this.offset = res.data.images.from-1;
          this.total = res.data.images.total;
          this.length = Math.ceil(res.data.images.total/res.data.images.per_page);
        }else{
          this.limit = res.limit;
          this.offset = res.offset;
          this.total = res.total;
          this.urls=[];
        }
      });
    },
    handlePageChange(value) {
        this.page = value;
        this.offset = this.limit * (this.page - 1);
        this.searchURL()
    },
    confirmShopEdit(){
      this.update_image.name = this.image.name;
      this.update_image.description = this.image.description;
      this.update_image.path = this.image.path==null?this.image.path:null;

      if(this.image.path == null){
        this.updateProduct();
      }else{
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });

        let s3BucketName = "tution-site"; 
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
        var timestamp = Math.floor(Date.now() / 1000);
        if(this.image.path){
          var path = new Blob([this.image.path], { type: "application/pdf" });
          var path_extension = this.image.path.name.split('.')[this.image.path.name.split('.').length-1];
          var path_name = this.image.path.name.split('.')[0]
          // if (file.size < 100000000) {
          var s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'gallery/'+ current_date+"/"+this.image.name +path_name+ timestamp + "."+path_extension,
              ACL: "public-read",
            },
          });
          var upload = s3obj.upload({ Body: path });
          upload.on("httpUploadProgress", function (evt) {
            // uploadStatus == 0;
            //checkConnetionOffnOn(upload);
            var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
            console.log(per_uploaded);
          })
          .send((err, data) => {
            this.update_image.uploaded_image = data.Location;
          })
        }
      }
    },
    updateProduct(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/gallery/'+this.record.id,this.update_image,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == 'Image Updated successfully'){
          this.images[this.record.index] = res.data.data;
          this.$refs['confirm-modal'].hide();
          // location.reload();
        }
      })
    },
    deleteProduct(){
      axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/gallery/'+this.record.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        console.log(res);
        if(res.data.meaasge == 'Image Deleted successfully'){
          this.$refs['confirm-modal'].hide();
          this.images.splice(this.record.index,1);
        }
      })
    },
    addProduct(e){
      e.preventDefault();
      this.err_msg = '';
      this.valid = this.$refs.form.validate();

      if(this.valid == true){
        this.loading = true;
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });

        let s3BucketName = "tution-site"; 
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
        var timestamp = Math.floor(Date.now() / 1000);

        var file = new Blob([this.image.path], { type: "application/image" });
        var extension = this.image.path.name.split('.')[this.image.path.name.split('.').length-1];
        var s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'materials/files/'+ current_date+"/"+this.image.name + timestamp + "."+extension,
              ACL: "public-read",
            },
          });
        var upload = s3obj.upload({ Body: file });
        upload.on("httpUploadProgress", function (evt) {
          // uploadStatus == 0;
          //checkConnetionOffnOn(upload);
          var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
          console.log(per_uploaded);
        })
        .send((err, data) => {
          this.image.uploaded_image = data.Location;
          this.submitProduct();
        });
      }
    },
    getConfirmation(){
      this.closeSlideBar();
      this.$refs['confirm-modal'].show();
    },
    confirmDelete(id,index){
      this.record.id = id;
      this.record.index = index;
      this.record.type = 'gallery';
      this.record.method = 'DELETE';
      this.$refs['confirm-modal'].show();
    },
    submitProduct(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/gallery',this.image,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == "Image added"){
          this.images.unshift(res.data.data);
          this.loading = false;
          this.image.name = null;
          this.image.description = null;
          this.image.path = null;
          this.image.uploaded_image = null;
          this.closeSlideBar();
        }
      })
    }
  }
}
</script>

<style scoped>
p:last-child {
    margin: 0px;
}
.slide-panel-light {
    background-color: #fff;
    padding: 30px;
    text-align: left;
    overflow: scroll;
}
.slide-panel{
    position: fixed;
    width: 0px;
    transition: all 450ms ease 0s;
    height: 100%;
    top: 0px;
    right: -275px;
    z-index: 1049;

    /* width: 275px;  */
}
.setbtn a.btn {
    float: right !important;
    width: 200px;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.request-list-table .table {
    font-size: 14px;
}
.table {
    margin-bottom: 0px;
    color: #6f6d72;
}
.request-list-table table th {
    background-color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252328;
}
.table thead th {
    vertical-align: bottom;
    font-weight: normal;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    padding: 8px;
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-xs {
    padding: 4px 8px !important;
    font-size: 11px !important;
    line-height: 1.5;
}
.btn-outline-violate {
    color: #9047fb;
    background-color: #f2e9ff;
    border-color: #9047fb;
}
.btn-outline-pink {
    color: #ff7b7b;
    background-color: #ffeded;
    border-color: #ff7b7b;
}
.btn-outline-violate:hover {
    color: #fff;
    background-color: #9047fb;
    border-color: #9047fb;
}
.btn-outline-pink:hover {
    color: #fff;
    background-color: #ff7b7b;
    border-color: #ff7b7b;
}
.btn-default:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled):active, .show>.btn-default.dropdown-toggle {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.form-control {
    display: block;
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #252328;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cfced0;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    /* background: #fff url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) no-repeat right .75rem center; */
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}
.btn-success {
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
</style>