<template>
  <div class="about-us">
    <div class="space-vision pb-0">
      <div class="space-medium">
        <div class="container">
          <!-- <div class="row">
            
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <iframe ref="auto_video" width="600" height="400" src="https://tution-site.s3.ap-south-1.amazonaws.com/video/amccmembers.mp4?autoplay=1&cc_load_policy=1&mute=1" title="YouTube video player" frameborder="0" allow="autoplay;encrypted-media;" allowfullscreen></iframe>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
              <h2>Counselling Team Members AMCC</h2>
              <p><b>(Al-Musheera Counseling & (Life) Coaching)</b><br>Email: <a href="mailto:amcc@fromyourtutor.com">amcc@fromyourtutor.com</a><br><a href="https://t.me/amcounselor/9031">https://t.me/amcounselor/9031</a></p>
              <h3>Supervisor</h3>
              <p><b class="prof-name">Umm Hamna Fatima</b></p>
              <p>Diploma in Counseling (Status: Ongoing , Australian university)</p>
              <p><b>Our counselling approach</b> is based on the Quran, Sunnah and Islamic-family values upon the understanding of our pious predecessors. We strive our best to distinguish our efforts in counselling and hence not to be rooted in erroneous ideologies and deviant methodologies but to use proven successful methods which won’t go against our religion and its foundation. We ask Allah to bless us with sincerity and adherence to the Sunnah and we strive to the best of our abilities</p>
              <p><b class="prof-name">Clinical Psychologist:  Umm Hurrairah </b> holds a Masters in Psychology, with an existing PG diploma in Therapeutic Counselling, and is a certified Relationship Counselor. She also holds a diploma in Islamic Studies and Arabic Language . She has over 7 years of experience in counselling and psychotherapy. She has a passion to work for the empowerment of Muslim Women & Girls by asserting our divine rights given by Allah سبحانه وتعالى </p>
              <h3><b>Other Female therapist</b></h3>
              <p><b class="prof-name">Bint Anwar</b> : Speech therapist and Sisters Counselor </p>
              <p><b class="prof-name">Sister Zehra </b>: Certified Counselling Psychology Practitioner and Speech therapist </p>
              <h3><b>Male Counselor</b></h3>
              <p><b class="prof-name">Brother Aftab</b>: M A Psychology,Advance Counseling </p>
              <p>Brother Aftab who is very hardworking, conscientious and caring counselor with extensive knowledge of offering advice, support, rehabilitation and guidance to clients to cope issues that maybe hampering their overall mental health.Having a proven ability to empathize with individuals whilst retaining an objective and realistic approach to their treatment and therapy. Comfortable working and communicating with people from all backgrounds and having experience of trauma, abuse, addiction, depression, marital difficulties and child-parent conflicts and mostly helping clients having Anxiety and Obsessive Compulsive Disorder.</p>
              <p>(above are just to name a few in our team) </p>
              <p><a class="btn btn-primary btn-sm text-white" href="https://forms.fromyourtutor.com/" target="_blank">Click here to take an appointment</a> </p>
            </div>
          </div> -->

          <div class="row">

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
             
              <h2>Counselling</h2>
              <p>Email: <a href="mailto:amcc@fromyourtutor.com">amcc@fromyourtutor.com</a><br><a href="https://t.me/amcounselor/9031">https://t.me/amcounselor/9031</a></p>
              <h3>Our counselling approach</h3>
            
              <p><b>Our counselling approach</b> is based on the Quran, Sunnah and Islamic-family values upon the understanding of our pious predecessors. We strive our best to distinguish our efforts in counselling and hence not to be rooted in erroneous ideologies and deviant methodologies but to use proven successful methods which won’t go against our religion and its foundation. We ask Allah to bless us with sincerity and adherence to the Sunnah and we strive to the best of our abilities</p>
              
              <p><a class="btn btn-primary btn-sm text-white" href="https://forms.fromyourtutor.com/" target="_blank">Click here to take an appointment</a> </p>
            </div>
          </div>
        </div>
        <div class="container">
            <h4>INTERNATIONAL ACCREDITATION</h4>
            <p><img src="../assets/images/amcc.jpg" alt=""  style="width:100%; max-width:1000px; margin-top: 10px;"></p>
              <p><img src="../assets/images/certificate.jpg" alt=""  style="width:100%; max-width:1000px; margin-top: 10px;"></p>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                
            <!-- <div class="card">
                <h3 class="card-header bg-white">General Questions About Counselling</h3>
                <div class="card-body">
                    <div class="faq-qa-block">
                        
                    <h4>Do you have only salafi counsellors onboard or others as well?</h4>
                    <p>Our platform is based on the values of Salafiyyah and insha Allah our employees follow these values. </p>
                    <p>When interviewed and given contract to sign, all employees must agree to uphold certain values during the sessions and follow certain rules. </p>
                    <p>They aren't permitted to do what is haram such as play music or songs during the sessions, nor discuss or suggest things that go against Islamic values which we stand for . We are strict on that . Any complaints on the breaching of our value system will lead to termination of the contract with immediate effect if the employee didn’t accept the advice. 
</p>
<p>They are reviewed, reports are checked etc by supervisors. Insha Allah we hope to be firm with this matter so only khair comes from our academy and it's sessions with Allah’s permission.</p>
<p>But understand no teachers or counselors are students of knowledge and hence worldly matters which are strictly related religion like divorce, fiqh issues etc are not discussed or given a fatwa but will be forwarded to students of knowledge and we will patiently wait for their answers in shaa Allah. 
</p>
<p>Reminder patience, prayer and perseverance is the key to success of any process to get its fruit.</p>
                  </div>
                </div>
            </div> -->
        </div>
        </div>
        </div>
    
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq"
}
</script>

<style scoped>
.page-header{
  border-bottom: transparent;
  background: #ce0d3d;
  min-height: 189px;
}
.page-caption{
  padding-top:30px;
  padding-bottom:32px;
}
.page-title{
      font-size: 46px;
    color: #fff;
    margin-bottom: 0px;
    font-weight: 400;
    line-height: 1.2;
}
.page-breadcrumb {
    background-color: rgba(0, 0, 0, .5);
    border-top: rgba(255, 255, 255, .1) 1px solid;
}
.page-breadcrumb .breadcrumb .breadcrumb-item {
    font-size: 13px;
}
.breadcrumb{
    margin-bottom: 8px;
    padding-left: 2px;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link {
    color: #bebbc1;
    text-decoration: none;
}
.page-breadcrumb .breadcrumb .breadcrumb-item .breadcrumb-link:hover {
    color: #0079df;
}
/* .page-breadcrumb .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: #bebbc1;
    content: "\f105";
    font-family: 'font Awesome 5 Free';
    font-weight: 900;
} */
.circle-icon {
    height: 125px;
    width: 125px;
    font-size: 60px;
    line-height: 2.1;
    padding: 0px 33px;
    color: #fff;
    display: inline-block;
}
.bg-default {
    background-color: #0079df !important;
}
h2 {
    font-size: 28px;
    line-height: 38px;
}
.faq-qa-block{
    margin-bottom: 20px !important;
border-bottom: 1px solid #e6e5e7;
padding-bottom: 20px;
}
.faq-qa-block h4{

margin-top: 0px;
}
.lead{
  font-size: 21px;
    font-weight: 400;
    color: #252328;
    line-height: 31px;
    font-family: 'Playfair Display', serif;
}
.metrics {
    padding: 70px !important;
    text-align: center;
}
.metrics-title {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 36px;
}
.metrics-subtitle {
    font-size: 56px;
    line-height: 64px;
}
.space-medium{
    padding-top: 50px;
    padding-bottom: 80px;
}
.section-title {
    margin-bottom: 50px;
}
.section-title h2 {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 36px;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
    margin-bottom: 15px;
}
.section-title h2 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 36px;
}
.heading-btm-line {
    font-size: 20px;
    font-weight: 600;
    border-bottom: 2px solid #0079df;
    padding-bottom: 10px;
    display: inline-block;
}
p:last-child {
    margin: 0px;
}
.seperator-line{
    height: 1px;
    background: rgb(227, 227, 227);
}
</style>