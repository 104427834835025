<template>
  <div class="cart">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead>
                      <tr>
                        <th class="sn" width="50">Sn.</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody v-if="items.length">
                      <tr v-for="(item,index) in items" :key="item.product_id">
                        <td width="50">{{index+1}} </td>
                        <td class="estimate-total">
                          <img src="../assets/images/cart-l.jpg" v-if="!item.product.thumbnail" width="130" style="pointer-events:none;width:50px !important;">
                          <img :src="item.product.thumbnail.split(' ')[0]" v-if="item.product.thumbnail" width="130" style="pointer-events:none;width:50px !important;">
                        </td>
                        <td class="actual-total">
                          <div class="recent-realwed-content pl-0">
                            <!-- real wedding content -->
                            <h5 class="mb-1"><a href="#" class="book_title">{{item.product.name}}</a></h5>
                            <div class="real-wedding-meta" v-if="item.product.type&&item.product.file_format"><span class="real-wedding-date" >Type: {{item.product.type}} || Format: {{item.product.file_format.name}}</span><br></div>
                          </div>
                        </td>
                        <td class="paid-total">
                          <span class="total-amount" v-if="item.product.price.startsWith('$')">{{item.product.price}}</span>
                          <span class="total-amount" v-if="!item.product.price.startsWith('$')">${{item.product.price}}</span></td>
                        <td class="pending-total">
                          <a href="" v-on:click.prevent="deleteItem(item,index)" class="btn btn-outline-pink btn-xs">delete</a>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-if="!items.length">
                      <tr> No Items</tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="card">
              <div class="card-body">  
                <table class="table">
                  <tbody>
                    <tr>
                      <td>Total </td>
                      <td>$ {{this.total}}</td>
                    </tr>
                    <tr>
                      <td>Tax </td>
                      <td>$ {{this.tax}}</td>
                    </tr>
                    <tr>
                      <td><h4>Grand Total</h4></td>
                      <td><h4>$ {{this.grand_total}}</h4></td>
                    </tr>
                  </tbody>
                </table>
                <div v-show="this.user">
                  <a v-on:click.prevent="editUserEmail()" class="btn btn-outline-violate btn-xs mr-1" v-if="user_email&&!order_success" style="float:right">Edit</a>
                  <v-text-field type="text" v-model="user_email" disabled v-if="user_email&&!order_success"><template #label><span>Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  <div id="paypal-button-container" v-show="booking"></div>
                </div>
                <div class="terms">
                  <v-checkbox class="mt-0" v-model="terms"></v-checkbox><span>I agree the <a href="/terms">Terms & Conditions and giving permission to send email to my mail</a></span>
                </div>
                <v-form ref="form" lazy-validation v-model="valid" v-show="!this.user&&terms">
                  <v-text-field type="text" v-model="user_email" :rules="emailRules"><template #label><span>Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  <a class="btn button-success pay w-100 text-white" v-on:click="toPayConfirm()" v-show="booking&&!confirmed">Pay Now</a>
                </v-form>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to delete these records from cart?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="confirmDelete">Delete</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import axios from 'axios'
  /*import AWS from "aws-sdk";*/
  export default {
    data(){
      return {
        items:[],
        total:0,
        grand_total:0,
        terms:true,
        record:{
          id:null,
          index:null,
          price:null
        },
        fileformats:[],
        result:null,
        booking:false,
        tax:0,
        student:null,
        country:null,
        user:null,
        user_email:null,
        valid: true,
        confirmed:false,
        order_success:false,
        emailRules:[
          v => !!v || 'Enter valid email',
          v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
        ],
      }
    },
    async beforeMount(){
      window.scrollTo(0, 0);
      if(this.$cookies.get('order-cart-session-key')&&this.$cookies.get('order-cart-session-token')){
        console.log('');
      }else{
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/cart',{},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.Message == 'A new cart have been created for you!'){
            this.$cookies.set('order-cart-session-key',res.data.cartKey);
            this.$cookies.set('order-cart-session-token',res.data.cartToken);
          }
        })
      }
      // await axios.get('http://ip-api.com/json').then(res => {
      //   if(res.data.status == 'success'){
      //     this.country = res.data.country;
      //   }
      // })
      
      let cartKey = this.$cookies.get('order-cart-session-key');
      let cartToken = this.$cookies.get('order-cart-session-token');
      let user_id;
      if(this.$cookies.get('tution-student')){
        await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
          if(res.data.message == 'Student retrieved successfully'){
            this.user = res.data.data.student.user;
            user_id = this.user.user_id;
          }
        });
      }else if(this.$cookies.get('tution-tutor')){
        await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-tutor').token}}).then(res => {
          if(res.data.message == 'User found'){
            this.user = res.data.data.users;
            user_id = this.user.user_id;
          }
        });
      }

      await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/course/getcart',{cartKey:cartKey,cartToken:cartToken,user_id:user_id},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message == 'Product listed successfully'){
          res.data.Items.forEach(item => {
            if(item.is_active == 'Y'&&item.item_type == 2){
              this.items.push(item)
            }else if(item.is_active == 'Y'){
              item.course.thumbnail = (item.course.thumbnails.length > 0)?item.course.thumbnails[0].url:'';
              item.product = item.course;
              this.items.push(item);
            }

          })
        }
      });
      this.items = this.items.reduce((unique, o) => {
          if(!unique.some(obj => obj.user_id === o.user_id && obj.product_id === o.product_id)) {
            unique.push(o);
          }
          return unique;
      },[]);

      this.items.forEach((item) => {
        this.total = this.total+parseInt(item.product.price.startsWith('$')?item.product.price.replace('$',''):item.product.price);
      });
      
      this.tax = (this.country=='India')?(Math.floor((this.total*0.18) * 100) / 100):0;
      // this.tax = 0;
      this.grand_total = parseInt(this.total)+this.tax;
      this.booking = (this.grand_total > 0)?true:false;
    },
    mounted(){
      const script = document.createElement('script');
      script.src="https://www.paypal.com/sdk/js?client-id="+process.env.VUE_APP_CLIENT_ID;
      script.addEventListener("load", () => this.setLoaded());
      document.body.appendChild(script);
    },
    methods:{
      hideModal(){
        this.$refs['confirm-modal'].hide();
      },
      deleteItem(item,index){
        this.record.id = item.cart_id;
        this.record.index = index;
        this.record.price = item.product.price;
        this.$refs['confirm-modal'].show();
      },
      confirmDelete(e){
        e.preventDefault();
        axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/carts/'+this.record.id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.message == 'product deleted successfully'){
            this.items.splice(this.record.index,1);
            this.total = this.total - parseInt(this.record.price.startsWith('$')?this.record.price.replace('$',''):this.record.price);
            // this.tax = (Math.floor((this.total*0.18) * 100) / 100);
            this.grand_total = parseInt(this.total)+this.tax;
            this.record.id = null;
            this.record.index = null;
            this.record.price = null;
            this.$refs['confirm-modal'].hide();
          }else{
            console.log('error');
          }
        })
      },
      toPayConfirm(){
        this.valid = this.$refs.form.validate();
        if(this.valid == true){
          this.confirmed = true;
          this.user = {};
          this.user.email = this.user_email;
        }
      },
      editUserEmail(){
        this.confirmed = false;
        this.user = null;
      },
      setLoaded: function(){
        // this.loaded = true; 
        // var price = this.grand_total;
        this.user_email=this.user?this.user.email:this.user_email
        var self = this;
        // var items = this.items;
        // var student_token = this.$cookies.get('tution-student').token;
          window.paypal.Buttons({
            // Sets up the transaction when a payment button is clicked
            createOrder: function(data, actions) {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: self.grand_total // Can reference variables or functions. Example: `value: document.getElementById('...').value`
                  }
                }]
              })
            },

            // Finalize the transaction after payer approval
            onApprove: function(data, actions) {
            return actions.order.capture().then(function(orderData) {
              // Successful capture! For dev/demo purposes:
                  // console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                  // var transaction = orderData.purchase_units[0].payments.captures[0];
                  // alert('Transaction '+ transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');

              var data = {
                name:orderData.payer.name.given_name+' '+orderData.payer.name.surname,
                adress:orderData.payer.email_address,
                totalPrice:self.grand_total,
                cartKey:self.$cookies.get('order-cart-session-key'),
                user_id:self.user?self.user.user_id:null
              }
              axios.post(process.env.VUE_APP_TUTION_SITE_API+'/carts/checkout',data,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
                if(res.data.message == 'you\'re order has been completed succefully, thanks for shopping with us!'){
                  var cart_items = [];
                  self.items.forEach(item => {
                    cart_items.push(item.product.name)
                  })
                  var transactionID = res.data.transactionID;
                  self.items = [];
                  self.total = 0;
                  self.grand_total = 0;
                  self.$cookies.remove("order-cart-session-token");
                  self.$cookies.remove("order-cart-session-key");
                  // price = 0;
                  // self.booking = false;
                  

                  var body =  {
                      "eventType": "orderConfirmation",
                      "order": {
                          "email": self.user_email,
                          "cart_items": cart_items,
                          "transactionID": transactionID
                        }
                  }
                  axios.post('https://fpurd8z9i3.execute-api.ap-south-1.amazonaws.com/test01/confirm-booking',body).then(res => {
                    console.log(res);
                  })


                  var element = document.getElementById('paypal-button-container');
                  element.innerHTML = '';
                  self.order_success = true;
                  if(self.user.user_id){
                    element.innerHTML = '<h5 class="text-success">you\'re order has been completed successfully, thanks for shopping with us!</h5>';
                    setTimeout(() => {
                      self.$router.push('/student/dashboard');
                    },4000)
                  }else{
                    element.innerHTML = '<h5 class="text-success">Your order has been successfully placed. Please check your email and also you can download from here</h5></br><a href="https://fromyourtutor.com/order?key='+transactionID+'&&type=course" target="_blank" style="color: #fff; padding: 15px; font-size:16px; background:#ce004b; border-radius:5px; text-decoration: none;"><strong>View purchased course</strong></a>';
                  }
                  // setTimeout(() => {
                  //   self.$router.push('/study_materials');
                  // },4000)
                }
              })
            });
            }
          }).render('#paypal-button-container');
      }
    }
  }
</script>

<style scoped>
.cart{
  background-color: #f2f7fb !important;
}
.content {
    padding-top: 40px;
    padding-bottom: 60px;
}
.card-body {
    padding: 20px;
}
table {
    color: #6f6d72;
    border: #fff !important;
}
.table thead th {
    vertical-align: bottom;
    font-weight: normal;
    color: #252328;
}
.table td, .table th {
    padding: 8px;
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
.recent-realwed-content {
    padding: 12px;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
h4 {
    font-size: 18px;
    line-height: 26px;
}

h1, h2, h3, h4, h5, h6 {
    color: #252328;
    margin: 0px 0px 15px 0px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}
.book_title {
    color: #252328;
    text-decoration: none;
}
a {
    color: #252328;
    -webkit-font-smoothing: antialiased;
}
.book_title:active, a:hover {
    outline: 0;
    text-decoration: none;
}

.book_title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.real-wedding-meta {
    line-height: 14px;
}
.real-wedding-date {
    font-size: 12px;
    color: #4c4a4f;
    border-bottom: 1px solid #0079df;
    display: inline-block;
    line-height: 1.8;
    font-style: italic;
}
.total-amount {
    font-weight: 500;
    margin-top: 6px;
    display: block;
    color: #252324;
}
.estimate-total, .actual-total, .paid-total, .pending-total {
    font-size: 14px;
    line-height: 18px;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}

.btn-outline-pink {
    color: #ff7b7b;
    background-color: #ffeded;
    border-color: #ff7b7b;
}
.btn {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.terms{
  display: flex;
  flex-direction: row;
}
/* img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
} */
</style>