<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header">
              <h3 class="dashboard-page-title">Add Video Courses</h3>
            <p class="d-block">Fill below details and add video courses</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <v-form ref="form" lazy-validation v-model="valid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="title">Title<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field v-model="toAdd.name" :rules="[v => !!v || 'Enter title']" outlined></v-text-field>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="summernote">Descriptions </label>
                      <v-textarea v-model="toAdd.description" rows="6" placeholder="Write Descriptions about the course" outlined ></v-textarea>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="title">Price<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field placeholder="$" v-model="toAdd.price" :rules="[v => !!v || 'Enter price']" outlined></v-text-field>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <label class="control-label" for="filebutton">Upload Image (For thumbnail & Slider)</label>
                      <v-file-input accept="image/*" v-model="toAdd.thumbnails" @change="addMultipleFiles"  prepend-icon="mdi-camera" multiple small-chips truncate-length="10" counter outlined>
                        <template v-slot:selection="{ index, text }">
                          <v-chip close @click:close="deleteChip(index, text)">{{ text }}</v-chip>
                        </template>
                      </v-file-input>
                      <v-progress-linear :value="thumbnail_progress" v-if="toAdd.thumbnails.length>0 && thumbnail_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                      <div v-if="toAdd.uploaded_thumbnails" class="row">
                        <div v-for="(thumb,index) in toAdd.uploaded_thumbnails" :key="index" class="col-4 ml-1" style="border:1px solid #ddd">
                          <img :src="thumb" :alt="'thumb'+index" height="50" style="pointer-events:none;">
                          <a v-on:click.prevent="deleteThumb(index)" :id="index" class="btn btn-outline-pink btn-xs" style="padding: 0 !important;position: relative;top: 10px;">DELETE</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                      <v-checkbox v-model="toAdd.female_only" label="Female Only"></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row module-title">
              <div class="col-md-6">
                <h4>Add Modules</h4>
              </div>
              <div class="col-md-6 text-right">
                <a href="" v-on:click.prevent="addModule()" class="btn add-module-btn btn-sm ">Add module +</a>
              </div>
            </div>
            <div class="card modules-card" v-for="(v_module, index) in toAdd.modules" :key="index">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <p class="moduleSn-badge">{{index+1}}.</p>
                    <span class="moduleClose-badge" v-on:click.prevent="removeModule(index)">X</span>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div>
                      <label class="control-label" for="filebutton">Module Title<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="v_module.name" :rules="[v => !!v || 'Enter title']" placeholder="Module Title" outlined ></v-text-field>
                    </div>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div>
                      <label class="control-label" for="filebutton">Module Description<span class="red--text"><strong>* </strong></span></label>
                      <v-textarea type="text" v-model="v_module.description" :rules="[v => !!v || 'Enter description']" placeholder="Module Description" outlined ></v-textarea>
                    </div>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div >
                      <label class="control-label" for="filebutton">Upload Video<span class="red--text"><strong>* </strong></span></label>
                        <v-file-input accept="video/*" v-model="v_module.video" @change="addFileToS3(v_module.video,'video','course_file',index)"  prepend-icon="mdi-paperclip" :rules="[v => !!v || 'Select video']" outlined></v-file-input>
                        <v-progress-linear :value="video_progress" v-if="v_module.video && video_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                    </div>
                  </div>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div>
                      <label class="control-label" for="filebutton">Upload Resourse File</label>
                        <v-file-input v-model="v_module.resource" @change="addFileToS3(v_module.resource,'all','resource',index)" prepend-icon="mdi-paperclip" outlined></v-file-input>
                        <v-progress-linear :value="resource_progress" v-if="v_module.resource && resource_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <b-button type="submit" class="btn btn-default" v-if="isUploading == 0" v-on:click.prevent="addVideoCourse">Submit</b-button>
              <b-button type="button"  class="btn btn-default" v-if="isUploading != 0"><i class="fa fa-spinner fa-spin"></i></b-button>
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Course added</div>
    </b-modal>
  </div>
</template>
  
<script>
import AWS from "aws-sdk";
import axios from 'axios';
export default {
  name:'add-video-course',
  data(){
    return {
      valid:true,
      loading:false,
      toAdd:{
        name:null,
        description:null,
        price:null,
        female_only: false,
        thumbnails:[],
        thumb_files:[],
        updated_thumbnails:[],
        modules:[
          {name:null,video:null,description:null,resource:null, video_url:null, resource_url:null}
        ]
      },
      files:[],
      thumbnail_progress:0,
      resource_progress:0,
      video_progress:0,
      isUploading: 0
    }
  },
  methods:{
    addModule(){
      this.toAdd.modules.push({name:null,video:null,resource:null, video_url:null, resource_url:null});
    },
    removeModule(index){
      this.toAdd.modules.splice(index,1);
    },
    deleteChip(index){
      this.toAdd.thumbnails.splice(index, 1)
    },
    deleteThumb(index){
      this.toAdd.uploaded_thumbnails.splice(index, 1);
    },
    addMultipleFiles(){
      this.toAdd.thumbnails.forEach(thumbnail => {
        if(!this.files.includes(thumbnail)){
          this.files.push(thumbnail);
          this.addFileToS3(thumbnail,'image','thumbnail',0)
        }
      });
      this.toAdd.thumbnails=[];
      this.toAdd.thumbnails = this.files;
    },
    addFileToS3(file,type,field_name,module_index){
      var i = module_index
      var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
      var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
      let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
    
      AWS.config.update({
        accessKeyId: keys.accessKeyId,
        secretAccessKey: keys.secretAccessKey,
        region: 'ap-south-1'
      });

      let s3BucketName = "tution-site"; 
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
      var timestamp = Math.floor(Date.now() / 1000);
      if(file){
        var data = (field_name=='resource')?new Blob([file]):new Blob([file], { type: "application/"+type });
        var extension = file.name.split('.')[file.name.split('.').length-1];
        var name = file.name.split('.')[0];
        this.isUploading++;
        var s3obj = new AWS.S3({
          params: {
            Bucket: s3BucketName,
            Key:
              'courses/'+field_name+'/'+ current_date+"/"+name+ timestamp + "."+extension,
            ACL: "public-read",
          },
        });
        var upload = s3obj.upload({ Body: data });
        let that = this;
        upload.on("httpUploadProgress", function (evt) {
          switch(field_name) {
              case 'resource': that.resource_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              case 'thumbnail': that.thumbnail_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              case 'course_file': that.video_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              default: break;
          }
        }).send((err, data) => {
          switch(field_name) {
              case 'resource': that.toAdd.modules[i].resource_url= data.Location; break;
              case 'thumbnail': that.toAdd.thumb_files.push(data.Location); break;
              case 'course_file': that.toAdd.modules[i].video_url = data.Location; break;
              default: break;
          }
          this.isUploading--;
        });
      }
    },
    addVideoCourse(e){
      e.preventDefault();
      this.loading = true;
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/video_course',this.toAdd,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.status){
            this.$refs["success"].show();
            setTimeout(() => {
              this.$refs["success"].hide();
              this.$router.push("/tutn-auth/video-courses");
            },2000);
          }
        })
      }
    }
  }
}
</script>
  
<style scoped>
  .switch {
      display: inline-block;
      width: 40px;
      height: 20px;
      transform: translateY(20%);
      position: relative;
  }
  label {
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 14px;
      color: #5f5e61;
      line-height: 2.3;
  }
  .switch input {
      display: none;
  }
  .slider {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 20px;
      cursor: pointer;
      transition: .4s;
      background: #bdbdbd;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    background-color: white;
    transition: .4s;
    border-radius: 20px;
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
  input:checked + .slider:before {
    transform: translateX(20px);
  }
  input:checked+.slider {
      background: #129e32;
  }
  .btn-outline-light:hover {
      color: #2e2f39;
      background-color: #f0f0f8;
      border-color: #f0f0f8;
  }
  .btn-outline-light {
      color: #2e2f39;
      background-color: transparent;
      border-color: #ebebed;
  }
  </style>