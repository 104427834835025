<template>
  <div class="order-content">
    <div class="container">
      <div class="row mt-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mb-6">
          <a href="/study_materials" class="btn btn-default btn-sm text-white" v-if="order_type == 'e_shop'">Download Study Materials</a>
          <a href="/video_course" class="btn btn-default btn-sm text-white" v-if="order_type == 'course'">Video Courses</a>
        </div>
      </div>
      <div class="dashboard-vendor-list" v-if="order_type == 'e_shop'">
        <ul class="list-unstyled pl-0" v-if="products.length">
          <li v-for="(product,index) in products" v-bind:key="index">
            <div class="dashboard-list-block">
              <div class="row">
                <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-img"><a href=" "><img :src="product.Thumbnail.split(' ')[0]" alt="" class="img-fluid" v-if="product.Thumbnail" style="pointer-events:none;"><img src="../assets/images/cart-l.jpg" alt="" class="img-fluid" v-if="!product.Thumbnail" style="pointer-events:none;"></a></div>
                </div>
                <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div class="dashboard-list-content">
                    <h3 class="mb-0"><a href="" class="title">{{product.Name}} | {{product.Type==3?'Video Course':product.Type}}</a></h3>
                    <!-- <a class="title"><p style="font-size:14px;">Book by {{material.auther}} | Purchased - {{material.purchased}}</p></a> -->
                    <a class="title" v-if="product.item_type!=3"><p style="font-size:14px;"><span v-if="product.Subject">Subject by {{product.Subject}} | </span>Format - {{product.Fileformat}}</p></a>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-btn" v-if="product.Uploaded_file">
                    <a v-on:click.prevent="openVideoModal(product.Uploaded_file,product.name)" v-if="product.item_type==3&&product.Uploaded_file&&product.Uploaded_file.substr(product.Uploaded_file.lastIndexOf('.') + 1)=='mp4'" class="btn btn-outline-violate btn-xs mr10">Play</a>
                    <a :href="product.Uploaded_file" v-else class="btn btn-outline-violate btn-xs mr10">Download</a>           
                    <!-- <form method="get" :action="material.file"><button type="submit" class="btn btn-outline-violate btn-xs mr10">Download</button></form> -->
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <div class="empty-data">No Downloads</div>
        </ul>
      </div>
      <div class="dashboard-vendor-list" v-if="order_type == 'course'">
        <ul class="list-unstyled pl-0" v-if="courses.length">
          <li v-for="(course,index) in courses" v-bind:key="index">
            <div class="dashboard-list-block">
              <div class="row">
                <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-img">
                    <a href=" ">
                      <img :src="course.Thumbnail" alt="" class="img-fluid" style="pointer-events:none;height:70px;" v-if="course.Thumbnail">
                      <img src="../assets/images/cart-l.jpg" alt="" class="img-fluid" style="pointer-events:none;" v-else>
                    </a>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div class="dashboard-list-content">
                    <h3 class="mb-0"><a href="" class="title">{{course.Name}}</a></h3>
                    <p style="font-size:14px;">{{course.Description}}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-btn">
                    <a class="btn btn-outline-violate btn-xs mr10" v-on:click.prevent="startClass(course.id)">Start Class</a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <div class="empty-data">No Downloads</div>
        </ul>
      </div>
    </div>
    <b-modal id="success" ref="video-modal" size="xl" :title="video_title" hide-footer centered>
      <div class="container text-center">
        <video id="video_modal" width="1000" height="400" type="video/*" controls :src="this.video_id"></video>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'order',
  data(){
    return{
      products:[],
      courses:[],
      video_id:null,
      video_title:null,
      order_type:'e_shop'
    }
  },
  beforeMount(){
    window.scrollTo(0, 0);
    if(this.$route.query.type == 'course'&&this.$route.query.key){
      this.order_type = 'course'
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_orders/'+this.$route.query.key,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      if(res.data.message === 'product listed successfully'){
          this.courses = res.data.products.Orders;
          // this.courses = res.data.courses;
        }
      })
    }else if(this.$route.query.key){
      this.order_type = 'e_shop'
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_orders/'+this.$route.query.key,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      if(res.data.message === 'product listed successfully'){
          this.products = res.data.products.Orders;
        }
      })
    }
  },
  methods:{
    openVideoModal(videoID,title){
      this.video_id = videoID;
      this.video_title = title;
      this.$refs['video-modal'].show();
    },
    startClass(id){
      const data = this.$CryptoJS.AES.encrypt('tuition_id='+id, 'tutn@369').toString().replaceAll('/','Por21Ld');
      this.$router.push('/order/video-class-room/'+this.$route.query.key+'/'+data);
    }
  }
}
</script>

<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
}
.dashboard-list-btn {
    padding-right: 29px;
    padding-top: 20px;
    text-align: right;
}
.dashboard-list-img img {
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}
</style>