<template>
  <div class="dashboard-content">
    <div id="slide-reveal-overlay" v-on:click="closeSlideBar()" style="display: none; position: fixed; top: 0px; left: 0px; height: 100%; width: 100%; z-index: 1048; background-color: rgba(0, 0, 0, 0.5);"></div>
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <h3 class="dashboard-page-title" v-if="tab=='subjects'">Subjects</h3>
            <h3 class="dashboard-page-title" v-if="tab=='grades'">Grades</h3>
            <h3 class="dashboard-page-title" v-if="tab=='accent'">Accent</h3>
            <h3 class="dashboard-page-title" v-if="tab=='shop'">Shop File Formats</h3>
            <p class="d-block" v-if="tab=='subjects'">Add, Edit or Delete Subjects</p>
            <p class="d-block" v-if="tab=='grades'">Add, Edit or Delete Grades</p>
            <p class="d-block" v-if="tab=='accent'">Add, Edit or Delete Accent</p>
            <p class="d-block" v-if="tab=='shop'">Add, Edit or Delete File Formats</p>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <a class="btn btn-sm mr-1" :class="(tab=='subjects')?'btn-default':'btn-outline-light'" @click="tab='subjects'">Subjects</a> 
            <a class="btn btn-sm mr-1" :class="(tab=='grades')?'btn-default':'btn-outline-light'" @click="tab='grades'">Grades</a>
            <a class="btn btn-sm mr-1" :class="(tab=='accent')?'btn-default':'btn-outline-light'" @click="tab='accent'">Accent</a>
            <a class="btn btn-sm mr-1" :class="(tab=='shop')?'btn-default':'btn-outline-light'" @click="tab='shop'">Shop File Formats</a>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div id="slide-panel6" class="slide-panel-light" style="position: fixed; width: 275px; transition: all 450ms ease 0s; height: 100%; top: 0px; right: -275px; z-index: 1049;">
          <div v-if="record.method!='EDIT'">
            <h3 v-if="tab=='subjects'">Add Subjects</h3>
            <h3 v-if="tab=='grades'">Add Grades</h3>
            <h3 v-if="tab=='accent'">Add Accent</h3>
            <h3 v-if="tab=='shop'">Add File Formats</h3>
          </div>
          <div v-if="record.method=='EDIT'">
            <h3 v-if="tab=='subjects'">Edit Subject</h3>
            <h3 v-if="tab=='grades'">Edit Grade</h3>
            <h3 v-if="tab=='accent'">Edit Accent</h3>
            <h3 v-if="tab=='shop'">Edit File Format</h3>
          </div>
          <form>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="form-group">
                  <!-- <input id="inputSubjects" v-model="new_subject" v-if="record.method!='EDIT' && tab=='subjects'"  type="text" class="form-control" placeholder="Enter">
                  <input id="inputGrades" v-model="new_grade" v-if="record.method!='EDIT' && tab=='grades'"  type="text" class="form-control" placeholder="Enter">
                  <input id="inputAccents" v-model="new_accent" v-if="record.method!='EDIT' && tab=='accent'"  type="text" class="form-control" placeholder="Enter">
                  <input id="inputFormats" v-model="new_format" v-if="record.method!='EDIT' && tab=='shop'"  type="text" class="form-control" placeholder="Enter"> -->
                  <input id="inputSubjects" v-model="add_new" v-if="record.method!='EDIT'"  type="text" class="form-control" placeholder="Enter">
                  <input id="inputSubjects" v-model="record.text" v-if="record.method=='EDIT'"  type="text" class="form-control" placeholder="Enter">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <div class="form-group">
                  <a class="btn btn-success text-white" v-on:click.prevent="toAddItem" v-if="record.method!='EDIT'" style="cursor:pointer;">Save</a>
                  <a class="btn btn-success text-white" v-on:click.prevent="toEditItem" v-if="record.method=='EDIT'" style="cursor:pointer;">Update</a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 setbtn pt-0">
          <a v-on:click="openSlider()" class="btn btn-default" id="trigger6">Add New</a>
        </div>
      </div> 
      <br>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="card request-list-table table-responsive">
            <table class="table" v-if="tab=='subjects'">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Subjects</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="subjects.length">  
                <tr v-for="(subject,index) in subjects" :key="subject.value">
                  <td width="20">{{index+1}}</td>
                  <td width="70%">{{subject.text}}</td>
                  <td>
                    <a v-on:click.prevent="confirmSubjectEdit(subject.value,subject.text,index)" class="btn btn-outline-violate btn-xs mr-1">edit</a>
                    <a v-on:click.prevent="confirmDeleteSubject(subject.value,index)" href="# " class="btn btn-outline-pink btn-xs">delete</a>
                  </td>  
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="empty-data text-center" colspan="6" rowspan="4">No Subjects</td>
                </tr>
              </tbody>
            </table>
            <table class="table" v-if="tab=='grades'">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Grades</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="grades.length">  
                <tr v-for="(grade,index) in grades" :key="grade.value">
                  <td width="20">{{index+1}}</td>
                  <td width="70%">{{grade.text}}</td>
                  <td>
                    <a v-on:click.prevent="confirmGradeEdit(grade.value,grade.text,index)" class="btn btn-outline-violate btn-xs mr-1">edit</a>
                    <a v-on:click.prevent="confirmDeleteGrade(grade.value,index)" class="btn btn-outline-pink btn-xs">delete</a>
                  </td>  
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="empty-data text-center" colspan="6" rowspan="4">No Grades</td>
                </tr>
              </tbody>
            </table>
            <table class="table" v-if="tab=='accent'">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Accent</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="accents.length">  
                <tr v-for="(accent,index) in accents" :key="accent.value">
                  <td width="20">{{index+1}}</td>
                  <td width="70%">{{accent.text}}</td>
                  <td>
                    <a v-on:click.prevent="confirmAccentEdit(accent.value,accent.text,index)" class="btn btn-outline-violate btn-xs mr-1">edit</a>
                    <a v-on:click.prevent="confirmDeleteAccent(accent.value, index)" class="btn btn-outline-pink btn-xs">delete</a>
                  </td>  
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="empty-data text-center" colspan="6" rowspan="4">No Accents</td>
                </tr>
              </tbody>
            </table>
            <table class="table" v-if="tab=='shop'">
              <thead>
                <tr>
                  <th>Sn.</th>
                  <th>Formats</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="fileFormats.length">  
                <tr v-for="(file,index) in fileFormats" :key="file.value">
                  <td width="20">{{index+1}}</td>
                  <td width="70%">{{file.text}}</td>
                  <td>
                    <a v-on:click.prevent="confirmFormatEdit(file.value,file.text,index)" class="btn btn-outline-violate btn-xs mr-1">edit</a>
                    <a v-on:click.prevent="confirmDeleteFileFormat(file.value, index)" class="btn btn-outline-pink btn-xs">delete</a>
                  </td>  
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="empty-data text-center" colspan="6" rowspan="4">No File format</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p v-if="this.record.method == 'DELETE'">Do you really want to delete these records? This process cannot be undone.</p>
          <p v-if="this.record.method == 'EDIT'">Do you really want to update these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal">Cancel</button>
            <button type="button" class="btn btn-danger" v-if="this.record.method == 'DELETE'" v-on:click="updateRecord">Delete</button>
            <button type="button" class="btn btn-danger" v-if="this.record.method == 'EDIT'" v-on:click="updateRecord">Update</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "admin-settings",
  data(){
    return {
      tab:'subjects',
      add_new:null,
      new_subject:null,
      new_grade:null,
      new_accent:null,
      new_format:null,
      subjects:[],
      grades:[],
      accents:[],
      fileFormats:[],
      edit_record:{
        id:null,
        name:null,
        price:null,
        type:null,
        file_format_id:null,
        subject_id:null
      },
      record:{
        id:null,
        text: null,
        index:null,
        method:null,
        type:null
      }
    }
  },
  beforeMount(){
    this.getSubjects();
    this.getGrades();
    this.getAccents();
    this.getFileFormats();
  },
  methods: {
    openSlider(){
      this.add_new = null;
      document.getElementById('slide-panel6').style.right = '0px';
      document.getElementById('slide-panel6').style.width = '275px';
      document.getElementById('slide-reveal-overlay').style.display = 'block';
    },
    closeSlideBar(){
      document.getElementById('slide-panel6').style.right = '-275px';
      document.getElementById('slide-panel6').style.width = '0px';
      document.getElementById('slide-reveal-overlay').style.display = 'none';
    },
    hideModal(){
      this.$refs['confirm-modal'].hide();
    },
    resetRecords(){
      this.record.id = null;
      this.record.type = null;
      this.record.text = null;
      this.record.index = null;
      this.record.method = null;
    },
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          if(subject.is_active == 'Y'){
            this.subjects.push({value:subject.subject_id, text:subject.subject_name});
          }
        })
      })
    },
    getGrades(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Grades.forEach(grade => {
          if(grade.is_active == 'Y'){
            this.grades.push({value:grade.grade_id,text:grade.name});
          }
        })
      })
    },
    getAccents(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/accents',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Accents.forEach(accent => {
          if(accent.is_active == 'Y'){
            this.accents.push({value:accent.accent_id,text:accent.name});
          }
        })
      })
    },
    getFileFormats(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/fileformats',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.fileFormats.forEach(file => {
          if(file.is_active == 'Y'){
            this.fileFormats.push({value:file.file_format_id,text:file.name});
          }
        })
      })
    },
    confirmSubjectEdit(id,text,index){
      this.record.id = id;
      this.record.text = text;
      this.record.type = 'subject'
      this.record.index = index;
      this.record.method = 'EDIT';
      this.openSlider();
    },
    confirmGradeEdit(id,text,index){
      this.record.id = id;
      this.record.text = text;
      this.record.type = 'grade';
      this.record.index = index;
      this.record.method = 'EDIT';
      this.openSlider();
    },
    confirmAccentEdit(id,text,index){
      this.record.id = id;
      this.record.text = text;
      this.record.type = 'accent'
      this.record.method = 'EDIT';
      this.record.index = index;
      this.openSlider();
    },
    confirmFormatEdit(id,text,index){
      this.record.id = id;
      this.record.type = 'file-format'
      this.record.text = text;
      this.record.method = 'EDIT';
      this.record.index = index;
      console.log(this.record);
      this.openSlider();
    },
    confirmDeleteSubject(id,index){
      this.record.id = id;
      this.record.index = index;
      this.record.type = 'subject';
      this.record.method = 'DELETE';
      this.$refs['confirm-modal'].show();
    },
    deleteSubject(){
      axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/subject/'+this.record.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)},'content-type': 'application/x-www-form-urlencoded' }).then(res => {
        if(res.data.status == 1){
          this.$refs['confirm-modal'].hide();
          this.subjects.splice(this.record.index,1);
        }
      })
    },
    confirmDeleteGrade(id,index){
      this.record.id = id;
      this.record.index = index;
      this.record.type = 'grade';
      this.record.method = 'DELETE';
      this.$refs['confirm-modal'].show();
    },
    deleteGrade(){
      axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/grade/'+this.record.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
          this.$refs['confirm-modal'].hide();
          this.grades.splice(this.record.index,1);
        }
      })
    },
    confirmDeleteAccent(id,index){
      this.record.id = id;
      this.record.index = index;
      this.record.type = 'accent';
      this.record.method = 'DELETE';
      this.$refs['confirm-modal'].show();
    },
    deleteAccent(){
      axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/accent/'+this.record.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
          this.$refs['confirm-modal'].hide();
          this.accents.splice(this.record.index,1);
        }
      })
    },
    confirmDeleteFileFormat(id,index){
      this.record.id = id;
      this.record.index = index;
      this.record.type = 'file-format';
      this.record.method = 'DELETE';
      this.$refs['confirm-modal'].show();
    },
    deleteFileFormat(){
      axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/fileformat/'+this.record.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
          this.$refs['confirm-modal'].hide();
          this.fileFormats.splice(this.record.index,1);
        }
      })
    },
    updateRecord(e){
      e.preventDefault();
      if(this.record.method == 'DELETE'){
        switch(this.record.type){
          case 'subject' : this.deleteSubject();
          break;
          case 'grade' : this.deleteGrade(); 
          break;
          case 'accent' : this.deleteAccent();
          break;
          case 'file-format' : this.deleteFileFormat();
          break;
          default : console.log('default type ...');
        }
      }else if(this.record.method == 'EDIT'){
        switch(this.record.type){
          case 'subject' : this.updateSubject();
          break;
          case 'grade' : this.updateGrade(); 
          break;
          case 'accent' : this.updateAccent();
          break;
          case 'file-format' : this.updateFormat();
          break;
          default : console.log('default type ...');
        }
      }
    },
    toAddItem(){
      switch(this.tab){
        case 'subjects':this.saveSubject();
        break;
        case 'grades' : this.saveGrade(); 
        break;
        case 'accent' : this.saveAccent();
        break;
        case 'shop' : this.saveFormat();
        break;
        default : console.log('invalid');
      }
    },
    toEditItem(){
      this.closeSlideBar();
      this.$refs['confirm-modal'].show();
    },
    saveSubject(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/subject',{subject_name:this.add_new},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
           this.subjects.push({value:res.data.data.subject_id, text:res.data.data.subject_name});
           this.add_new = null;
           this.closeSlideBar();
        }
      })
    },
    saveGrade(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/grade',{name:this.add_new},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
           this.grades.push({value:res.data.data.grade_id, text:res.data.data.name});
           this.add_new=null;
           this.closeSlideBar();
        }
      })
    },
    saveAccent(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/accent',{name:this.add_new},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
           this.accents.push({value:res.data.data.accent_id, text:res.data.data.name});
           this.add_new=null;
           this.closeSlideBar();
        }
      })
    },
    saveFormat(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/fileformat',{name:this.add_new},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
           this.fileFormats.push({value:res.data.data.file_format_id, text:res.data.data.name});
           this.add_new=null;
           this.closeSlideBar();
        }
      })
    },
    updateSubject(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/subject/'+this.record.id,{subject_name:this.record.text},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
          this.subjects[this.record.index].text = res.data.data.subject_name;
          this.resetRecords();
          this.$refs['confirm-modal'].hide();
        }
      })
    },
    updateGrade(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/grade/'+this.record.id,{name:this.record.text},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
          this.grades[this.record.index].text = res.data.data.name;
          this.resetRecords();
          this.$refs['confirm-modal'].hide();
        }
      })
    },
    updateAccent(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/accent/'+this.record.id,{name:this.record.text},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
          this.accents[this.record.index].text = res.data.data.name;
          this.resetRecords();
          this.$refs['confirm-modal'].hide();
        }
      })
    },
    updateFormat(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/fileformat/'+this.record.id,{name:this.record.text},{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status == 1){
          this.fileFormats[this.record.index].text = res.data.data.name;
          this.resetRecords();
          this.$refs['confirm-modal'].hide();
        }
      })
    }
  }
}
</script>

<style scoped>
.dashboard-page-header {
    border-bottom: 1px solid #e4e4e4;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn-default:not(:disabled):not(.disabled):active{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.setbtn a.btn {
    float: right !important;
    width: 200px;
}
.btn-outline-light {
    color: #2e2f39;
    background-color: transparent;
    border-color: #ebebed;
}
.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.5;
}
.slide-panel-light {
    background-color: #fff;
    padding: 30px;
    text-align: left;
    overflow: scroll;
}
.request-list-table .table {
    font-size: 14px;
}
table {
    color: #6f6d72;
}
.request-list-table table th {
    background-color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252328;
}
.table thead th {
    vertical-align: bottom;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
.icon-box i{
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-body{
  color: #999;
}
.modal-body p{
  font-size:14px !important
}
</style>