<template>
  <div class="tutors">
    <div class="filter-form">
      <div class="container p-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-2">
          
          <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12" style="padding-top:19px !important;">
              <v-autocomplete :items="subjects" v-model="filter.subject" item-text="text" item-value="value" placeholder="Select Subject" clearable outlined></v-autocomplete>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12" style="padding-top:19px !important;">
              <v-autocomplete :items="grades" item-text="text" v-model="filter.grade" item-value="value" placeholder="Select Grade" clearable outlined></v-autocomplete>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
              <button class="btn btn-default btn-block w-100" v-on:click="searchTutor">Search</button>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <iframe ref="auto_video" width="450" height="250" src="https://tution-site.s3.ap-south-1.amazonaws.com/video/courses_promo.mp4?autoplay=1&cc_load_policy=1&mute=1" title="YouTube video player" frameborder="0" allow="autoplay;encrypted-media;" allowfullscreen></iframe>
            </div>
          </div>
        <div class="row" v-if="tutors.length > 0">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" v-for="tutor in tutors" :key="tutor.id">
            <div class="vendor-thumbnail">
              <div class="vendor-img zoomimg" style="height:200px;">
                <a :href="'/tutors/tutor-details?id='+tutor.id">
                  <img :src="tutor.profile_pic" alt="" class="img-fluid" v-if="tutor.profile_pic" style="pointer-events:none;">
                  <img src="../../assets/images/dummy-tutor.jpg" alt="" class="img-fluid" v-if="!tutor.profile_pic" style="pointer-events:none;">
                </a>
              </div>
              <div class="vendor-content">
                <h2 class="vendor-title"><a :href="'/tutors/tutor-details?id='+tutor.id" class="title">{{tutor.username?tutor.username:(tutor.subject?tutor.subject:tutor.name)}}</a></h2>
                <p class="vendor-address" v-if="tutor.accent">Accent:<i v-if="tutor.accent"> {{tutor.accent.name}}</i></p>
              </div>
              <div class="vendor-meta">
                <div class="vendor-meta-item vendor-meta-item-bordered">
                  <span class="vendor-price" v-if="tutor.price">${{tutor.price.hourly}}/hr</span>
                  <span class="vendor-text">Start From</span>
                </div>
                <div class="vendor-meta-item vendor-meta-item-bordered">
                  <span class="rating-star">
                    <i class="fa fa-star rated" v-for="n in Math.floor(tutor.rate)" :key="'rate'+n"></i>
                    <i class="fa fa-star-half-alt rated" v-if="tutor.rate-Math.floor(tutor.rating) !== 0"></i>
                    <i class="fa fa-star rate-mute" v-for="n in 5-Math.round(tutor.rate)" :key="'unrate'+n"></i>
                    <!-- <i class="fa fa-star rated" v-for="n in tutor.rate" :key="'rated-'+n"></i>
                    <i class="fa fa-star rate-mute" v-for="n in 5-tutor.rating" :key="'unrated-'+n"></i>  -->
                  </span>
                  <span class="rating-count vendor-text">({{tutor.review.length}})</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12 empty-data text-center">
            Courses Not Listed
          </div>
        </div>
        <div class="row" v-if="tutors.length>8">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="pagination justify-content-center">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item active"><a class="page-link" href="">Load More</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "Tutors",
  data(){
    return{
      subjects: [],
      grades:[],
      tutors:[],
      filter:{
        subject:null,
        grade:null,
      },
      subject:'',
    }
  },
  beforeMount(){
    this.getGrades(),
    this.getSubjects(),
    this.getTutors()
  },
  methods:{
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          this.subjects.push({value:subject.subject_id, text:subject.subject_name});
        })
      })
    },
    getGrades(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Grades.forEach(grade => {
          this.grades.push({value:grade.grade_id,text:grade.name});
        })
      })
    },
    getTutors(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutors',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.data.forEach(tutor => {
          this.subject = '';
          if(tutor.is_active == 'Y'){
            tutor.rating = 0;
            tutor.rate =0;
            tutor.review.forEach(rev => {
              if(rev.is_active == 'Y'){
                tutor.rating = tutor.rating+parseInt(rev.star);
              }
            });

            if(tutor.rating> 0 && tutor.review.length>0){
              tutor.rating = tutor.rating/tutor.review.length;
              tutor.rate = Math.round(tutor.rating * 2) / 2;
            }
            tutor.subjects.forEach((subject,index) => {
              this.subject += subject.subject.subject_name;
              if(tutor.subjects.length != index+1){
                this.subject += ','
              }
            })
            this.tutors.push({id:tutor.tutor_id,username:tutor.username,name:tutor.name,accent:tutor.accent,profile_pic:tutor.profile_pic, price:tutor.salary,rating:tutor.rating,rate:tutor.rate,review:tutor.review, subject:this.subject});
          }
        });
      })
    },
    searchTutor(e){
      e.preventDefault();
      this.tutors = [];
      if(this.filter.subject || this.filter.grade){
       var param =+ (this.filter.subject&&this.filter.grade)?'?subject='+this.filter.subject+'&&grade='+this.filter.grade:((this.filter.subject)?'?subject='+this.filter.subject:'?grade='+this.filter.grade);
      }else{
        param = ''
      }
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutors'+param,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.data.forEach(tutor => {
          this.subject = '';
          if(tutor.is_active == 'Y'){
            tutor.rating = 0;
            tutor.rate =0;
            tutor.review.forEach(rev => {
              if(rev.is_active == 'Y'){
                tutor.rating = tutor.rating+parseInt(rev.star);
              }
            });

            if(tutor.rating> 0 && tutor.review.length>0){
              tutor.rating = tutor.rating/tutor.review.length;
              tutor.rate = Math.round(tutor.rating * 2) / 2;
            }
            tutor.subjects.forEach((subject,index) => {
              this.subject += subject.subject.subject_name;
              if(tutor.subjects.length != index+1){
                this.subject += ','
              }
            })
            this.tutors.push({id:tutor.tutor_id,username:tutor.username,name:tutor.name,accent:tutor.accent,profile_pic:tutor.profile_pic, price:tutor.salary,rating:tutor.rating,rate:tutor.rate,review:tutor.review,subject:this.subject});
          }        
        });
      })
    }
  }
}
</script>
<style scoped>
.filter-form {
    background-color: #fff;
    border: 1px solid #e6e5e7;
    margin-bottom: 20px;
}
button.btn {
    font-size: 13px;
    height: 50px;
    font-weight: 400;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.content {
    padding-top: 30px;
    padding-bottom: 60px;
}
.vendor-thumbnail {
    border: 1px solid #e6e5e7;
    padding: 4px;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    position: relative;
}
.zoomimg {
    position: relative;
    overflow: hidden;
}
.vendor-img{
  height: 200px;
}
.vendor-img img {
    width: 100%;
}
.vendor-content {
    padding: 16px 16px 12px 16px;
    margin-bottom: 6px;
}
.vendor-title {
    font-size: 20px;
    margin: 0px 0px 0px 0px;
}
.title {
    color: #252328;
    text-decoration: none;
}
p:last-child {
    margin: 0px;
}

.vendor-address {
    font-size: 12px;
    font-weight: 400;
    color: #6f6d72;
    line-height: 18px;
}
.zoomimg:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
</style>