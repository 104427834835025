<template>
  <section class="register-section">
    <div class="register-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
              <div class="auth-box card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="text-center" style="margin-bottom:15px !important;"><a href="/"><img src="../../assets/images/logo.png" style="margin-bottom: 5px;pointer-events:none;"></a><br>Student Register </h5>
                    </div>
                  </div> 
                  <div class="name">
                    <v-text-field type="text" v-model="register.name" label="Name" :rules="[v => !!v || 'Enter valid name']"><template #label>Name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="email mt-4">
                    <v-text-field type="text" v-model="register.email" :rules="emailRules" :error-messages="(auth_error)?auth_msg:null" @keydown.space.prevent><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="country mt-4">
                    <v-autocomplete  v-model="student.country" :items="countries" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" @input="getRegions"><template #label>Country<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                  </div>
                  <div class="password mt-4">
                    <v-text-field type="password" v-model="register.password" :rules="passwordRules"><template #label>Password<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="repeat_pass mt-4">
                    <v-text-field type="password" v-model="register.re_password" label="Repeat-Password" :rules="passwordConfirmationRules"><template #label>Repeat-Password<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="mobile">
                    <div class="row">
                      <div class="col-lg-5"><v-autocomplete v-model="student.code" label="Country code" :items="codes" item-text="text" item-value="value"  @input="getCountry"></v-autocomplete></div>
                      <div class="col-lg-6"><v-text-field type="number" v-model="register.mobile" label="Mobile" :error-messages="(mob_error)?mob_msg:null"></v-text-field></div>
                    </div>
                  </div>
                  <div class="gender mt-4">
                    <v-autocomplete  v-model="register.gender" label="Gender" :items="gender" item-text="text" item-value="value"></v-autocomplete>
                  </div>
                  <div class="region mt-4">
                    <v-autocomplete  v-model="register.region_id" label="Region" :items="regions" item-text="text" item-value="value"></v-autocomplete>
                  </div>
                  <div class="course mt-4">
                    <v-autocomplete  v-model="register.course_id" label="Course" :items="courses" item-text="text" item-value="value"></v-autocomplete>
                  </div>
                  <div class="row">
                    <div class="col-md-12"> 
                      <b-button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-if="!loading"  v-on:click.prevent="submitRegisterForm">Sign up</b-button>
                      <b-button type="button"  class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-if="loading"><i class="fa fa-spinner fa-spin"></i></b-button>
                    </div>
                  </div>
                  <!-- <div class="or-container">
                    <div class="line-separator"></div>
                    <div class="or-label">or</div>
                    <div class="line-separator"></div>
                  </div> -->
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <a class="btn btn-lg btn-social btn-facebook" style="padding: 14px 50px;">
                        <i class="fab fa-facebook-f fa-fw"></i><span style="margin-left:25px;">Sign in with Facebook</span>
                      </a>
                    </div>
                    <div class="col-md-12 pt-0"> <a class="btn btn-lg btn-google btn-block text-uppercase btn-outline w-100" href="#" style="padding: 14px 25px;"><img src="https://img.icons8.com/color/16/000000/google-logo.png"> Sign in Using Google</a> </div>
                  </div>  -->
                  <br>
                  <p class="text-inverse text-center">Already have an account <a href="/login" data-abc="true" target="_blank">Login</a></p>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Registration successful</div>
    </b-modal>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name:'student-register',
  data(){
    return{
      valid: true,
      can_register:0,
      countries: [],
      courses:[],
      codes:[],
      regions:[],
      student:{
        code:null,
        mobile:null,
        country:null
      },
      register: {
        name: null,
        email: null,
        mobile: null,
        gender: null,
        country_id: null,
        region_id: null,
        course_id: null,
        password: null,
        country_code:null,
        re_password: null
      },
      auth_error: false,
      auth_msg: null,
      gender:[
        {value:1, text:'Male'},
        {value:2, text:'Female'},
        {value:3, text:'Other'}
      ],
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      passwordRules:[
        v => !!v || 'Enter valid password',
        // v => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
      ],
      passwordConfirmationRules:[
        v => !!v || 'Enter valid password',
        v => this.register.password == v || 'Password not match'
      ],
      mob_error:false,
      mob_msg:'',
      loading:false,
    }
  },
  beforeMount(){
    this.getCourse();
    this.getCountries();
    this.getCourses();
  },
  methods: {
    openModal: function (type = "") {
      this.open = (type == "")? this.open : type;
      if (this.open == "success") {
        this.$refs["success"].show();
      }
    },
    getCourse(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Grades.forEach(grade => {
          if(grade.is_active == 'Y'){
            this.courses.push({value:grade.grade_id,text:grade.name});
          }
        })
      });
    },
    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },
    getRegions(){
      this.register.country_id = this.student.country;
      this.countries.forEach(country => {
        if(country.value == this.student.country){
          this.student.code = {value:country.code, text:country.short+ '-' + country.code};
        }
      });
      this.listRegions(this.student.country);
    },
    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/grades',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Grades.forEach(grade => {
          if(grade.is_active == 'Y'){
            this.courses.push({value:grade.grade_id,text:grade.name});
          }
        })
      });
    },
    getCountry(){
      this.countries.forEach(country => {
        if(country.code == this.student.code){
          this.student.country = {value:country.value, text:country.text};
        }
      })
      this.register.country_id = this.student.country.value;
      this.listRegions(this.student.country.value);
    },
    listRegions(id){
      this.regions = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/country/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.states.forEach(region => {
          this.regions.push({value:region.id,text:region.name});
        })
      })
    },
    submitRegisterForm(e){
      e.preventDefault();
      this.loading = true;
      this.valid = this.$refs.form.validate();
      this.auth_msg = null;
      this.auth_error = false;
      if(this.valid == true){
        this.register.country_code = typeof this.student.code === 'object'?this.student.code.value:this.student.code;
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/st_register',this.register,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.status != "error"){
            this.openModal("success");
            setTimeout(() => {
              this.$refs["success"].hide();
              this.$router.push('/login');
            },2000)
          }else{
            this.loading = false;
          }
          // let student = { token: res.access.token, name:this.register.name}
          // this.$cookies.set("tution-student",student);
          // location.reload();
          // this.$router.push('/student/dashboard');
        }).catch((err) => {
          this.loading = false;
          if(err.response.data.email){
            this.auth_error = true;
            this.auth_msg = 'Email is already registered with us'
          }
          if(err.response.data.mobile){
            this.mob_error = true;
            this.mob_msg = 'Mobile is already registered with us'
          }
        });
      }else{
        this.loading = false;
      }
    }
  } 
}
</script>

<style scoped>
.register-section{
    background: url(../../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
}
.register-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.register-block .auth-box {
    margin: 20px auto 0;
    max-width: 450px !important;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.card .card-block {
    padding: 1.25rem;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn:hover {
  color: #ffa25f;
}
.btn-lg {
    font-size: 14px;
    line-height: 1.3333333;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}
</style>