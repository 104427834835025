<template>
  <div class="study_materials">
    <div class="content">
      <div class="container">
        <div class="row" v-if="testimonials.length">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"  v-for="(testimonial,index) in testimonials" :key="index">
            <div class="testimonial-box">
              <span><i class="fas fa-quote-left"></i></span>
              <p class="testimonial_string">{{(testimonial.testimonial.length>200)?testimonial.testimonial.slice(0,200).concat('...'):testimonial.testimonial}}</p>
              <p class="testimonial_string_hover">{{testimonial.testimonial}}</p>
              <h5>{{(testimonial.name)?testimonial.name:'Anonymous'}}</h5>
              <div class="testimonial-dp">
                <img src="../assets/images/dummy-testi.png" v-if="!testimonial.thumbnail" style="pointer-events:none;">
                <img :src="testimonial.thumbnail" v-if="testimonial.thumbnail" style="pointer-events:none;">
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-lg-12 col-md-12 text-center mb-15 empty-data">
            Testimonials not listed
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "Testimonials",
  data(){
    return {
      testimonials: [],
      limit: 8 ,
      books:[],
      items:[],
      filter_subject:null,
    }
  },
  async beforeMount(){
    window.scrollTo(0, 0);
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_testimonials',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      if(res.data.message === 'Tutors listed successfully'){
        res.data.data.forEach(test => {
          if(test.status === 1){
            this.testimonials.push(test);
          }
        })
      }
    })
  },
  methods:{
    loadmore(){
      this.limit += this.limit;
      this.books = [];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/materials',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.products.length > 0 ){
          res.data.products.forEach(product => {
            if(product.is_active == 'Y' && this.books.length <= this.limit){
              if(this.items.length > 0){
                this.items.forEach(item => {
                  if(product.product_id == item.productID){
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:true})
                  }else{
                    this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
                  }
                })     
              }else{
                this.books.push({id:product.product_id, title:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
              }   
            }
          })
        }
      });
    },
  }
}
</script>

<style scoped>
.testimonial-btn{
  border: solid 2px #ce0d3d;
  border-radius: 30px;
  margin-top: 100px;
  color: #252328;
  padding:12px 55px 12px 55px;
  font-size: 16px;
  
  font-weight: bold;
}
.testimonial-btn:hover{
  background: #ce0d3d;
  color: #fff;
}
.testimonials{
  background:#e5e5e5;
  padding-top: 50px;
  padding-bottom: 50px;
}
.testimonials h1{
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 700;
}
.testimonial-box{
  background: white;
  height: 380px;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  transition: all 0.5s;
  margin: 0px 8px 50px 8px;
  text-align: center;
}
.testimonial-box:hover{
  background: #d51439;
  color: #fff;
  transition: all 0.5s;
}
.testimonial-box .testimonial_string{
  display: block;
}
.testimonial-box:hover .testimonial_string{
  display:none;
}
.testimonial-box .testimonial_string_hover{
  display:none;
}
.testimonial-box:hover .testimonial_string_hover{
  display: block;
  max-height: 240px;
  overflow-y: auto;
}
.testimonial-box:hover .fas{
  display: none;
}
.testimonial-box:hover h5{
  color: #fff;
  transition: all 0.5s;
}
.testimonial-box .fas{
  font-size: 50px;
  color: #cccccc;
  margin-bottom: 15px;
}
.testimonial-dp{
  width: 100px;
  height: 100px;
  border-radius: 90px;
  border: solid 2px #fff;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
}
.testimonial-dp img{
  width: 100%;
}
p.ttl-description{
  color: white;
}
</style>