<template>
  <div>
    <div class="container">
      <a href="javascript:;" class="btn btn-outline-pink text-white mr-5 mt-5" v-on:click="filterResult(0)">All</a>
      <a href="javascript:;" class="btn btn-outline-pink text-white mr-5 mt-5" v-on:click="filterResult(1)">Image</a>
      <a href="javascript:;" class="btn btn-outline-pink text-white mr-5 mt-5" v-on:click="filterResult(2)">Video</a>
    </div>
    <div class="gallery" v-if="!loading&&images.length">
    <LightBox  ref="lightbox" :media="media" :show-caption="true" :show-light-box="false" :showThumbs="false"/>
    <!-- <div style=" display: flex;width: 160px;float: right;position: absolute;right: 10px;">
      <v-select v-model="filter" :items="items" item-text="text" item-value="value" label="Filter" v-on:change="filterResult"></v-select>
    </div> -->
    <ul>
      <li class="thumb"
        v-for="(thumb, thumbIndex) in images"
        :key="thumbIndex"
        @click="openGallery(thumbIndex)"
      >
        <img :src="thumb.url" style="width:200px !important;height:200px;" v-if="image_extn.includes(thumb.url.split('.')[thumb.url.split('.').length-1])">
        <p class="playbtn" v-if="video_extn.includes(thumb.url.split('.')[thumb.url.split('.').length-1])"> <img src="../assets/images/play-btn.png"> </p>
        <video width="200" height="195" v-if="video_extn.includes(thumb.url.split('.')[thumb.url.split('.').length-1])">
          
          <source :src="thumb.url+'#t=0.1'" type="video/mp4">
        </video>
      </li>
    </ul>
  </div>
  <div style="text-align:center;margin:50px;" v-show="loading&& !images.length">
     <b-spinner variant="danger" v-if="loading"></b-spinner>
     <div class="empty-data text-center" v-if="!loading">Empty Gallery</div>
  </div>
  </div>
</template>

<script>
// import FsLightbox from "fslightbox-vue";
import LightBox from '@/components/dist/lightbox/Lightbox';
import('vue-it-bigger/dist/vue-it-bigger.min.css')

import axios from 'axios';
  export default {
    name: 'gallery',
    components: {
      // FsLightbox
      LightBox
    },
    data() {
      return {
        images: [],
        videos:[],
        media:[],
        loading:true,
        index: null,
        interval:null,
        image_extn : ["jpg", "gif", "png","jpeg"],
        video_extn : ["mp4", "3gp", "ogg","MOV","mov","MP4","mp4","WMV","wmv","AVI","avi","mkv","MKV","WEBM","webm","HTML5","html5"],
        toggler:false,
        slide:0,
        types:[],
        filter:0,
        items:[
          {value:0,text:'All'},
          {value:1,text:'Images'},
          {value:2,text:'Videos'},
        ],
        gallery:[]
     };
    },
    beforeMount(){
      window.scrollTo(0, 0);
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/gallery?filter=0',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.status === 1){
          this.loading = false;
          this.filter = 0;
          this.gallery = res.data.gallery;
          if(res.data.gallery.length){
            res.data.gallery.forEach(image=> {
              this.images.push({id:image.id,title:'',description:'',url:image.path});
              if(this.image_extn.includes(image.path.split('.')[image.path.split('.').length-1])){
                this.types.push(null);
                this.media.push({
                  type: 'image', // Can be omitted for image
                  src: image.path,
                  caption: image.description,
                });
              }else{
                this.types.push("video");
                this.media.push({
                  type: 'video',
                  sources: [
                    {
                      src:image.path,
                      type: 'video/mp4'
                    }
                  ],
                  caption:image.description
                });
              }
            });
          }
        }
      });
    },
    methods:{
      openGallery(index) {
        this.$refs.lightbox.showImage(index)
      },
      open(thumbIndex){
        this.index = thumbIndex;
        this.slide = thumbIndex+1;
        this.toggler = !this.toggler;
      },
      close(){
        clearInterval(this.interval);
        this.index = null;
      },
      filterResult(filter){
        this.images = [];
        this.media = [];
        this.types = [];
        this.loading = true;  
        if(this.gallery.length){
          this.gallery.forEach(image=> {
            this.loading = false; 
            if(filter == 1){
              if(this.image_extn.includes(image.path.split('.')[image.path.split('.').length-1])){
                this.images.push({id:image.id,title:'',description:'',url:image.path});
                this.types.push(null);
                this.media.push({
                  type: 'image', // Can be omitted for image
                  src: image.path,
                  caption: image.description,
                });
              }
            }else if(filter == 2){
              if(!this.image_extn.includes(image.path.split('.')[image.path.split('.').length-1])){
                this.images.push({id:image.id,title:'',description:'',url:image.path});
                this.types.push("video");
                this.media.push({
                  type: 'video',
                  sources: [
                    {
                      src:image.path,
                      type: 'video/mp4'
                    }
                  ],
                  caption:image.description
                });
              }
            }else{
              this.images.push({id:image.id,title:'',description:'',url:image.path});
              if(this.image_extn.includes(image.path.split('.')[image.path.split('.').length-1])){
                this.types.push(null);
                this.media.push({
                  type: 'image', // Can be omitted for image
                  src: image.path,
                  caption: image.description,
                });
              }else{
                this.types.push("video");
                this.media.push({
                  type: 'video',
                  sources: [
                    {
                      src:image.path,
                      type: 'video/mp4'
                    }
                  ],
                  caption:image.description
                });
              }
            }
          });
        }
      }
    }
  };
</script>

<style scoped>
  .gallery {
      max-width: 980px;
      margin: 0 auto;
      text-align: center;
  }
  ul {
      list-style: none;
      padding: 0;
      margin: 40px 0 0 0;
  }
  .thumb {
      display: inline-block;
      margin: 10px;
      cursor: pointer;
      overflow: hidden;
      border-radius: 3px;
      padding: 5px;
      box-shadow: 0px 1px 4px rgba(129, 129, 129, 0.5);
      position: relative;
      
  }
  p.playbtn{
    position: absolute;
    width: 100%;
    position: absolute;
    display: block;
    width: 200px;
    height: 200px;
    padding-top: 80px;
  }
  p.playbtn img{
    width: 30px;
  }
  .thumb video::before{
    content: "25";
  }
  /* .light-gallery__modal{
    top: 88px !important;
  }
  .light-gallery__image img{
    width: 100% !important;
  } */
</style>
<style>
  /* .light-gallery__image img{
    width: 100% !important;
  } */
  
</style>