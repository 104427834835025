<template>
  <div class="vendor-content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12" v-if="tutor">
          <div class="sidebar-venue">
            <div class="vendor-owner-profile mb-5">
              <div class="vendor-owner-profile-head">
                <div class="vendor-owner-profile-img">
                  <img :src="tutor.profile_pic" alt="" class="img-fluid" v-if="tutor.profile_pic" style="pointer-events:none;">
                  <img src="../../assets/images/dummy-tutor.jpg" alt="" v-if="!tutor.profile_pic" style="pointer-events:none;">
                </div>
                <h4 class="vendor-owner-name mb-0" v-if="tutor.username">{{tutor.username?tutor.username:tutor_subjects+' Tutor'}}</h4>
              </div>
              <div class="vendor-owner-profile-content">
                <div class="mt-xl-4">
                  <div class="tcher-btn">
                    <a v-on:click="addToFavourite" class="btn btn-default-wishlist" v-if="!favourite"><i class="fa fa-heart"></i> <span class="pl-1">Add To Favorite</span></a>
                    <a class="btn btn-fav-tutor" v-if="favourite" disabled><i class="fa fa-heart"></i></a>
                  </div>
                  <div class="tcher-btn">
                    <a v-on:click="viewDemoClass" class="btn btn-primary" data-toggle="modal" data-target="#myModal">View Demo Class</a>
                  </div>
                  <div class="tcher-btn">
                    <a v-on:click="bookClass" class="btn btn-primary btn-theme">Book a Class</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12 col-12" v-if="tutor">
          <div class="vendor-meta bg-white border m-0">
            <div class="vendor-meta-item vendor-meta-item-bordered">
              <span class="vendor-price" v-if="tutor.salary">${{tutor.salary.hourly}}/hr</span>
              <span class="vendor-price" v-else>-</span>
              <span class="vendor-text">Start From</span></div>
            <div class="vendor-meta-item vendor-meta-item-bordered">
              <span class="rating-star" v-if="tutor.rating">
                <i class="fa fa-star rated" v-for="n in Math.floor(rate)" :key="'rate'+n"></i>
                <i class="fa fa-star-half-alt rated" v-if="this.rate-Math.floor(this.tutor.rating) !== 0"></i>
                <i class="fa fa-star rate-mute" v-for="n in 5-Math.round(rate)" :key="'unrate'+n"></i>
              </span>
              <span class="rating-star" v-else>
                <i class="fa fa-star rate-mute" v-for="n in 5" :key="'unrated-'+n"></i> 
              </span>
              <span class="rating-count vendor-text">({{tutor.review.length}})</span>
            </div>
          </div>
          <div class="mt-5">
            <div class="card border card-shadow-none">
              <h3 class="card-header bg-white">About Tutor<!--{{tutor.name}}--></h3>
              <div class="card-body">
                <!--venue-highlights -->
                <div class="venue-highlights">
                  <div class=" table-responsive">
                    <p v-if="tutor.about">{{tutor.about}}</p>
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>Subject</td>
                          <td class="venue-highlight-meta" v-if="tutor_subjects">{{tutor_subjects}}</td>
                          <td class="venue-highlight-meta" v-else>-</td>
                        </tr>
                        <tr>
                          <td>Accent</td>
                          <td class="venue-highlight-meta" v-if="tutor.accent">{{tutor.accent.name}}</td>
                          <td class="venue-highlight-meta" v-else>-</td>
                        </tr>
                        <tr style="display:none">
                          <td>Country</td>
                          <td class="venue-highlight-meta" v-if="tutor.country">{{tutor.country.name}}</td>
                          <td class="venue-highlight-meta" v-else>-</td>
                        </tr>
                        <tr>
                          <td>Educational qualifcation</td>
                          <td class="venue-highlight-meta" v-if="tutor.qualification">{{tutor.qualification.name}}</td>
                          <td class="venue-highlight-meta" v-if="!tutor.qualification">-</td>
                        </tr>
                        <tr>
                          <td>Preferred grades for teaching</td>
                          <td class="venue-highlight-meta" v-if="tutor_grades">{{tutor_grades}}</td>
                          <td class="venue-highlight-meta" v-else>-</td>
                        </tr>
                        <tr>
                          <td>Time Zone</td>
                          <td class="venue-highlight-meta" v-if="tutor.timezone">{{tutor.timezone}}</td>
                          <td class="venue-highlight-meta" v-else>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- /.venue-highlights -->
              </div>
            </div>
            <!-- <div id="reviews">
              <div class="card border card-shadow-none ">
                <div class="card-header bg-white">
                  <h3 class="mb-0 d-inline-block">Reviews</h3>
                </div>
                <div class="card-body">
                  <div class="review-block">
                    <div class="row">
                      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div class="review-sidebar">
                          <div class="review-total" v-if="tutor.rating">{{Math.round(tutor.rating*10)/10}}</div>
                          <div class="review-total" v-else>0</div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div class="review-box">
                          <div class="review-text">Reviews</div>
                          <span class="rated">
                            <i class="fa fa-star rated" v-for="n in Math.floor(rate)" :key="'rate'+n"></i>
                            <i class="fa fa-star-half-alt rated" v-if="this.rate-Math.floor(this.tutor.rating) !== 0"></i>
                            <i class="fa fa-star rate-mute" v-for="n in 5-Math.round(rate)" :key="'unrate'+n"></i>
                          </span>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-3 col-md-6 col-sm-12 col-12">
                        <button class="btn btn-default btn-sm float-right d-inline-block text-white" v-on:click="open=!open">write a review</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- <div class="card border card-shadow-none leave-review" id="review-form" v-if="open">
              <div class="card-header bg-white mb-0">
                <h3 class="mb-0">Write Your Reviews</h3>
              </div>
              <div class="card-body">
                <div class="review-rating-select">
                  <div class="rate-selection">
                    <span class="text-dark">Quality Service</span>
                    <span id="rateYo1" class="jq-ry-container" style="width: 70px;">
                      <div class="jq-ry-group-wrapper">
                        <div class="jq-ry-normal-group jq-ry-group">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" v-for="n in review.starcount" :key="'rate'+n" v-on:click.prevent="rateTutor(n)" viewBox="0 12.705 512 486.59" x="0px" y="0px" xml:space="preserve" width="14px" height="14px" fill="#ff9227">
                            <polygon points="256.814,12.705 317.205,198.566 512.631,198.566 354.529,313.435 414.918,499.295 256.814,384.427 98.713,499.295 159.102,313.435 1,198.566 196.426,198.566 "></polygon>
                          </svg>
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" v-for="n in (5-(review.starcount))" :key="'unrate'+n" v-on:click.prevent="rateTutor((review.starcount?review.starcount+n:n))" viewBox="0 12.705 512 486.59" x="0px" y="0px" xml:space="preserve" width="14px" height="14px" fill="#d3d3d3">
                            <polygon points="256.814,12.705 317.205,198.566 512.631,198.566 354.529,313.435 414.918,499.295 256.814,384.427 98.713,499.295 159.102,313.435 1,198.566 196.426,198.566 "></polygon>
                          </svg>
                        </div>
                        <div class="jq-ry-rated-group jq-ry-group" style="width: 72%;">
                          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" v-for="n in 5" :key="n" viewBox="0 12.705 512 486.59" x="0px" y="0px" xml:space="preserve" width="14px" height="14px" fill="#ff9227">
                            <polygon points="256.814,12.705 317.205,198.566 512.631,198.566 354.529,313.435 414.918,499.295 256.814,384.427 98.713,499.295 159.102,313.435 1,198.566 196.426,198.566 "></polygon>
                          </svg>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
                <div class="row">
                  <v-form ref="form" lazy-validation v-model="valid">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt30">
                      <label class="control-label pb-1" for="review">Write Your Review</label>
                      <v-textarea v-model="review.description" id="review" name="review" rows="5" :rules="[v => !!v || 'Enter description']" outlined></v-textarea>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label class="control-label pb-1" for="name">Name</label>
                      <v-text-field id="name" name="name" type="text" v-model="review.name" class="input-md" readonly outlined></v-text-field>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <label class="control-label pb-1" for="email">Email</label>
                      <v-text-field id="email" name="email" type="text" v-model="review.email" readonly class="input-md" outlined></v-text-field>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <b-button type="submit" v-on:click="submitReview" class="btn btn-default">Submit review</b-button>
                    </div>
                  </v-form>
                </div>
              </div>
            </div> -->
            <!-- <div v-if="tutor.review.length">
              <div class="card border card-shadow-none " v-for="rev in tutor.review" :key="rev.review_id">
                <div class="card-header bg-white mb-0">
                  <div class="review-user d-flex">
                    <div class="user-img pr-2" v-if="rev.student"> 
                      <img :src="rev.student.profile_pic" v-if="rev.student.profile_pic" class="rounded-circle" style="height:51px;width:51px;">
                      <img src="../../assets/images/dashboard-profile.jpg" class="rounded-circle" alt="" v-else style="height:51px;width:51px;">
                    </div>
                    <div class="user-meta w-100">
                      <h5 class="user-name w-100 mb-0">{{rev.name}}<span class="user-review-date float-right">{{convertDate(rev.created_at)}}</span></h5>
                      <div class="given-review">
                        <span class="rated">
                          <i class="fa fa-star rated" v-for="n in parseInt(rev.star)" :key="'rated'+n"></i> 
                          <i class="fa fa-star rate-mute" v-for="n in 5-(parseInt(rev.star))" :key="'unrated'+n"></i> 
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="review-descriptions">
                    <p>{{rev.description}}</p>
                  </div>
                </div>
              </div>
            </div>  -->
          </div>
        </div>
      </div>
    </div>
    <b-modal id="demo" ref="demo-class" size="lg" title="Demo Class" body-bg-variant="light" bod body-text-variant="light" hide-footer centered>
      <div class="container text-center p-0" v-if="tutor">
        <div v-if="tutor.demo_class" style="padding:0;">
          <video width="640" height="360" controls="controls" preload="none">
            <source :src="tutor.demo_class"/>
            <source src="movie.ogg" type="video/ogg" />Your browser does not support the video tag.
          </video>
        </div>
        <div v-else class="empty-data pt-6 pb-5">Didn't uploaded demo class yet</div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: "Tutors-list",
  data(){
    return {
      tutor:null,
      favourite:false,
      tutor_subjects:'',
      tutor_grades:'',
      open:false,
      valid:true,
      student:null,
      review:{
        name:null,
        email:null,
        description: null,
        starcount:0,
        tutor_id: null,
      },
      rate:0,
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
    }
  },
  beforeMount(){
    this.review.tutor_id = this.$route.query.id;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/'+this.$route.query.id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      this.tutor = res.data.data;
      res.data.data.subjects.forEach((sub,index) => {
        if(sub.is_active == 'Y'&&sub.subject){
          this.tutor_subjects += sub.subject.subject_name;
          if(res.data.data.subjects.length != index+1){
            this.tutor_subjects += ','
          }
        }
      });
      res.data.data.grades.forEach((g,index) => {
        if(g.is_active == 'Y'&&g.grade){
          this.tutor_grades += g.grade.name;
          if(res.data.data.grades.length != index+1){
            this.tutor_grades += ','
          }
        }
      })
      if(this.$cookies.get('tution-student')){
        this.checkFavTutor();
      }
      this.tutor.rating = 0;
      this.tutor.review.forEach(rev => {
        if(rev.is_active == 'Y'){
          this.tutor.rating = this.tutor.rating+parseInt(rev.star);
        }
      });

      if(this.tutor.rating> 0 && this.tutor.review.length>0){
        this.tutor.rating = this.tutor.rating/this.tutor.review.length;
        this.rate = Math.round(this.tutor.rating * 2) / 2;
      }
    });
    if((this.$cookies.get('tution-student'))){ 
       axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
      this.student = res.data.data.student;
      this.review.email = this.student.user.email;
      this.review.name = this.student.name;
    })
    }else{
      this.review.email=null;
      this.review.name=null;
    }
  },
  methods:{
    checkFavTutor(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/favoritetutors',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.message == 'Student retrieved successfully'){
          var fav_tutors = res.data.data;
          fav_tutors.forEach(fav => {
            if(fav.tutorId == this.tutor.tutor_id && fav.is_active == 'Y'){
              this.favourite = true;
            }
          })
        }
      })
    },
    convertDate(timestamp){
      var months = {'01':'Jan', '02':'Feb' , '03':'Mar' , '04':'Apr' , '05':'May' , '06':'June' , '07':'July' , '08':'Aug' , '09':'Sep' , '10':'Oct' , '11':'Nov' , '12':'Dec' , };
      var date_ob = new Date(timestamp)
      var year = date_ob.getFullYear();
      var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
      var date = ("0" + date_ob.getDate()).slice(-2);
      var result = date+' '+months[month]+','+year;
      return result;
    },
    addToFavourite(e){
      e.preventDefault();
      if(this.$cookies.get('tution-student')){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/favoritetutor',{tutorId:this.tutor.tutor_id},{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
          if(res.data.meaasge == 'Student Favorite Created successfully'){
            this.favourite = true;
          }
        })
      }else{
        this.$router.push('/login');
      }
    },
    viewDemoClass(e){
      e.preventDefault();
      this.$refs['demo-class'].show();
    },
    bookClass(e){
      e.preventDefault();
      if(this.$cookies.get('tution-student')){
        // this.$cookies.set('book_class',{tutor_id:this.tutor.tutor_id});
        this.$router.push('/pricing?id='+this.tutor.tutor_id);
      }else{
        this.$router.push('/login')
      }
    },
    rateTutor(rate){
      this.review.starcount = rate;
    },
    submitReview(e){
      e.preventDefault();
      this.valid = this.$refs.form.validate();
      if(this.$cookies.get('tution-student')){
        if(this.valid == true){
          axios.post(process.env.VUE_APP_TUTION_SITE_API+'/reviewtutor',this.review,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
            if(res.data.meaasge == 'Tutor review Created successfully'){
              this.open = false;
              this.review.description =null;
              this.review.starcount = null;
              this.tutor.review.push(res.data.data);
              this.tutor.rating = 0;
              this.tutor.review.forEach(rev => {
                  this.tutor.rating = this.tutor.rating+parseInt(rev.star);
              });
                this.tutor.rating = this.tutor.rating/this.tutor.review.length;
                this.rate = Math.round(this.tutor.rating * 2) / 2;
            }
          })
        }
      }else{
        this.$router.replace({name:'student-login', params:{return:true}});
        // this.$router.push('/login')
      }

    }
  }
}
</script>
<style scoped>
.vendor-content-wrapper {
    margin-top: 40px;
}
.sidebar-venue {
    position: sticky;
    top: 0;
    z-index: 1;
}
.vendor-owner-profile {
    background-color: #fff;
    border: 1px solid #e5e6e7;
    border-radius: 4px;
    padding-bottom: 20px;
}
.vendor-owner-profile-head {
    padding: 0px 0px 20px 0px;
    text-align: center;
    border-bottom: 1px solid #e5e6e7;
}
.vendor-owner-profile-img {
    margin-bottom: 18px;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
.vendor-owner-name {
    line-height: 18px;
}
.vendor-owner-profile-content {
    font-size: 14px;
}
.tcher-btn {
    padding: 5px 25px 5px 25px;
}
.vendor-owner-profile-content .btn {
    width: 100%;
}
.btn-default-wishlist {
    background-color: #fff;
    border-radius: 4px;
    padding: 12px 10px;
    border: 1px solid #ebebeb;
    color: #0079df;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.border {
    border-color: #e6e5e7 !important;
}
.card-shadow-none {
    box-shadow: none !important;
}
.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-body {
    padding: 20px;
}
.venue-highlights {
    margin-bottom: 40px;
}
.venue-highlights>.table-responsive>.table-bordered {
    border: 1px solid #dee2e6;
}
.table {
    margin-bottom: 0px;
}
.table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6;
}
.venue-highlight-meta {
    text-align: right;
    font-weight: 500;
}
.border {
    border-color: #e6e5e7 !important;
}
.card-shadow-none {
    box-shadow: none !important;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.review-sidebar {
    border-right: 1px solid #e5e6e7;
    margin-right: 20px;
}
.review-total {
    font-size: 42px;
    color: #252328;
    font-weight: 500;
    line-height: 35px;
    margin-bottom: 6px;
}
.review-text {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
    color: #252328;
    font-weight: 500;
}
.btn-default-wishlist{
  background-color: #fff;
    border-radius: 4px;
    padding: 12px 10px;
    border: 1px solid #ebebeb;
    color: #0079df;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.btn-fav-tutor{
    background-color: #fff;
    border-radius: 4px;
    padding: 12px 10px;
    border: 1px solid #ebebeb;
    color: #ce0d3d;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.btn-fav-tutor:hover, .btn-fav-tutor:active{
  color: #ce0d3d;
}
.btn-primary {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn.btn-theme {
    color: #fff;
    background: #ce0d3d;
    border-color: #ce0d3d;
}
.btn-default-wishlist:hover {
    background-color: #0079df;
    color: #fff !important;
    border: 1px solid #0079df;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn.btn-theme:hover{
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.user-name {
    font-size: 15px !important;
    color: #252328;
}
.user-review-date {
    font-size: 12px !important;
    color: #a09ea2;
}
.given-review {
    font-size: 10px !important;
    line-height: 14px;
    margin-top: 6px;
}
.review-descriptions {
    line-height: 1.7;
    font-size: 15px !important;
}
</style>