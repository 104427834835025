import Vue from 'vue'
import Home from '@/views/Home.vue'
import TutorRegistration from '@/views/tutor/Registration.vue'
import TutorLogin from '@/views/tutor/TutorLogin.vue'
import TutorHome from '@/views/tutor/Home.vue'
import TutorChatRoom from '@/views/tutor/ChatRoom.vue'
import TutorUpcomingClasses from '@/views/tutor/UpcomingClasses.vue'
import TutorCompletedClasses from '@/views/tutor/CompletedClasses.vue'
import TutorTimeShedule from '@/views/tutor/TimeShedule.vue'
import TutorProfile from '@/views/tutor/Profile.vue'
import TutorCategory from '@/views/tutor/Category.vue'
import TutorBankDetails from '@/views/tutor/BankDetails.vue'
import TutorEarnings from '@/views/tutor/Earnings.vue'
import TutorSettings from '@/views/tutor/Settings.vue'
import TutorsList from '@/views/tutor/Tutors.vue'
import TutorsDetails from '@/views/tutor/TutorsDetails.vue'

import StudentRegister from '@/views/student/Register.vue'
import StudentRegisterForm from '@/views/student/StudentRegistrationForm.vue'
import StudentLogin from '@/views/student/Login.vue'
import StudyMaterials from '@/views/StudyMaterials.vue'
import VideoCourse from '@/views/VideoCourse.vue'
import VideoCourseDetail from '@/views/VideoCourseDetail.vue'
import StudentHome from '@/views/student/Home.vue'
import BookedClassesList from '@/views/student/BookedClasses.vue'
import ChatRoom from '@/views/student/ChatRoom.vue'
import CompletedClassesList from '@/views/student/CompletedClasses.vue'
import Downloads from '@/views/student/Downloads.vue'
import MyVideoCourses from '@/views/student/MyVideoCourses.vue'
import VideoClassRoom from '@/views/student/MyVideoClassRoom.vue'
import Profile from '@/views/student/Profile.vue'
import FavTutors from '@/views/student/FavouriteTutors.vue'
import AdminStudentDetails from '@/views/admin/StudentDetails.vue'
import AdminStudentFormDetails from '@/views/admin/StudentFormDetails.vue'
import ResetPassword from '@/views/student/ResetPassword.vue'

import AdminLogin from '@/views/admin/Login.vue'
import AdminDashboard from '@/views/admin/Dashboard.vue'
import AdminTutor from '@/views/admin/Tutors.vue'
import AdminStudents from '@/views/admin/Students.vue'
import AdminLiveClasses from '@/views/admin/LiveClasses.vue'
import AdminVideoCourses from '@/views/admin/VideoCourses.vue'
import AdminAddVideoCourses from '@/views/admin/AddVideoCourses.vue'
import AdminEditVideoCourses from '@/views/admin/EditVideoCourse.vue'
import AdminShop from '@/views/admin/Shop.vue'
import AdminGallery from '@/views/admin/Gallery.vue'
import AdminSettings from '@/views/admin/Settings.vue'
import AdminTutorDetails from '@/views/admin/TutorDetails.vue'
import AdminChatRoom from '@/views/admin/ChatRoom.vue'
import AdminTestimonial from '@/views/admin/Testimonials.vue'
import AdminSliders from '@/views/admin/Sliders.vue'
import AdminPayments from '@/views/admin/Payments.vue'
import AdminOrders from '@/views/admin/Orders.vue'
import AdminCounselling from '@/views/admin/Counselling.vue'
import AdminCounsellingView from '@/views/admin/CounsellingDetails.vue'
import ChatSettings from '@/views/admin/ChatSetting.vue'
import VideoClassDetails from '@/views/admin/VideoCourseDetail.vue'
import OrderVideoClassDetails from '@/views/OrderVideoCourseDetail.vue'

import CounselingRegisterForm from '@/views/student/CounselingRegistrationForm.vue'

import Cart from '@/views/Cart.vue'
import CourseCart from '@/views/CourseCart.vue'
import About from '@/views/About.vue'
import Academic from '@/views/Academicyear.vue'
import Terms from '@/views/Terms.vue'
import Staff from '@/views/Staff.vue'
import Faq from '@/views/faq.vue'
import AmccMembers from '@/views/amccmembers.vue'
import Privacy from '@/views/Privacy.vue'
import Refund from '@/views/Refund.vue'
import Contact from '@/views/Contact.vue'
import Pricing from '@/views/Pricing.vue'
import HourlyPackage from '@/views/PackageCalculation.vue'
import Testimonials from '@/views/Testimonials.vue'
import Gallery from '@/views/Gallery.vue'
import Payment from '@/views/Payment.vue'
import Donation from '@/views/Donation.vue'
import Order from '@/views/Order.vue'
import ShopDetail from '@/views/ShopDetails.vue'
import ChatPool from '@/views/ChatPool.vue'
import ClassTimer from '@/views/ClassTimer.vue'
import NotFound from '@/views/NotFound.vue' 

import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: false,
      margin:false,
      subhead:false
    },
  },
  {
    path: '/tutor_registration',
    name: 'tutor-registration',
    component: TutorRegistration,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:true
    },
  },
  {
    path: '/tutor_login',
    name: 'tutor-login',
    component: TutorLogin,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/tutor/dashboard',
    name: 'tutor-home',
    component: TutorHome,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
    // get component(){
    //   if(window.$cookies.get("tutor-token")){
    //     return TutorHome
    //   }else{
    //     return Home
    //   }
    // },
  },
  {
    path: '/tutor/chatroom',
    name: 'tutor-chatroom',
    component: TutorChatRoom,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/upcoming-classes',
    name: 'tutor-upcoming-classes',
    component: TutorUpcomingClasses,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/completed-classes',
    name: 'tutor-completed-classes',
    component: TutorCompletedClasses,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/time-shedule',
    name: 'tutor-time-shedule',
    component: TutorTimeShedule,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/profile',
    name: 'tutor-profile',
    component: TutorProfile,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/category',
    name: 'tutor-category',
    component: TutorCategory,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/bank-details',
    name: 'tutor-bank-details',
    component: TutorBankDetails,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/earnings',
    name: 'tutor-earnings',
    component: TutorEarnings,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutor/settings',
    name: 'tutor-settings',
    component: TutorSettings,
    meta: {
      requiresAuth: true,
      margin:true,
      tutorHeader:true,
      tutorSidebar:true
    },
  },
  {
    path: '/tutors',
    name: 'Tutors',
    component: TutorsList,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/tutors/tutor-details',
    name: 'Tutors-list',
    component:TutorsDetails,
    meta: {
      requiresAuth: false,
      margin:false,
      subhead:true
    },
  },

  {
    path: '/register',
    name: 'student-register',
    component: StudentRegister,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/student-registration',
    name: 'student-register-form',
    component: StudentRegisterForm,
    meta: {
      requiresAuth: false,
      margin:true
    },
  },
  {
    path: '/counseling-registration',
    name: 'counseling-register-form',
    component: CounselingRegisterForm,
    meta: {
      requiresAuth: false,
      margin:false,
      subhead:false,
    },
  },
  {
    path: '/login',
    name: 'student-login',
    component: StudentLogin,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/student/dashboard',
    name: 'student-home',
    component: StudentHome,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
    // get component(){
    //   if(window.$cookies.get("tution-student")){
    //     return StudentHome
    //   }else{
    //     return Home
    //   }
    // },
  },
  {
    path: '/student/booked-classes',
    name: 'booked-classes',
    component: BookedClassesList,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/booking/:key',
    name: 'timer',
    component: ClassTimer,
    meta: {
      requiresAuth: true,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/student/chatroom',
    name: 'student-chatroom',
    component: ChatRoom,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/chat-pool',
    name: 'tution-chatpool',
    component: ChatPool,
    meta: {
      requiresAuth: true,
      margin:true

    }
  },
  {
    path: '/admin/chatroom',
    name: 'admin-chatroom',
    component: AdminChatRoom,
    meta: {
      requiresAuth: true,
      margin:true
    },
  },
  {
    path: '/student/completed-classes',
    name: 'completed-classes',
    component: CompletedClassesList,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/student/downloads',
    name: 'downloads',
    component: Downloads,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/student/my-video-courses',
    name: 'myvideocourses',
    component: MyVideoCourses,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/student/video-class-room/:key',
    name: 'videoclassroom',
    component: VideoClassRoom,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/tutn-auth/video-class-room/:key',
    name: 'videoclassdetails',
    component: VideoClassDetails,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
    },
  },
  {
    path: '/order/video-class-room/:transactionID/:key',
    name: 'ordervideoclassdetails',
    component: OrderVideoClassDetails,
    meta: {
      requiresAuth: true,
      subhead:true
    },
  },
  {
    path: '/student/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/student/favourite-tutors',
    name: 'fav-titors',
    component: FavTutors,
    meta: {
      requiresAuth: true,
      margin:true,
      studentHeader:true,
      studentSidebar:true
    },
  },
  {
    path: '/video_course',
    name: 'videoCourse',
    component: VideoCourse,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/video_course/:video_course_title',
    name: 'videoCourseDetail',
    component: VideoCourseDetail,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/study_materials',
    name: 'studyMaterials',
    component: StudyMaterials,
    meta: {
      requiresAuth: false,
      subhead: true
    },
  },
  {
    path: '/study_materials/:material',
    name: 'shopDetail',
    component: ShopDetail,
    meta: {
      requiresAuth: false,
      subhead: true
    },
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart,
    meta: {
      requiresAuth: true,
      subhead:false
    },
  },
  {
    path: '/course/cart',
    name: 'course-cart',
    component: CourseCart,
    meta: {
      requiresAuth: true,
      subhead:false
    },
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: About,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/academic-year',
    name: 'academic-year',
    component: Academic,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: Contact,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/admin',
    name: 'admin-login',
    component: AdminLogin,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/tutn-auth/dashboard',
    name: 'admin-dashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/tutors',
    name: 'admin-tutor',
    component: AdminTutor,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/students',
    name: 'admin-students',
    component: AdminStudents,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/video-courses',
    name: 'admin-video-courses',
    component: AdminVideoCourses,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/add-video-courses',
    name: 'admin-add-video-courses',
    component: AdminAddVideoCourses,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/edit/video-course/:id',
    name: 'admin-edit-video-courses',
    component: AdminEditVideoCourses,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/live-classes',
    name: 'admin-live-classes',
    component: AdminLiveClasses,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/shop',
    name: 'admin-shop',
    component: AdminShop,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/gallery',
    name: 'admin-gallery',
    component: AdminGallery,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/testimonial',
    name: 'admin-testimonial',
    component: AdminTestimonial,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/sliders',
    name: 'admin-sliders',
    component: AdminSliders,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/tutor-view/:id',
    name: 'admin-tutor-details',
    component: AdminTutorDetails,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    }
  },
  {
    path: '/tutn-auth/student-view/:id',
    name: 'admin-student-details',
    component: AdminStudentDetails,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    }
  },
  {
    path: '/tutn-auth/student-form/:id',
    name: 'admin-student-form-details',
    component: AdminStudentFormDetails,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    }
  },
  {
    path: '/tutn-auth/chat-settings',
    name: 'chat-settings',
    component: ChatSettings,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/settings',
    name: 'admin-settings',
    component: AdminSettings,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/payments',
    name: 'admin-payments',
    component: AdminPayments,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    },
  },
  {
    path: '/tutn-auth/orders',
    name: 'admin-orders',
    component: AdminOrders,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    }
  },
  {
    path: '/tutn-auth/counselling',
    name: 'admin-counselling',
    component: AdminCounselling,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    }
  },
  {
    path: '/tutn-auth/counselling/:id',
    name: 'admin-counselling-form-datails',
    component: AdminCounsellingView,
    meta: {
      requiresAuth: true,
      margin:true,
      adminHeader:true,
      adminSidebar:true
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/pricing/hourly-package',
    name: 'hourly-package',
    component: HourlyPackage,
    meta: {
      requiresAuth: true,
      subhead:true
    },
  },
  {
    path: '/pricing/monthly-package',
    name: 'monthly-package',
    component: HourlyPackage,
    meta: {
      requiresAuth: true,
      subhead:true
    },
  },
  {
    path: '/pricing/yearly-package',
    name: 'yearly-package',
    component: HourlyPackage,
    meta: {
      requiresAuth: true,
      subhead:true
    },
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: Testimonials,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: Privacy,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/refund-policy',
    name: 'Refund',
    component: Refund,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/staff',
    name: 'Staff',
    component: Staff,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/faq',
    name: 'Faq',
    component: Faq,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/amccmembers',
    name: 'AmccMembers',
    component: AmccMembers,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/donate',
    name: 'Donation',
    component: Donation,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      requiresAuth: false,
      margin:true,
      subhead:false
    },
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    meta: {
      requiresAuth: false,
      subhead:true
    },
  },
  {
    path: '*',
    redirect: '/not-found'
  },
  {
    path: '/not-found',
    name: 'NotFound',
    component: NotFound,
    meta:{
      subhead:false
    }
  }
]

Vue.use(VueCookies);

const router = new VueRouter({
  mode: 'history', //to remove # from url
  routes: routes
})

router.beforeEach((to, from, next) => {
  const authenticatedTutor = window.$cookies.get('tution-tutor')?window.$cookies.get('tution-tutor').token:null;
  const authenticatedStudent = window.$cookies.get('tution-student')?window.$cookies.get('tution-student').token:null;
  const authenticatedAdmin = window.$cookies.get('tution-admin')?window.$cookies.get('tution-admin').token:null;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !authenticatedAdmin && to.path.split('/').includes('tutn-auth')) next('/admin');
  else if(requiresAuth && !authenticatedTutor && to.path.split('/').includes('tutor')) next('/tutor_login');
  else if(requiresAuth && !authenticatedStudent && to.path.split('/').includes('student')) next('/login');
  else if(!requiresAuth && authenticatedStudent && (to.path == '/tutor_login' || to.path == '/admin' || to.path.split('/').includes('tutn-auth') || to.path.split('/').includes('tutor'))) next('/student/dashboard');
  else if(!requiresAuth && authenticatedTutor && (to.path == '/login' || to.path == '/admin' || to.path.split('/').includes('tutn-auth') || to.path.split('/').includes('student'))) next('/tutor/dashboard');
  else if(!requiresAuth && authenticatedAdmin && (to.path == '/tutor_login' || to.path == '/login' || to.path.split('/').includes('student') || to.path.split('/').includes('tutor'))) next('/tutn-auth/dashboard');
  else next();
});

export default router;