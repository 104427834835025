<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <h3 class="dashboard-page-title">Chat Room</h3>
            <p class="d-block"></p>
          </div>
        </div>
        <div class="dashboard-vendor-list">
            <iframe :src="'https://tokbox.com/embed/embed/ot-embed.js?embedId=3ea4533b-35ee-45bf-9ce0-a67894786ced&room='+room_key+'&iframe=true'" width=950 height=640 scrolling="auto" allow="microphone; camera" ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name:'student-chatroom',
  data(){
    return {
      room_key:null,
    }
  },
  beforeMount(){
    this.room_key = this.$route.query.key;
  },
  mounted(){
    setTimeout(function(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/completedclass/'+this.$route.query.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}});
    }, 300000);
  }
}
</script>