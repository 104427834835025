<template>
  <div class="home">
    <div class="hero-section-third">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="">
              <div class="search-head">
                
                <h1 class="search-head-title text-white">get <span class="big-txt">online </span>learning</h1>
                <p class="ttl-description" style="width:54%;">Our main aim is to help those children who are home schooled and their moms who are too busy to help their kids to provide a balanced structured education and skills  development plus private tutoring. We also provide counseling services</p>
<a href="/tutor_registration" class="btn btn-banner btn-sm" style="font-size: 18px; margin-right:10px; margin-top:10px">Tutor registration</a>
 <a href="/student-registration" class="btn btn-banner btn-sm" style="font-size: 18px; margin-top:10px">Student registration</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="acadamic-year">
      <div class="container acadamic-info">
        <h3>Help your child meet their academic skills</h3>
        <p>Now accepting applications for the academic year 2024-2025.</p>
        <a href="/academic-year" class="acadamic-btn">View Our Academic Year Program</a>
      </div>
    </div>
    <div class="popular_subject pt-12 pb-12">
      <div class="container">
        <div class="row">
          <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
            <div class="section-title text-center mb-12">
              <!-- section title start-->
              <h2 class="mb10">Popular Subjects</h2>
              <p>Here is a quick access for most popular subjects in fromyourtutor

</p>
            </div>
            <!-- /.section title start-->
          </div>
        </div>
        <div class="row">
          <!-- venue-categoris-block-->
          <div class="col">
              <div class="vendor-categories-block zoomimg">
                <div class="vendor-categories-img g1"> <a href="/tutors"><img src="../assets/images/maths.png" alt="" class="img-fluid mrgn1" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                    <div class="vendor-categories-text">
                    <h4 class="mb-0"><a href="/tutors" class="vendor-categories-title">Mathematics</a></h4>
                  </div>
                </div>
              </div>
              <!-- /.venue-categoris-block-->
          </div>
          <div class="col">
              <div class="vendor-categories-block zoomimg">
                  <div class="vendor-categories-img g2"> <a href="/tutors"><img src="../assets/images/physics.png" alt="" class="img-fluid mrgn2" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                      <div class="vendor-categories-text">
                          <h4 class="mb-0"><a href="/tutors" class="vendor-categories-title">Science</a></h4>
                          
                      </div>
                  </div>
              </div>
              <!-- /.venue-categoris-block-->
          </div>
          <div class="col">
            <a href="https://forms.fromyourtutor.com/">
              <div class="vendor-categories-block zoomimg">
                  <div class="vendor-categories-img g3"> <a href="https://forms.fromyourtutor.com/"><img src="../assets/images/counceling.png" alt="" class="img-fluid mrgn3" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                      <div class="vendor-categories-text">
                          <h4 class="mb-0"><a href="https://forms.fromyourtutor.com/" class="vendor-categories-title">Counseling</a></h4>
                          
                      </div>
                  </div>
              </div>
            </a>
              <!-- /.venue-categoris-block-->
          </div>
          <div class="col">
              <div class="vendor-categories-block zoomimg">
                  <div class="vendor-categories-img g4"> <a href="/tutors"><img src="../assets/images/speech.png" alt="" class="img-fluid mrgn4" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                      <div class="vendor-categories-text">
                          <h4 class="mb-0"><a href="/tutors" class="vendor-categories-title">Speech Therapy </a></h4>
                          
                      </div>
                  </div>
              </div>
              <!-- /.venue-categoris-block-->
          </div>
          <div class="col">
              <div class="vendor-categories-block zoomimg">
                  <div class="vendor-categories-img g5"> <a href="/tutors"><img src="../assets/images/english.png" alt="" class="img-fluid mrgn5" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                      <div class="vendor-categories-text">
                          <h4 class="mb-0"><a href="/tutors" class="vendor-categories-title">English</a></h4>
                          
                      </div>
                  </div>
              </div>
              <!-- /.venue-categoris-block-->
          </div>
          <div class="col">
              <div class="vendor-categories-block zoomimg">
                  <div class="vendor-categories-img g6"> <a href="/tutors"><img src="../assets/images/arabic.png" alt="" class="img-fluid mrgn6" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                      <div class="vendor-categories-text">
                          <h4 class="mb-0"><a href="/tutors" class="vendor-categories-title">Arabic</a></h4>
                          
                      </div>
                  </div>
              </div>
              <!-- /.venue-categoris-block-->
          </div>
          <div class="col">
              <div class="vendor-categories-block zoomimg">
                  <div class="vendor-categories-img g7"> <a href="/tutors"><img src="../assets/images/quran.png" alt="" class="img-fluid mrgn7" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                      <div class="vendor-categories-text">
                          <h4 class="mb-0"><a href="/tutors" class="vendor-categories-title">quran</a></h4>
                          
                      </div>
                  </div>
              </div>
              <!-- /.venue-categoris-block-->
          </div>
          <div class="col">
              <div class="vendor-categories-block zoomimg">
                  <div class="vendor-categories-img g8"> <a href="/tutors"><img src="../assets/images/islam.png" alt="" class="img-fluid mrgn8" style="pointer-events:none;"></a></div>
                  <div class="vendor-categories-overlay">
                      <div class="vendor-categories-text">
                          <h4 class="mb-0"><a href="/tutors" class="vendor-categories-title">Islamic studies
</a></h4>
                          
                      </div>
                  </div>
              </div>
              <!-- /.venue-categoris-block-->
          </div>
        </div>
      </div>
    </div>

    <div class="shop-caousal" v-if="sliders.length>0">
      <div class="container">
        <carousel :autoplay="true" :items="1" :nav="false" :margin="3">
          <div class="vendor p-2 shopCarBox" v-for="(slider,index) in sliders" :key="index">
            <div class="vendor-img" style="height:400px;text-align: center;">
                <img :src="slider.thumbnail.split(' ')[0]" alt="" class="img-fluid" v-if="slider.thumbnail" style="pointer-events:none;height: 400px;width: 1200px !important;">
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <div class="shop-caousal">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="text-center">
              <h2 class="mb-6 mt-6">E-Shop</h2>
            </div>
          </div>
        </div>
        <div v-if="shops.length>0">
          <carousel :items="4" :loop="true" :margin="3" :nav="false">
            <div class="vendor p-2 shopCarBox" v-for="(shop,index) in shops" :key="index">
              <div class="vendor-img zoomimg" style="height:250px;">
                <a :href="'/study_materials/'+shop.name+'?id='+shop.product_id"> 
                  <img :src="shop.thumbnail.split(' ')[0]" alt="" class="img-fluid" v-if="shop.thumbnail" style="pointer-events:none;">
                  <img src="../assets/images/book.jpg" alt="" class="img-fluid" v-if="!shop.thumbnail" style="pointer-events:none;">
                </a>
              </div>
              <div class="vendor-content">
                <h2 class="vendor-title" v-if="shop.price.startsWith('$')">{{shop.price}}</h2>
                <h2 class="vendor-title" v-if="!shop.price.startsWith('$')">${{shop.price}}</h2>
                <h3 class="vendor-title">{{shop.name.slice(0,20)}}</h3>
                <!--<p class="vendor-address">Type: {{shop.type}} | Format: {{shop.format}}</p>-->
                <p v-if="!shop.cart"><a href="" @click.prevent="addToCart(shop)" class="btn btn-success btn-sm text-white">Add to Cart</a></p>
                <p v-if="shop.cart">
                  <a href="" class="btn btn-secondary text-white btn-sm">  Added  </a>
                  <a href="/cart" class="btn btn-success btn-sm ml-1 text-white">Go to cart</a>
                </p>
              </div>
            </div>
          </carousel>
        </div>
        <div class="row mb-8">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <a href="/study_materials" class="btn testimonial-btn mt-8">View all</a>
          </div>
        </div>
      </div>
    </div>
    <div class="features">
      <div class="container">
        <div class="row">
          <div class="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
            <div class="text-center" style="margin-bottom:26px;">
              <!-- section title start-->
              <h2 class="feature-title text-white" style="margin-bottom:10px;">Features</h2>
              <p class="text-white">In this age of online learning, you can see fromyourtutor one of the most advanced features in technology for students to understand quickly and accurately.

</p>
            </div>
            <!-- /.section title start-->
          </div>
        </div>
        <div class="row">
            <!-- feature-1 -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 text-center text-white">
                <div class="feature">
                    <div class="feature-icon bg-transparent text-default mr-auto ml-auto ">
                      <img src="../assets/images/f1.png" target="_blank" style="pointer-events:none;">
                    </div>
                    <div class="feature-content mt-8">
                        <h3 class="text-white">International Faculties</h3>
                        <p> We have diversified faculty from all over the world. Our faculties are passionate. We have onboard native speakers for languages like English, Arabic , French , Hindi etc</p>
                    </div>
                </div>
            </div>
            <!-- feature-1 -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 text-center text-white">
                <div class="feature">
                    <div class="feature-icon bg-transparent text-default mr-auto ml-auto ">
                        <img src="../assets/images/f2.png" target="_blank" style="pointer-events:none;">
                    </div>
                    <div class="feature-content mt-8">
                        <h3 class="text-white">Live Interaction</h3>
                        <p> Our classes are highly interactive with one to one or groups. Live classes allow interaction with students through talking to the teachers, writing notes, submitting them.</p>
                    </div>
                </div>
            </div>
            <!-- feature-1 -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 text-center text-white">
                <div class="feature">
                    <div class="feature-icon bg-transparent text-default mr-auto ml-auto ">
                      <img src="../assets/images/f3.png" target="_blank" style="pointer-events:none;">
                    </div>
                    <div class="feature-content mt-8">
                        <h3 class="text-white">Personalized Classes </h3>
                        <p> Personalized student-centred learning is an educational approach that aims to customize learning for each student’s strengths, needs, skills, and interests.</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    
    <div class="testimonials text-center">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <h1 class="mb-4">Testimonials</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"  v-for="(testimonial,index) in testimonials" :key="index">
            <div class="testimonial-box">
              <span><i class="fas fa-quote-left"></i></span>
              <p class="testimonial_string">{{(testimonial.testimonial.length>200)?testimonial.testimonial.slice(0,200).concat('...'):testimonial.testimonial}}</p>
              <p class="testimonial_string_hover">{{testimonial.testimonial}}</p>
              <h5>{{(testimonial.name)?testimonial.name:'Anonymous'}}</h5>
              <div class="testimonial-dp">
                <img src="../assets/images/dummy-testi.png" v-if="!testimonial.thumbnail" style="pointer-events:none;">
                <img :src="testimonial.thumbnail" v-if="testimonial.thumbnail" style="pointer-events:none;">
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <a href="/testimonials" class="btn testimonial-btn">View all</a>
          </div>
        </div>
      </div>
    </div>
    <div class="tutor-section text-center" v-if="!this.$cookies.get('tution-tutor')">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h1 class="mb-4">Want to be a teacher</h1>
                    <p>You are a qualified tutor ? Then you can join with us</p>
                    <p><a href="/tutor_registration" class="btn btn-red">Register Now</a></p>
                    <p>Already Registered? <a href="/tutor_login">Login now</a></p>
                </div>
               
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import carousel from 'vue-owl-carousel'
export default {
  name: 'home',
  components: { carousel },
  data(){
    return{
      testimonials:[],
      shops:[],
      items:[],
      user_id:null,
      sliders:[]
    }
  },
  async beforeMount(){
    window.scrollTo(0, 0);
    this.listTestimonials();
    this.listSliders();
    if(this.$cookies.get('cart-session-key')&&this.$cookies.get('cart-session-token')){
      console.log('');
    }else{
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/cart',{},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.Message == 'A new cart have been created for you!'){
          this.$cookies.set('cart-session-key',res.data.cartKey);
          this.$cookies.set('cart-session-token',res.data.cartToken);
        }
      })
    }

    if(this.$cookies.get('cart-session-key')&&this.$cookies.get('cart-session-token')){
      let cartKey = this.$cookies.get('cart-session-key');
      let cartToken = this.$cookies.get('cart-session-token');
        if(this.$cookies.get('tution-student')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
            if(res.data.message == 'Student retrieved successfully'){
              let student = res.data.data.student;
              this.user_id = student.user.user_id;
            }
          });
        }else if(this.$cookies.get('tution-tutor')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-tutor').token}}).then(res => {
            if(res.data.message == 'User found'){
              let tutor = res.data.data.users;
              this.user_id = tutor.user_id;
            }
          });
        }
        
      await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/getcart',{cartKey:cartKey,cartToken:cartToken,user_id:this.user_id},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message == 'Product listed successfully'){
          res.data.Items.forEach(item => {
            if(item.is_active == 'Y'){
              this.items.push(item)
            }
          });
        }
      });
    }else{
      this.items = [];
    }
    this.listMaterials();
  },
  methods:{
    listTestimonials(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_testimonials',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message === 'Tutors listed successfully'){
          res.data.data.forEach(test => {
            if(test.home_flag === 1 && test.status === 1 && this.testimonials.length < 3){
              this.testimonials.push(test);
            }
          })
        }
      })
    },
    listSliders(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/list_sliders',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message === 'Sliders listed successfully'){
          res.data.data.forEach(test => {
            if(test.home_flag === 1 && test.status === 1 && test.thumbnail){
              this.sliders.push(test);
            }
          })
        }
      })
    },
    listMaterials(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/home-materials',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.products.data.length > 0){
          res.data.products.data.forEach(product =>{
            if(product.is_active == 'Y' && product.uploaded_file){
              let flag = false;
              if(this.items.length > 0){
                this.items.forEach((item,index) => {
                  if(product.product_id == item.product_id){
                    flag = true;
                    this.items.splice(index,1)
                  }
                })
                if(flag == true){
                    this.shops.push({id:product.product_id, name:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:true})
                }else{
                    this.shops.push({id:product.product_id, name:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
                }
              }else{
                this.shops.push({id:product.product_id, name:product.name,price:product.price,type:product.type,format:product.file_format.name,thumbnail:product.thumbnail, cart:false})
              }
            }
          })
        }else{
          this.shops = []
        }
      });
    },
    addToCart(product){
      let cartKey = this.$cookies.get('cart-session-key');
      let cartToken = this.$cookies.get('cart-session-token');
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/addcarts',{productID:product.id,quantity:1,cartKey:cartKey,cartId:cartToken,user:this.user_id,item_type:2},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message == 'Product added to Cart  successfully' || res.data.message == 'The Cart was updated with the given product information successfully'){
          this.$router.push('/cart');
        }
      })
    }
  }
}
</script>

<style scoped>
p.ttl-description{
  color: white;
}
.popular_subject {
  background: white;
}
.popular_subject img{
  width: 50px;
}
.popular_subject .col{
  min-width: 140px;
}
.popular_subject h4{
  font-size: 13px;
}
.hero-section-third{
    background: url(../assets/images/hero-image-3.jpg) no-repeat center bottom;
    background-size: cover;
}

.big-txt{
  font-size: 158px;
    line-height: 104px;
    font-weight: 900;
}
.search-head-title{
  font-size: 80px;
  line-height: 80px;
  font-weight: 700;
  text-transform: uppercase;
}
.section-title > h2{
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
}
.vendor-categories-block {
    margin-bottom: 0px;
    position: relative;
}
.zoomimg {
    overflow: hidden;
}

.zoomimg:hover img{
    -moz-transform: scale(1.1); -webkit-transform: scale(1.1); transform: scale(1.1); 
}
.vendor-categories-img {
    border-radius: 15px;
    overflow: hidden;
    height: 130px;
    text-align: center;
}
.vendor-categories-overlay {
    position: absolute;
    bottom: 0px;
    display: inline-block;
    width: 100%;
    padding: 10px 10px 23px 10px;
    text-align: center;
    text-transform: uppercase;
}
a {
    color: #252328;
    -webkit-font-smoothing: antialiased;
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.mrgn1 { margin-top: 36px; }
.mrgn2 { margin-top: 29px; }
.mrgn3 { margin-top: 29px; }
.mrgn4 { margin-top: 28px; }
.mrgn5 { margin-top: 25px; }
.mrgn6 { margin-top: 25px; }
.mrgn7 { margin-top: 28px; }
.mrgn8 { margin-top: 18px; }
.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
.vendor-categories-overlay h4 {
    font-weight: 700;
}
.vendor-categories-title {
    color: #fff;
}
.g1{
  background: linear-gradient(to bottom right, #FFA35F, #F95A26);
}
.g2{
  background: linear-gradient(to bottom right, #CE2147, #F75628);
}
.g3{
      background: linear-gradient(to bottom right, #FE8767, #E82F63);
}
.g4{
  background: linear-gradient(to bottom right, #D5B84D, #9BB01A);
}
.g5{
  background: linear-gradient(to bottom right, #2B13CF, #8C50F8);
}
.g6{
  background: linear-gradient(to bottom right, #92E3F9, #BA49EF);
}
.g7{
  background: linear-gradient(to bottom right, #C03CE6, #E937AE);
}
.g8{
  background: linear-gradient(to bottom right, #27D5D5, #8AF495);
}
.mrgn1{
  margin-top: 29px;
}
.vendor-categories-text a{
  text-decoration: none;
  background-color: transparent;
  color: #fff;
  }


.features{
  background: #f6a544 url(../assets/images/features.png) repeat top center;
  padding-top: 30px;
  padding-bottom: 50px;
}
.feature-title{
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
}
.feature-icon img{
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
}
p:last-child {
    margin: 0px;
}

.tutor-section{
  background: #fff url(../assets/images/fbg.jpg) no-repeat center top;
  padding-top: 80px;
  padding-bottom: 80px;
}
.tutor-section h1{
  text-transform: uppercase;
  color: black;
  font-weight: 700;
}
.btn-red {
    color: #fff;
    background-color: #ce0d3d;
    border-color: #ce0d3d;
    font-size: 29px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
}
.btn-banner {
    color: #d51439;
    background-color: #fff;
    border-color: #fff;
    font-weight: 700;
    font-size: 27px;
    border-radius: 60px;
}
.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.5;
}
.btn {
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.testimonial-btn{
  
  border: solid 2px #ce0d3d;
  border-radius: 30px;
  margin-top: 100px;
  color: #252328;
  padding:12px 55px 12px 55px;
  font-size: 16px;
  
  font-weight: bold;
}
.testimonial-btn:hover{
  background: #ce0d3d;
  color: #fff;
}
.acadamic-btn{
  padding: 10px;
  background: #ce0d3d;
  color: #fff;
  text-decoration: none;
  border-radius: 20px;
  
  display: block;
  width: 320px;
  margin: 0 auto;
  margin-top: 10px;
  text-transform: uppercase;
}
.testimonials{
  background:#e5e5e5;
  padding-top: 50px;
  padding-bottom: 50px;
}
.testimonials h1{
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 700;
}
.testimonial-box{
  background: white;
  height: 380px;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  transition: all 0.5s;
  margin: 0px 8px 0px 8px;
}
.testimonial-box:hover{
  background: #d51439;
  color: #fff;
  transition: all 0.5s;
}
.testimonial-box .testimonial_string{
  display: block;
}
.testimonial-box:hover .testimonial_string{
  display:none;
}
.testimonial-box .testimonial_string_hover{
  display:none;
}
.testimonial-box:hover .testimonial_string_hover{
  display: block;
  max-height: 240px;
  overflow-y: auto;
}
.testimonial-box:hover .fas{
  display: none;
}
.testimonial-box:hover h5{
  color: #fff;
  transition: all 0.5s;
}
.testimonial-box .fas{
  font-size: 50px;
  color: #cccccc;
  margin-bottom: 15px;
}
.testimonial-dp{
  width: 100px;
  height: 100px;
  border-radius: 90px;
  border: solid 2px #fff;
  margin: 0 auto;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -50px;
}
.testimonial-dp img{
  width: 100%;
}
p.ttl-description{
  color: white;
}
.popular_subject {
  background: white;
}
.hero-section-third{
    background: url(../assets/images/hero-image-3.jpg) no-repeat center bottom;
    background-size: cover;
}
.search-head{
    margin-bottom: 390px;
    padding: 25px 0px 0px 0px;
}
.big-txt{
  font-size: 158px;
    line-height: 104px;
    font-weight: 900;
}
.search-head-title{
  font-size: 80px;
  line-height: 80px;
  font-weight: 700;
  text-transform: uppercase;
}
.section-title > h2{
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
}
.zoomimg {
    overflow: hidden;
}

.zoomimg:hover img{
    -moz-transform: scale(1.1); -webkit-transform: scale(1.1); transform: scale(1.1); 
}

.vendor-categories-overlay {
    position: absolute;
    bottom: 0px;
    display: inline-block;
    width: 100%;
    padding: 10px 10px 23px 10px;
    text-align: center;
    text-transform: uppercase;
}
a {
    color: #252328;
    -webkit-font-smoothing: antialiased;
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.mrgn1 { margin-top: 36px; }
.mrgn2 { margin-top: 29px; }
.mrgn3 { margin-top: 29px; }
.mrgn4 { margin-top: 28px; }
.mrgn5 { margin-top: 25px; }
.mrgn6 { margin-top: 25px; }
.mrgn7 { margin-top: 28px; }
.mrgn8 { margin-top: 18px; }
.img-fluid {
    max-width: 100%;
    height: auto;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
.vendor-categories-overlay h4 {
    font-weight: 700;
}
.vendor-categories-title {
    color: #fff;
}
.g1{
  background: linear-gradient(to bottom right, #FFA35F, #F95A26);
}
.g2{
  background: linear-gradient(to bottom right, #CE2147, #F75628);
}
.g3{
      background: linear-gradient(to bottom right, #FE8767, #E82F63);
}
.g4{
  background: linear-gradient(to bottom right, #D5B84D, #9BB01A);
}
.g5{
  background: linear-gradient(to bottom right, #2B13CF, #8C50F8);
}
.g6{
  background: linear-gradient(to bottom right, #92E3F9, #BA49EF);
}
.g7{
  background: linear-gradient(to bottom right, #C03CE6, #E937AE);
}
.g8{
  background: linear-gradient(to bottom right, #27D5D5, #8AF495);
}
.mrgn1{
  margin-top: 29px;
}
.vendor-categories-text a{
  text-decoration: none;
  background-color: transparent;
  color: #fff;
  }


.features{
  background: #f6a544 url(../assets/images/features.png) repeat top center;
  padding-top: 30px;
  padding-bottom: 50px;
}
.feature-title{
  font-weight: 700;
  text-transform: uppercase;
  font-size: 36px;
}
.feature-icon img{
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
.feature-content h3 {
    font-size: 22px !important;
    font-weight: 500;
}
p:last-child {
    margin: 0px;
}

.tutor-section{
  background: #fff url(../assets/images/fbg.jpg) no-repeat center top;
  padding-top: 80px;
  padding-bottom: 80px;
}
.tutor-section h1{
  text-transform: uppercase;
  color: black;
  font-weight: 700;
}
.btn-red {
    color: #fff;
    background-color: #ce0d3d;
    border-color: #ce0d3d;
    font-size: 29px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
}
.btn-banner {
    color: #d51439;
    background-color: #fff;
    border-color: #fff;
    font-weight: 700;
    font-size: 27px;
    border-radius: 60px;
}
.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.5;
}
.btn {
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.acadamic-year{
  background: #fff;
  padding: 40px 0px 10px 0px;
  text-align: center;
}
.acadamic-info{
  background: linear-gradient(to bottom right, #04997D, #5BD768);
  border-radius: 150px 250px 0px 150px;
  color: #fff;
  max-width: 770px !important;
  padding: 50px 99px 40px 60px;
}
.acadamic-year h3{
  font-weight: bold;
  text-align: center;
  font-size: 28px !important;
  color: #fff;
}
.acadamic-year p{
  font-size: 18px;
}
@media (min-width: 1400px){

  .container {
    max-width: 1140px;
}

}
.btn-banner {
    color: #d51439;
    background-color: #fff;
    border-color: #fff;
    font-weight: 900;
    font-size: 27px;
    border-radius: 60px;
}
@media (max-width: 760px){
    h1.search-head-title{
      font-size: 40px;
      line-height: 15px;
      margin-bottom: 30px;
    }
    h1.search-head-title span.big-txt{
      font-size: 80px;
    }
}
@media (min-width: 1400px){

  .container {
    max-width: 1140px;
}

}
.btn-banner {
    color: #d51439;
    background-color: #fff;
    border-color: #fff;
    font-weight: 900;
    font-size: 27px;
    border-radius: 60px;
}
@media (max-width: 760px){
    h1.search-head-title{
      font-size: 40px;
      line-height: 15px;
      margin-bottom: 30px;
    }
    h1.search-head-title span.big-txt{
      font-size: 80px;
    }
}
</style>