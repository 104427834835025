<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header">
            <div class="row">
              <div class="col-10">
                <h3 class="dashboard-page-title">Hi, {{tutor.name}}</h3>
              </div>
              <div class="col-2">
                <a href="/chat-pool" class="btn btn-default" id="trigger6" style="font-size:12px !important;color:white !important;">Chat Pool</a>
              </div>
            </div>
            <p class="d-block">Here’s your class details.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="card card-summary">
            <div class="card-body">
              <div class="float-left">
                <div class="summary-count">{{upcoming_classes}}</div>
                <p>Upcomig classes</p>
              </div>
              <div class="summary-icon"><i class="icon-051-wedding-arch"></i></div>
            </div>
            <div class="card-footer text-center"><a href="/tutor/upcoming-classes">View All</a></div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="card card-summary">
            <div class="card-body">
              <div class="float-left">
                <div class="summary-count">{{completed_classes}}</div>
                <p>Classes Completed</p>
              </div>
              <div class="summary-icon"><i class="icon-021-love-1"></i></div>
            </div>
            <div class="card-footer text-center"><a href="/tutor/completed-classes">View All</a></div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
          <div class="card card-summary">
            <div class="card-body">
              <div class="float-left">
                <div class="summary-count">{{earnings}}</div>
                <p>Earnings</p>
              </div>
              <div class="summary-icon"><i class="icon-004-chat"></i></div>
            </div>
            <div class="card-footer text-center"><a href="/tutor/earnings">View All</a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "tutor-home",
  data(){
    return {
      tutor:null,
      upcoming_classes:0,
      completed_classes:0,
      earnings:0
    }
  },
  beforeMount(){
    this.tutor = this.$cookies.get("tution-tutor");
    let tutor_token = this.$cookies.get("tution-tutor").token;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor_dashboard',{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
      if(res.data.message == 'Data retrived successfully'){
        this.upcoming_classes = res.data.data.upcoming_class;
        this.completed_classes = res.data.data.completed_class;
        this.earnings = res.data.data.earnings;
      }
    })
  }
}
</script>

<style scoped>
.summary-count {
    font-size: 42px;
    color: #252328;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1;
}
.card-body {
    padding: 20px;
}
.summary-icon {
    font-size: 50px;
    color: #0079df;
    display: inline-block;
    float: right;
    line-height: 1.5;
}
.card-footer {
    background: #fff;
    border-top: 1px solid #e6e5e7;
    font-size: 14px;
    padding: 0.75rem 0.25rem;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328 !important;
    -webkit-font-smoothing: antialiased !important;
}
a:active, a:hover, a{
    outline: 0 !important;
    text-decoration: none !important;
}
</style>