<template>
    <section class="login-section">
      <div class="login-block">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
                <div class="auth-box card">
                  <div class="card-block">
                    <div class="row">
                      <div class="col-md-12">
                        <h5 class="text-center" style="margin-bottom:15px !important;" v-if="!reset"><a href="/"><img src="../../assets/images/logo.png" style="margin-bottom: 5px;pointer-events:none;"></a><br>Forgot Password</h5>
                        <h5 class="text-center" style="margin-bottom:15px !important;" v-if="reset"><a href="/"><img src="../../assets/images/logo.png" style="margin-bottom: 5px;pointer-events:none;"></a><br>Reset Password</h5>
                      </div>
                    </div> 
                    <div class="email" v-if="!reset && !loading">
                      <v-text-field type="text" :rules="emailRules" v-model="user.email" @keydown.space.prevent required><template #label><span style="font-size:18px !important;">Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                    </div>
                    <div class="password" v-if="reset && !expired && !loading">
                      <v-text-field type="password" :rules="passwordRules" v-model="password.new" @keydown.space.prevent required><template #label><span style="font-size:18px !important;">Password<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                    </div>
                    <div class="confirm-password" v-if="reset && !expired && !loading">
                      <v-text-field type="password" :rules="passwordConfirmationRules" v-model="password.confirm" @keydown.space.prevent required><template #label><span style="font-size:18px !important;">Confirm Password<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                    </div>
                    <div class="expired-link" v-if="expired && reset && !loading">
                      <p>This reset link is expired</p>
                    </div>
                    <div class="error-feedback" v-if="auth_error">{{auth_msg}}</div>
                    <div class="row">
                      <div class="col-md-12" v-if="!reset && !loading">
                        <button class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-on:click.prevent="toReset"><b>Submit</b></button>
                      </div>
                      <div class="col-md-12" v-if="reset && !expired && !loading">
                        <button class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-on:click.prevent="confirm"><b>Reset</b></button>
                      </div>
                    </div>
                    <br>
                    <p class="text-inverse text-center">Back To Login <a href="/login" data-abc="true" target="_blank">Login</a></p>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </div>
        <b-modal id="success" ref="success-modal" title=" " hide-footer hide-header centered>
          <div class="container">
            <div class="modal-body text-center">
              <p style="color:green">Password updated successfully</p>
            </div>		
          </div>
        </b-modal>
      </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data(){
      return {
        valid: true,
        user:{
          email: null
        },
        password:{
          new: null,
          confirm: null
        },
        can_reset:0,
        auth_error:false,
        auth_msg:null,
        emailRules:[
          v => !!v || 'Enter valid email',
          v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
        ],
        passwordRules:[
          v => !!v || 'Enter valid password'
        ],
        passwordConfirmationRules:[
          v => !!v || 'Enter valid password',
          v => this.password.new == v || 'Password not match'
        ],
        order_success:null,
        loading: true,
        expired: true,
        reset:false
      }
    },
    beforeMount(){
      this.loading = true;
      if(this.$route.query.key){
        this.reset = true;
        axios.get(process.env.VUE_APP_TUTION_SITE_API+'/reset/'+this.$route.query.key, {headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          this.loading = false;
          if(res.data.status){
            this.expired = false;
            
          }else{
            this.auth_error = true;
            this.auth_msg = 'Link is expired';
            this.expired = true;
          }
        });
      }else{
        this.reset = false;
      }
    },
    methods:{
      async toReset(e){
        e.preventDefault();
        this.can_reset = 0;
        this.auth_error = false;
        this.auth_msg = '';
        this.valid = this.$refs.form.validate();
        if(this.valid == true){
          try{
           await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/reset',this.user,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
                if(res.data.status){
                  this.auth_error = false;
                  var body = {
                                  "eventType": "resetPassword",
                                  "reset": {
                                      "email": res.data.user.email,
                                      "reset_link": res.data.reset_link,
                                  }
                              }
                  axios.post('https://fpurd8z9i3.execute-api.ap-south-1.amazonaws.com/test01/confirm-booking',body).then(res => {
                    console.log(res);
                  })
                }else{
                  this.auth_error = true;
                  this.auth_msg = 'User not found';
                }
            })
          }catch(err){
            console.log(err);
            this.auth_error = true;
            this.auth_msg = 'Incorrect email';
          }
        }
      },

      async confirm(e){
        e.preventDefault();
        this.auth_error = false;
        this.auth_msg = '';
        this.valid = this.$refs.form.validate();
        if(this.valid == true){
          try{
           await axios.put(process.env.VUE_APP_TUTION_SITE_API+'/reset/'+this.$route.query.key,this.password,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
                if(res.data.status){
                  this.auth_error = false;
                  this.$refs['success-modal'].show();
                  setTimeout(() => {
                    this.$refs['success-modal'].hide();
                    this.password.new = null;
                    this.password.confirm = null;
                  },4000)
                }else{
                  this.auth_error = true;
                  this.auth_msg = 'User not found';
                }
            })
          }catch(err){
            console.log(err);
            this.auth_error = true;
            this.auth_msg = 'Please contact admin';
          }
        }
      }
    }
  }
  </script>
  
  <style scoped>
    .login-section{
        background: url(../../assets/images/couple-bg-img.jpg)no-repeat;
        background-size: cover;
        height: 100%;
    }
    .login-block {
        margin: 30px auto;
        min-height: 93.6vh;
    }
    .login-block .auth-box {
        margin: 20px auto 0;
        max-width: 450px !important;
    }
    .card {
        border-radius: 5px;
        -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
        box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
        border: none;
        margin-bottom: 30px;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        background-color: #fff;
    }
    .card .card-block {
        padding: 1.25rem;
    }
    h5 {
        font-size: 16px;
        line-height: 26px;
    }
    .btn-primary:hover {
        color: #fff;
        background-color: #03a28f;
        border-color: #03a28f;
    }
    .btn:hover {
      color: #ffa25f;
    }
    .btn-lg {
        font-size: 14px;
        line-height: 1.3333333;
    }
    img {
        width: auto;
        max-width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        line-height: normal;
        vertical-align: middle;
    }
    a:active, a:hover {
        outline: 0;
        text-decoration: none;
    }
    a:hover {
        color: #ce0d3d;
        text-decoration: none;
    }
    a {
        color: #252328;
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
    }
  </style>