<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-10 col-md-9 col-sm-12 col-12">
          <div class="dashboard-page-header pb-0">
            <h3 class="dashboard-page-title">Downloads</h3>
            <p class="d-block">You can see all files you purchased</p>
          </div>
        </div>
      </div>
      <div class="row mt-0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right mb-6">
          <a href="/study_materials" class="btn btn-default btn-sm text-white">Download Study Materials</a>
        </div>
      </div>
      <div class="dashboard-vendor-list">
        <ul class="list-unstyled pl-0" v-if="downloaded_materials.length">
          <li v-for="(material,index) in downloaded_materials" v-bind:key="index">
            <div class="dashboard-list-block">
              <div class="row">
                <div class="col-xl-2 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-img"><a href=" "><img :src="material.thumbnail" alt="" class="img-fluid" v-if="material.thumbnail" style="pointer-events:none;"><img src="../../assets/images/cart-l.jpg" alt="" class="img-fluid" v-if="!material.thumbnail" style="pointer-events:none;"></a></div>
                </div>
                <div class="col-xl-7 col-lg-5 col-md-6 col-sm-12 col-12">
                  <div class="dashboard-list-content">
                    <h3 class="mb-0"><a href="" class="title">{{material.product.name}} | {{material.product.type}}</a></h3>
                    <!-- <a class="title"><p style="font-size:14px;">Book by {{material.auther}} | Purchased - {{material.purchased}}</p></a> -->
                    <a class="title"><p style="font-size:14px;">Subject by {{material.product.subject.subject_name}} | Format - {{material.product.file_format.name}}</p></a>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                  <div class="dashboard-list-btn">
                    <a v-on:click.prevent="openVideoModal(material.product.uploaded_file,material.product.name)" v-if="material.product.uploaded_file.substr(material.product.uploaded_file.lastIndexOf('.') + 1)=='mp4'" class="btn btn-outline-violate btn-xs mr10">Play</a>
                    <a :href="material.product.uploaded_file" v-else class="btn btn-outline-violate btn-xs mr10">Download</a>                    
                    <!-- <form method="get" :action="material.file"><button type="submit" class="btn btn-outline-violate btn-xs mr10">Download</button></form> -->
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul v-else>
          <div class="empty-data">No Downloads</div>
        </ul>
      </div>
    </div>
    <b-modal id="success" ref="video-modal" size="xl" :title="video_title" hide-footer centered>
      <div class="container text-center">
        <video id="video_modal" width="1000" height="400" type="video/*" controls :src="this.video_id"></video>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'completed-classes',
    data(){
      return{
        downloaded_materials:[],
        video_id:null,
        video_title:null,
      }
    },
    beforeMount(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/paid_items', {headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-student').token)}}).then(res => {
        if(res.data.meaasge == 'User paid items listed successfully'){
          res.data.data.forEach((item => {
            if(item.is_active == 'Y'){
              this.downloaded_materials.push(item)
            }
          }))
        }
      });
    },
    methods:{
      openVideoModal(videoID,title){
        console.log(videoID);
        this.video_id = videoID;
        this.video_title = title;
        this.$refs['video-modal'].show();
      }
    }
}
</script>

<style scoped>
.dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    border-radius: 3px;
}
.dashboard-list-content {
    font-size: 14px;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
}
.dashboard-list-btn {
    padding-right: 29px;
    padding-top: 20px;
    text-align: right;
}
.dashboard-list-img img {
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.title {
    color: #252328;
    text-decoration: none;
}
.title:hover {
    outline: 0;
    text-decoration: none;
}
.title:hover {
    color: #ce0d3d;
    text-decoration: none;
}
.btn-xs {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.5;
}
</style>