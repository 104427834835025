<template>
  <section class="login-section">
    <div class="login-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
              <div class="auth-box card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="text-center" style="margin-bottom:15px !important;"><a href="/"><img src="../../assets/images/logo.png" style="margin-bottom: 5px;pointer-events:none;"></a><br>Student Login</h5>
                    </div>
                  </div> 
                  <div class="email">
                    <v-text-field type="text" :rules="emailRules" v-model="login.email" @keydown.space.prevent required><template #label><span style="font-size:18px !important;">Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="password">
                    <v-text-field type="password" v-model="login.password" :rules="[v => !!v || 'Enter valid password']" required><template #label><span style="font-size:18px !important;">Password<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="error-feedback" v-if="auth_error">{{auth_msg}}</div>
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-on:click.prevent="toLogin"><b>Login</b></button>
                    </div>
                  </div>
                  <!-- <div class="or-container">
                    <div class="line-separator"></div>
                    <div class="or-label">or</div>
                    <div class="line-separator"></div>
                  </div> -->
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <a class="btn btn-lg btn-social btn-facebook" style="padding: 14px 50px;">
                        <i class="fab fa-facebook-f fa-fw"></i><span style="margin-left:25px;">Sign in with Facebook</span>
                      </a>
                    </div>
                    <div class="col-md-12 pt-0"> <a class="btn btn-lg btn-google btn-block text-uppercase btn-outline w-100" href="#" style="padding: 14px 25px;"><img src="https://img.icons8.com/color/16/000000/google-logo.png"> Sign in Using Google</a> </div>
                  </div>  -->
                  <br>
                  <p class="text-inverse text-center">Not yet an account <a href="/register" data-abc="true" target="_blank">Signup Now</a></p>
                  <p class="text-inverse text-center" style="font-size: 12px;">Forgot Password  <a href="/reset-password" data-abc="true" target="_blank">Click Here</a></p>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
export default {
  data(){
    return {
      valid: true,
      login:{
        email: null,
        password: null
      },
      can_login:0,
      auth_error:false,
      auth_msg:null,
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
    }
  },
  methods:{
    async toLogin(e){
      e.preventDefault();
      // console.log(this.$route);
      // console.log(this.$router.history._startLocation.contain('/tutors/tutor-details?'));
      this.can_login = 0;
      this.auth_error = false;
      this.auth_msg = '';
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        try{
         await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/st_login',this.login,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
            if(res.status == 200){
              let student = {token:res.data.data.access_token,name:res.data.name}
              this.$cookies.set("tution-student", student);
              if(this.$cookies.get('previousURL') != null){
                this.$cookies.remove('previousURL');
                this.$router.push('/cart');
                // location.reload();
              }else{
                if(this.$route.params && this.$route.params.return == true){
                  this.$router.push(this.$router.history._startLocation)
                }else{
                  this.$router.push('/student/dashboard');
                }
                // location.reload();
              }
            }else{
              this.auth_error = true;
              this.auth_msg = 'Incorrect email or password';
            }
          })
        }catch(err){
          this.auth_error = true;
          this.auth_msg = 'Incorrect email or password';
        }
      }
    }
  }
}
</script>

<style scoped>
.login-section{
    background: url(../../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
}
.login-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.login-block .auth-box {
    margin: 20px auto 0;
    max-width: 450px !important;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.card .card-block {
    padding: 1.25rem;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn:hover {
  color: #ffa25f;
}
.btn-lg {
    font-size: 14px;
    line-height: 1.3333333;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}
</style>