<template>
  <div class="dashboard-content">
    <div class="container pt-7">
      <div class="card">
        <div class="card-header" v-if="tutor">Working Time <small v-if="tutor.accent">/{{tutor.accent.name}}</small></div>
          <div class="card-body">
            <div class="personal-form-info">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" v-if="tutor_timing">
                  <p>{{getDay(tutor_timing.start_day)}} to {{getDay(tutor_timing.end_day)}}</p>
                  <p>Working time: {{tutor_timing.start_time}} - {{tutor_timing.end_time}}</p>
                  <p>Break time: {{tutor_timing.break_start_time}} - {{tutor_timing.break_end_time}}</p>
                  <p>Month: {{getMonth(tutor_timing.start_month)}} - {{getMonth(tutor_timing.end_month)}}</p>
                </div>
                <div v-else class="empty-data">Not Set Time</div>
              </div>
            </div>
            <br>
            <p><small><span class="mr-2"><span class="badge-dot badge-danger"></span> If you need any changes on your timing please contact admin</span></small></p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name:'student-chatroom',
  data(){
    return {
      tutor:null,
      tutor_timing:null,
      days:[
        {value:1,text:'Sunday'},
        {value:2,text:'Monday'},
        {value:3,text:'Tuesday'},
        {value:4,text:'Wedensday'},
        {value:5,text:'Thursday'},
        {value:6,text:'Friday'},
        {value:7,text:'Saturday'},
      ],
      months:[
        {value:1,text:'January'},
        {value:2,text:'February'},
        {value:3,text:'March'},
        {value:4,text:'April'},
        {value:5,text:'May'},
        {value:6,text:'June'},
        {value:7,text:'July'},
        {value:8,text:'August'},
        {value:9,text:'September'},
        {value:10,text:'October'},
        {value:11,text:'November'},
        {value:12,text:'December'},
      ],
    }
  },
  beforeMount(){
    let tutor_token = this.$cookies.get("tution-tutor").token;
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
      this.tutor = res.data.data;
      this.tutor_timing = res.data.data.timing
    })
  },
  methods:{
    getDay(id){
      for(var i = 0; i<this.days.length; i++){
        if(this.days[i].value == id){
          return this.days[i].text;
        }
      }
    },
    getMonth(id){
      for(var i = 0; i<this.months.length; i++){
        if(this.months[i].value == id){
          return this.months[i].text;
        }
      }
    }
  }
}
</script>
<style scoped>
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
</style>