<template>
  <div class="study_materials">
    <div id="slide-reveal-overlay" v-on:click="closeSlideBar()" style="display: none; position: fixed; top: 0px; left: 0px; height: 100%; width: 100%; z-index: 1048; background-color: rgba(0, 0, 0, 0.5);"></div>
    <div class="dashboard-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-12">
            <div class="dashboard-page-header mb-0 pb-3">
              <h3 class="dashboard-page-title">Shop</h3>
              <p class="d-block">Add, Edit or Delete Study Materials</p>
            </div>
          </div>
        </div>
        <div class="row">   
          <div id="slide-panel6" class="slide-panel-light slide-panel">
            <h3 v-if="record.method=='ADD'">Add Products</h3>
            <h3 v-if="record.method=='EDIT'">Edit Product</h3>
            <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <v-text-field id="inputText3" type="text" v-model="product.name" :rules="[v => !!v || 'Enter name']" placeholder="Name*" outlined :error-messages="err_msg"></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <v-text-field id="inputText3" type="text" v-model="product.price" :rules="[v => !!v || 'Enter price']" placeholder="Price in $*" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <v-text-field type="text" v-model="product.type" :rules="[v => !!v || 'Enter type']" placeholder="Type*" outlined></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="">
                    <v-autocomplete :items="file_formats" v-model="product.file_format_id" :rules="[v => !!v || 'Select file format']" item-value="value" item-text="text" placeholder="File Format*" outlined></v-autocomplete>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="">
                    <v-autocomplete :items="subjects" v-model="product.subject_id" item-value="value" :rules="[v => !!v || 'Select subject']" item-text="text" placeholder="Subject*" outlined></v-autocomplete>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="inputText3" class="col-form-label">Description<span> </span></label>
                    <v-textarea v-model="product.details" outlined></v-textarea>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="inputText3" class="col-form-label">Thumbnail<span> </span></label>
                    <v-file-input accept="image/*" v-model="product.uploaded_thumbnail" @change="addMultipleFiles"  prepend-icon="mdi-camera" multiple small-chips truncate-length="10" counter outlined>
                      <template v-slot:selection="{ index, text }">
                        <v-chip close @click:close="deleteChip(index, text)">{{ text }}</v-chip>
                      </template>
                    </v-file-input>
                    <div v-if="product.thumbnail" class="row">
                        <div v-for="(thumb,index) in product.thumbnail" :key="index" class="col-4 ml-1" style="border:1px solid #ddd">
                          <img :src="thumb" :alt="'thumb'+index" height="50" style="pointer-events:none;">
                          <a v-on:click.prevent="deleteThumb(index)" :id="index" class="btn btn-outline-pink btn-xs" style="padding: 0 !important;position: relative;top: 10px;">DELETE</a>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="inputText3" class="col-form-label">Demo<span> </span></label>
                    <v-file-input accept="file" v-model="product.demo"  prepend-icon="mdi-paperclip" outlined></v-file-input>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="form-group">
                    <label for="inputText3" class="col-form-label">Upload File</label>
                    <v-file-input type="file" v-model="product.file" prepend-icon="mdi-paperclip" outlined></v-file-input>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                  <div class="form-group">
                    <a v-on:click="addProduct" class="btn btn-success" v-if="!loading && record.method=='ADD'">Save</a>
                    <a v-on:click="getConfirmation" class="btn btn-success" v-if="!loading && record.method=='EDIT'">Save</a>
                    <a class="btn btn-success" v-if="loading"><i class="fa fa-spinner fa-spin"></i></a>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-12 setbtn">
            <a v-on:click="getAddProductBar()" class="btn btn-default" id="trigger6" style="font-size:12px !important;">ADD PRODUCTS</a>
          </div>
        </div> <br>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card request-list-table table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Sn.</th>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Price</th>
                    <th>Type</th>
                    <th>Format</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="products.length">
                  <tr v-for="(product,index) in products" :key="index">
                    <td>{{index+1}}</td>
                    <td>
                      <img :src="product.thumbnail.split(' ')[0]" style="width: 80px;pointer-events:none;">
                    </td>
                    <td>{{product.name}}</td>
                    <td v-if="product.price.startsWith('$')">{{product.price}}</td>
                    <td v-if="!product.price.startsWith('$')">${{product.price}}</td>
                    <td>{{product.type}}</td>
                    <td>{{product.file_format.name}}</td>
                    <td>
                      <a v-on:click.prevent="editProduct(product,index)" class="btn btn-outline-violate btn-xs mr-1">EDIT</a>
                      <a class="btn btn-outline-violate btn-xs mr-1" v-on:click.prevent="openShareModal(product.product_id,product.name,index)" style="width:86px;">SHARE</a>
                      <a v-on:click.prevent="confirmDelete(product.product_id,index)" class="btn btn-outline-pink btn-xs">DELETE</a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="empty-data">
                    <td></td>
                    <td></td>
                    <td>No materials listed</td>
                  </tr>
                </tbody>
              </table>
              <div class="col pt-0" v-if="products.length">
                <v-pagination v-model="page" :length="length" class="float-left" @input="handlePageChange"></v-pagination>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p v-if="this.record.method == 'DELETE'">Do you really want to delete these records? This process cannot be undone.</p>
          <p v-if="this.record.method == 'EDIT'">Do you really want to update these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('confirm-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" v-if="this.record.method == 'DELETE'" v-on:click="deleteProduct">Delete</button>
            <button type="button" class="btn btn-danger" v-if="this.record.method == 'EDIT'" v-on:click="confirmShopEdit">Update</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="share" ref="share-modal" title="Share Course" hide-footer hide-header centered>
      <div class="container">
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Share Product</h4>	
        <div class="modal-body text-center" v-if="share.id">
          <p v-if="share.name"><strong>Product:</strong>{{share.product_name}}</p>
          <p>Please enter the email address do you want to share</p>
          <v-form ref="shareForm" lazy-validation v-model="valid">
            <v-text-field type="email" :rules="emailRules" v-model="share.email" @keydown.space.prevent required outlined><template #label><span style="font-size:18px !important;">Email<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
            <v-text-field type="text" :rules="nameRules" v-model="share.name" required outlined><template #label><span style="font-size:18px !important;">Name</span></template></v-text-field>
            <v-text-field type="text" :rules="numberRules" v-model="share.amount" @keydown.space.prevent required outlined><template #label><span style="font-size:18px !important;">Amount</span></template></v-text-field>
          </v-form>
        </div>	
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('share-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="shareProduct">Share</button>
            <button type="button" class="btn btn-danger" v-on:click="copyProduct">Copy Link</button>
            <p v-if="order_success==true">Email sent.</p><p v-else-if="order_success==false">An error occurred</p>
            <p v-if="copied==true" class="text-success">Link copied into clipboard</p><p v-else-if="copied==false" class="text-danger">An error occured</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import AWS from "aws-sdk";
import axios from 'axios';
import copy from 'copy-to-clipboard';
export default {
  name: "admin-shop",
  data(){
    return {
      products:[],
      subjects:[],
      file_formats:[],
      files:[], 
      product:{
        name:null,
        subject_id:null,
        type:null,
        price:null,
        file_format_id:null,
        thumbnail:null,
        uploaded_file:null,
        file:null,
        details:null,
        uploaded_thumbnail:[],
        demo:null,
        uploaded_demo:null
      },
      update_product:{
        name:null,
        subject_id:null,
        type:null,
        price:null,
        file_format_id:null,
        thumbnail:null,
        uploaded_file:null,
        demo:null,
        uploaded_demo:null,
        details:null,
        uploaded_thumbnail:[],
      },
      record:{
        id:null,
        index:null,
        method:null,
        type:null
      },
      valid:true,
      loading:false,
      err_msg:'',
      page:1,
      length:0,
      offset: 0,
      limit: 25,
      total: 0,
      share:{
        id:null,
        email:null,
        index:null,
        name:null,
        amount:0
      },
      order_success:null,
      activate:null,
      message:null,
      shareValid:true,
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      nameRules:[
        v => !!v || 'Enter valid name',
      ],
      numberRules:[
        v => !!v || 'Enter valid amount',
        v => /\D/g.test(v) == false || 'Enter number only',
      ],
      copied:null
    }
  },
  beforeMount(){
    this.getSubjects();
    this.getFileFormat();
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/materials?page='+this.page+'&per_page='+this.limit,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
      if(res.data.products.data.length>0){
        res.data.products.data.forEach(product => {
          this.products.push(product);
        });
        this.limit = res.data.products.per_page;
        this.offset = res.data.products.from-1;
        this.total = res.data.products.total;
        this.length = Math.ceil(res.data.products.total/res.data.products.per_page);
      }
    })
  },
  methods:{
    openSlider(){
      document.getElementById('slide-panel6').style.right = '0px';
      document.getElementById('slide-panel6').style.width = '275px';
      document.getElementById('slide-reveal-overlay').style.display = 'block';
    },
    closeSlideBar(){
      document.getElementById('slide-panel6').style.right = '-275px';
      document.getElementById('slide-panel6').style.width = '0px';
      document.getElementById('slide-reveal-overlay').style.display = 'none';
    },
    hideModal(type){
      this.$refs[type].hide();
    },
    deleteChip(index){
      this.product.uploaded_thumbnail.splice(index, 1)
    },
    deleteThumb(index){
      this.product.thumbnail.splice(index, 1);
    },
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.status == 200){
          res.data.Subjects.forEach(sub => {
            if(sub.is_active == 'Y'){
              this.subjects.push({value:sub.subject_id,text:sub.subject_name});
            }
          })
        }
      })
    },
    getFileFormat(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/fileformats',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        res.data.fileFormats.forEach(file => {
          if(file.is_active == 'Y'){
            this.file_formats.push({value:file.file_format_id,text:file.name});
          }
        })
      })
    },
    editProduct(product,index){
      this.record.id = product.product_id;
      this.record.index = index;
      this.record.type = 'shop';
      this.record.method = 'EDIT';
      this.product.name = product.name,
      this.product.subject_id = {value:product.subject_id,text:product.subject.subject_name},
      this.product.file_format_id = {value:product.file_format.file_format_id,text:product.file_format.name},
      this.product.type = product.type,
      this.product.price = product.price,
      this.product.details = product.details,
      this.product.thumbnail = product.thumbnail.split(' '),
      this.product.uploaded_file = product.uploaded_file,
      this.openSlider();
    },
    getAddProductBar(){
      this.record.method = 'ADD'
      this.record.type = 'shop';
      this.openSlider();
    },
    searchURL(){
      this.urls = [];
      this.products=[];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/materials?page='+this.page+'&per_page='+this.limit).then(res => {
        if(res.data.products.data.length>0){
          res.data.products.data.forEach(product => {
            this.products.push(product);
          });
          this.limit = res.data.products.per_page;
          this.offset = res.data.products.from-1;
          this.total = res.data.products.total;
          this.length = Math.ceil(res.data.products.total/res.data.products.per_page);
        }else{
          this.limit = res.limit;
          this.offset = res.offset;
          this.total = res.total;
          this.urls=[];
        }
      });
    },
    handlePageChange(value) {
        this.page = value;
        this.offset = this.limit * (this.page - 1);
        this.searchURL()
    },
    confirmShopEdit(){
      this.update_product.name = this.product.name;
      this.update_product.subject_id = (typeof(this.product.subject_id)== 'object') ? this.product.subject_id.value:this.product.subject_id;
      this.update_product.file_format_id = (typeof(this.product.file_format_id )== 'object' )? this.product.file_format_id.value:this.product.file_format_id;
      this.update_product.type = this.product.type;
      this.update_product.price = this.product.price;
      this.update_product.thumbnail = this.product.thumbnail;
      this.update_product.details = this.product.details;
      this.update_product.uploaded_file = this.product.file==null?this.product.uploaded_file:null;
      if(this.product.uploaded_thumbnail.length==0 && this.product.file == null && this.product.demo == null){
        this.updateProduct();
      }else if(this.product.uploaded_thumbnail.length || this.product.file != null || this.product.demo != null){
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });

        let s3BucketName = "tution-site"; 
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
        var timestamp = Math.floor(Date.now() / 1000);
        if(this.product.demo){
          var demo = new Blob([this.product.demo], { type: "application/pdf" });
          var demo_extension = this.product.demo.name.split('.')[this.product.demo.name.split('.').length-1];
          var demo_name = this.product.demo.name.split('.')[0]
          // if (file.size < 100000000) {
          s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'materials/demo/'+ current_date+"/"+this.product.name +demo_name+ timestamp + "."+demo_extension,
              ACL: "public-read",
            },
          });
          upload = s3obj.upload({ Body: demo });
          upload.on("httpUploadProgress", function (evt) {
            // uploadStatus == 0;
            //checkConnetionOffnOn(upload);
            var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
            console.log(per_uploaded);
          })
          .send((err, data) => {
            this.update_product.uploaded_demo = data.Location;
            if(!this.product.uploaded_thumbnail.length && this.product.file != null){
              var file = new Blob([this.product.file], { type: "application/pdf" });
              var extension = this.product.file.name.split('.')[this.product.file.name.split('.').length-1]
              // if (file.size < 100000000) {
                var s3obj = new AWS.S3({
                  params: {
                    Bucket: s3BucketName,
                    Key:
                      'materials/files/'+ current_date+"/"+this.product.name + timestamp + "."+extension,
                    ACL: "public-read",
                  },
                });
                var upload = s3obj.upload({ Body: file });
                upload.on("httpUploadProgress", function (evt) {
                  // uploadStatus == 0;
                  //checkConnetionOffnOn(upload);
                  var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                  console.log(per_uploaded);
                })
                .send((err, data) => {
                  this.update_product.uploaded_file = data.Location;
                  this.updateProduct();
                })
              // }
            }else if(this.product.uploaded_thumbnail.length && this.product.file == null){
              this.product.uploaded_thumbnail.forEach((u_thumbnail)=>{
                var thumbnail = new Blob([u_thumbnail], { type: "application/image" });
                var thumbnail_extension = u_thumbnail.name.split('.')[u_thumbnail.name.split('.').length-1];
                var s3obj = new AWS.S3({
                  params: {
                    Bucket: s3BucketName,
                    Key:
                    'materials/thumb/'+ current_date+"/"+this.product.name+u_thumbnail.name + timestamp + "."+thumbnail_extension,
                    ACL: "public-read",
                  },
                });
                var upload1 = s3obj.upload({ Body: thumbnail });
                upload1.on("httpUploadProgress", function (evt) {
                    var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                    console.log(per_uploaded);
                }).send((err, data) => {
                  this.update_product.thumbnail.push(data.Location);
                  this.update_product.uploaded_thumbnail.push(data.Location);
                  if(this.update_product.uploaded_thumbnail.length == this.product.uploaded_thumbnail.length){
                  this.updateProduct();
                  }
                });
              });
            }else if(this.product.uploaded_thumbnail.length && this.product.file != null){
              file = new Blob([this.product.file], { type: "application/pdf" });
              extension = this.product.file.name.split('.')[this.product.file.name.split('.').length-1]
              // if (file.size < 20000000) {
                s3obj = new AWS.S3({
                params: {
                  Bucket: s3BucketName,
                  Key:
                    'materials/files/'+ current_date+"/"+this.product.name + timestamp + "."+extension,
                  ACL: "public-read",
                },
              });
              upload = s3obj.upload({ Body: file });
              upload.on("httpUploadProgress", function (evt) {
                  // uploadStatus == 0;
                  //checkConnetionOffnOn(upload);
                  var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                  console.log(per_uploaded);
                })
                .send((err, data) => {
                  this.update_product.uploaded_file = data.Location;
                  // if(thumbnail.size < 20000000) {
                    this.update_product.uploaded_thumbnail.forEach((u_thumbnail)=>{
                      var thumbnail = new Blob([u_thumbnail], { type: "application/image" });
                      var thumbnail_extension = u_thumbnail.name.split('.')[u_thumbnail.name.split('.').length-1];
                      var s3obj = new AWS.S3({
                        params: {
                          Bucket: s3BucketName,
                          Key:
                          'materials/thumb/'+ current_date+"/"+this.product.name+u_thumbnail.name + timestamp + "."+thumbnail_extension,
                          ACL: "public-read",
                        },
                      });
                      var upload = s3obj.upload({ Body: thumbnail });
                      upload.on("httpUploadProgress", function (evt) {
                          var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                          console.log(per_uploaded);
                      }).send((err, data) => {
                        this.update_product.thumbnail.push(data.Location);
                        this.update_product.uploaded_thumbnail.push(data.Location);
                        if(this.product.uploaded_thumbnail.length == this.update_product.uploaded_thumbnail.length){
                        this.updateProduct();
                        }
                      });
                    });
                });
            }else{
              this.updateProduct();
            }
          })
        }else{
          if(!this.product.uploaded_thumbnail.length && this.product.file != null){
          var file = new Blob([this.product.file], { type: "application/pdf" });
          var extension = this.product.file.name.split('.')[this.product.file.name.split('.').length-1]
          // if (file.size < 100000000) {
            var s3obj = new AWS.S3({
              params: {
                Bucket: s3BucketName,
                Key:
                  'materials/files/'+ current_date+"/"+this.product.name + timestamp + "."+extension,
                ACL: "public-read",
              },
            });
            var upload = s3obj.upload({ Body: file });
            upload.on("httpUploadProgress", function (evt) {
              // uploadStatus == 0;
              //checkConnetionOffnOn(upload);
              var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
              console.log(per_uploaded);
            })
            .send((err, data) => {
              this.update_product.uploaded_file = data.Location;
              this.updateProduct();
            })
          // }
        }else if(this.product.uploaded_thumbnail.length && this.product.file == null){
          this.product.uploaded_thumbnail.forEach((u_thumbnail)=>{
            var thumbnail = new Blob([u_thumbnail], { type: "application/image" });
            var thumbnail_extension = u_thumbnail.name.split('.')[u_thumbnail.name.split('.').length-1];
            var s3obj = new AWS.S3({
              params: {
                Bucket: s3BucketName,
                Key:
                'materials/thumb/'+ current_date+"/"+this.product.name+u_thumbnail.name + timestamp + "."+thumbnail_extension,
                ACL: "public-read",
              },
            });
            var upload1 = s3obj.upload({ Body: thumbnail });
            upload1.on("httpUploadProgress", function (evt) {
                var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                console.log(per_uploaded);
            }).send((err, data) => {
              this.update_product.thumbnail.push(data.Location);
              this.update_product.uploaded_thumbnail.push(data.Location);
              if(this.update_product.uploaded_thumbnail.length == this.product.uploaded_thumbnail.length){
               this.updateProduct();
              }
            });
          });
        }else{
          file = new Blob([this.product.file], { type: "application/pdf" });
          extension = this.product.file.name.split('.')[this.product.file.name.split('.').length-1]
          // if (file.size < 20000000) {
            s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'materials/files/'+ current_date+"/"+this.product.name + timestamp + "."+extension,
              ACL: "public-read",
            },
          });
          upload = s3obj.upload({ Body: file });
          upload.on("httpUploadProgress", function (evt) {
              // uploadStatus == 0;
              //checkConnetionOffnOn(upload);
              var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
              console.log(per_uploaded);
            })
            .send((err, data) => {
              this.update_product.uploaded_file = data.Location;
              // if(thumbnail.size < 20000000) {
                this.update_product.uploaded_thumbnail.forEach((u_thumbnail)=>{
                  var thumbnail = new Blob([u_thumbnail], { type: "application/image" });
                  var thumbnail_extension = u_thumbnail.name.split('.')[u_thumbnail.name.split('.').length-1];
                  var s3obj = new AWS.S3({
                    params: {
                      Bucket: s3BucketName,
                      Key:
                      'materials/thumb/'+ current_date+"/"+this.product.name+u_thumbnail.name + timestamp + "."+thumbnail_extension,
                      ACL: "public-read",
                    },
                  });
                  var upload = s3obj.upload({ Body: thumbnail });
                  upload.on("httpUploadProgress", function (evt) {
                      var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                      console.log(per_uploaded);
                  }).send((err, data) => {
                    this.update_product.thumbnail.push(data.Location);
                    this.update_product.uploaded_thumbnail.push(data.Location);
                    if(this.product.uploaded_thumbnail.length == this.update_product.uploaded_thumbnail.length){
                    this.updateProduct();
                    }
                  });
                });
            });
        }
        }
      }
    },
    updateProduct(){
      axios.put(process.env.VUE_APP_TUTION_SITE_API+'/material/'+this.record.id,this.update_product,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.meaasge == 'Product Updated successfully'){
          this.products[this.record.index] = res.data.data;
          this.$refs['confirm-modal'].hide();
          // location.reload();
        }
      })
    },
    deleteProduct(){
      axios.delete(process.env.VUE_APP_TUTION_SITE_API+'/material/'+this.record.id,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.meaasge == 'Product Deleted successfully'){
          this.$refs['confirm-modal'].hide();
          this.products.splice(this.record.index,1);
        }
      })
    },
    addMultipleFiles(){
      this.product.uploaded_thumbnail.forEach(product => {
        if(!this.files.includes(product)){
          this.files.push(product);
        }
      });
      this.product.uploaded_thumbnail=[];
      this.product.uploaded_thumbnail = this.files;
    },
    addProduct(e){
      e.preventDefault();
      this.err_msg = '';
      this.product.thumbnail = [];
      this.valid = this.$refs.form.validate();
      if(this.valid == true){
        this.loading = true;
        var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
        var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
        let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
      
        AWS.config.update({
          accessKeyId: keys.accessKeyId,
          secretAccessKey: keys.secretAccessKey,
          region: 'ap-south-1'
        });

        let s3BucketName = "tution-site"; 
        var d = new Date();
        var month = d.getMonth() + 1;
        var day = d.getDate();
        var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
        var timestamp = Math.floor(Date.now() / 1000);

        var file = new Blob([this.product.file], { type: "application/pdf" });
        var extension = this.product.file.name.split('.')[this.product.file.name.split('.').length-1];
        var s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'materials/files/'+ current_date+"/"+this.product.name + timestamp + "."+extension,
              ACL: "public-read",
            },
          });
        var upload = s3obj.upload({ Body: file });
        upload.on("httpUploadProgress", function (evt) {
          // uploadStatus == 0;
          //checkConnetionOffnOn(upload);
          var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
          console.log(per_uploaded);
        })
        .send((err, data) => {
          this.product.uploaded_file = data.Location;
          if(this.product.demo){
            var demo = new Blob([this.product.demo], { type: "application/pdf" });
            var demo_extension = this.product.demo.name.split('.')[this.product.demo.name.split('.').length-1];
            var demo_name = this.product.demo.name.split('.')[0]
            var s3obj = new AWS.S3({
                params: {
                  Bucket: s3BucketName,
                  Key:
                    'materials/demo/'+ current_date+"/"+demo_name+timestamp + "."+demo_extension,
                  ACL: "public-read",
                },
              });
            var upload = s3obj.upload({ Body: demo });
            upload.on("httpUploadProgress", function (evt) {
              // uploadStatus == 0;
              //checkConnetionOffnOn(upload);
              var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
              console.log(per_uploaded);
            })
            .send((err, data) => {
              if(err){
                console.log(err);
              }else{
                this.product.uploaded_demo = data.Location;
              }
            });
          }
          if(this.product.uploaded_thumbnail.length){
            this.product.uploaded_thumbnail.forEach((u_thumbnail)=>{
              var thumbnail = new Blob([u_thumbnail], { type: "application/image" });
              var thumbnail_extension = u_thumbnail.name.split('.')[u_thumbnail.name.split('.').length-1];
              var s3obj = new AWS.S3({
                params: {
                  Bucket: s3BucketName,
                  Key:
                  'materials/thumb/'+ current_date+"/"+this.product.name +u_thumbnail.name+ timestamp + "."+thumbnail_extension,
                  ACL: "public-read",
                },
              });
              var upload1 = s3obj.upload({ Body: thumbnail });
              upload1.on("httpUploadProgress", function (evt) {
                  var per_uploaded = Math.floor((evt.loaded / evt.total) * 100);
                  console.log(per_uploaded);
              }).send((err, data) => {
                this.product.thumbnail.push(data.Location);
                if(this.product.thumbnail.length == this.product.uploaded_thumbnail.length){
                  this.submitProduct();
                }
              });
            });
          }else{
            this.submitProduct();
          }
        });
      }
    },
    getConfirmation(){
      this.closeSlideBar();
      this.$refs['confirm-modal'].show();
    },
    confirmDelete(id,index){
      this.record.id = id;
      this.record.index = index;
      this.record.type = 'shop';
      this.record.method = 'DELETE';
      this.$refs['confirm-modal'].show();
    },
    submitProduct(){
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/material',this.product,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.message == "Product Created successfully"){
          this.products.unshift(res.data.data);
          this.loading = false;
          this.product.name = null;
          this.product.subject_id = null;
          this.product.type = null;
          this.product.price = null;
          this.product.file_format_id = null;
          this.product.thumbnail = null;
          this.product.uploaded_file = null;
          this.product.file = null;
          this.product.uploaded_thumbnail = null;
          this.closeSlideBar();
        }
      })
    },
    openShareModal(id,name,index){
      this.share.id = id;
      this.share.index = index;
      this.share.course_name = name;
      this.$refs['share-modal'].show();
    },
    copyProduct(){
      this.shareValid = this.$refs.shareForm.validate();
      if(this.shareValid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/share/product',this.share,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.data.status == true){
            var ua = navigator.userAgent.toLowerCase(); 
            if (ua.indexOf('safari') != -1) { 
              if (ua.indexOf('chrome') > -1) {
                navigator.clipboard.writeText('https://fromyourtutor.com/order?key='+res.data.transactionID);
                this.copied = true;
              } else {
                copy('https://fromyourtutor.com/order?key='+res.data.transactionID);
                this.copied = true;
              }
            }
            setTimeout(() => {
              this.$refs['share-modal'].hide();
            },5000);
          }else{
            this.copied = false;
          }
        })
      }
    },
    shareProduct(){
      this.shareValid = this.$refs.shareForm.validate();
      if(this.shareValid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/share/product',this.share,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
          if(res.data.status == true){
            var access_key = process.env.VUE_APP_SES_ACCESS_KEY;
            var secret_key = process.env.VUE_APP_SES_SECRET_KEY;
            let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
            AWS.config.update({
              accessKeyId: keys.accessKeyId,
              secretAccessKey: keys.secretAccessKey,
              region: 'ap-south-1'
            })
            var self = this;
            var params = {
              Destination: { /* required */
                // CcAddresses: [
                //   self.student.user.email,
                //   /* more items */
                // ],
                ToAddresses: [
                  this.share.email,
                  'admin@fromyourtutor.com'
                  /* more items */
                ]
              },
              Message: { /* required */
                Body: { /* required */
                  Html: {
                    Charset: "UTF-8",
                    Data: '<table style="width: 600px; margin: 0 auto;" width="600"><tr><td style="text-align: center;"><br><img src="https://tution-site.s3.ap-south-1.amazonaws.com/test/logo.png" alt="logo"><br></td></tr><tr><td><br><table cellpadding="10" style="background:#f5f5f5; border-radius: 15px; width:560px; margin:0 auto; "><tr><td style="font-family: arial; font-size:18px; text-align: center; color: #086e00;"><br>	Your order has been successfully placed.</td></tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;"><strong>Course:'+self.share.product_name+'</strong><br></td></tr><tr><td style="font-family: arial; font-size:16px; text-align: center; color: #000; line-height: 28px;"><a href="https://fromyourtutor.com/order?key='+res.data.transactionID+'" target="_blank" style="color: #fff; padding: 15px; font-size:16px; background:#ce004b; border-radius:5px; text-decoration: none;"><strong>Dowload</strong></a><br><br></td></tr></table></td></tr></table>'
                  },
                  // Text: {
                  //   Charset: "UTF-8",
                  //   Data: 'Your order has been successfully placed.',
                  // }
                },
                Subject: {
                  Charset: 'UTF-8',
                  Data: 'Order placed'
                }
                },
              Source: 'info@fromyourtutor.com', /* required */
              ReplyToAddresses: [
                'info@fromyourtutor.com',
                /* more items */
              ],
            };

            // Create the promise and SES service object
            var sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();

            // Handle promise's fulfilled/rejected states
            sendPromise.then(
              function(data) {
                if(data.MessageId){
                  console.log('Email send');
                  self.order_success = true;
                  setTimeout(() => {
                    self.$refs['share-modal'].hide();
                  },5000)
                }
              }).catch(
              function(err) {
                console.error(err, err.stack);
                self.order_success = false;
            });
          }
        })
      }
    }
  }
}
</script>

<style scoped>
p:last-child {
    margin: 0px;
}
.slide-panel-light {
    background-color: #fff;
    padding: 30px;
    text-align: left;
    overflow: scroll;
}
.slide-panel{
    position: fixed;
    width: 0px;
    transition: all 450ms ease 0s;
    height: 100%;
    top: 0px;
    right: -275px;
    z-index: 1049;

    /* width: 275px;  */
}
.setbtn a.btn {
    float: right !important;
    width: 200px;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}
.request-list-table .table {
    font-size: 14px;
}
.table {
    margin-bottom: 0px;
    color: #6f6d72;
}
.request-list-table table th {
    background-color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252328;
}
.table thead th {
    vertical-align: bottom;
    font-weight: normal;
}
.request-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    padding: 8px;
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-xs {
    padding: 4px 8px !important;
    font-size: 11px !important;
    line-height: 1.5;
}
.btn-outline-violate {
    color: #9047fb;
    background-color: #f2e9ff;
    border-color: #9047fb;
}
.btn-outline-pink {
    color: #ff7b7b;
    background-color: #ffeded;
    border-color: #ff7b7b;
}
.btn-outline-violate:hover {
    color: #fff;
    background-color: #9047fb;
    border-color: #9047fb;
}
.btn-outline-pink:hover {
    color: #fff;
    background-color: #ff7b7b;
    border-color: #ff7b7b;
}
.btn-default:not(:disabled):not(.disabled).active, .btn-default:not(:disabled):not(.disabled):active, .show>.btn-default.dropdown-toggle {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.form-control {
    display: block;
    width: 100%;
    padding: 14px 16px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #252328;
    background-color: #fff;
    background-image: none;
    border: 1px solid #cfced0;
    border-radius: 2px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    /* background: #fff url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E) no-repeat right .75rem center; */
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}
.btn-success {
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
</style>