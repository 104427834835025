<template>
  <section class="register-section">
    <div class="register-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <v-form class="md-float-material form-material nregform" ref="form" lazy-validation v-model="valid">
              <div class="auth-box card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-4 col-sm-7  col-md-4">
                      <p class="text-left" style="margin-bottom:15px !important;"><a href="/"><img src="../../assets/images/new-logo1.png" style="margin-bottom: 5px; width: 128px; margin-left: 10px;"></a> </p>
                    </div>
                    <div class="col-5 col-sm-5  col-md-4">
                      <h3 class="text-center" style="margin-top:15px !important; font-size: 24px !important;">Counseling Appointment Form </h3>
                    </div>
                    <div class="col-5 col-sm-5  col-md-4">
                      <p class="text-right" style="margin-bottom:15px !important;"><img src="../../assets/images/counseling-badge.png" style=" width: 120px; margin-top: -10px; "></p>
                    </div>
                  </div> 
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/Bfyk1YgTuK4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <div class="name">
                    <v-text-field type="text" v-model="register.name" ref="nameInput" label="Name" :rules="[v => !!v || 'Enter valid name']"><template #label>Full Name<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="email">
                    <v-text-field type="text" v-model="register.email" ref="emailInput" :rules="emailRules" :error-messages="(auth_error)?auth_msg:null" @keydown.space.prevent><template #label>Email<span class="red--text"><strong>* </strong></span></template></v-text-field>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 col-md-3">
                      <v-text-field type="number" v-model="register.age" ref="ageInput" :rules="[v => !!v || 'Select age']" hide-spin-buttons><template #label>Age<span class="red--text"><strong>* </strong></span></template></v-text-field>
                    </div>
                    <div class="col-lg-3 col-md-3">
                      <v-autocomplete v-model="register.gender" :items="gender" ref="genderInput" item-text="text" item-value="value" label="Gender" :rules="[v => !!v || 'Select gender']"><template #label>Gender<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <v-text-field type="text" v-model="register.language" ref="languageInput" :rules="[v => !!v || 'Select language']"><template #label>Native Language<span class="red--text"><strong>* </strong></span></template></v-text-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <v-autocomplete  v-model="register.country" :items="countries" ref="countryInput" item-text="text" item-value="value" :rules="[v => !!v || 'Select country']" @input="getRegions"><template #label>Country<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <v-autocomplete  v-model="register.nationality" :items="countries" ref="nationalityInput" item-text="text" item-value="value" :rules="[v => !!v || 'Select nationality']"><template #label>Nationality<span class="red--text"><strong>* </strong></span></template></v-autocomplete>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mobile col-md-6 mt-0 pt-0">
                      <div class="row">
                        <div class="col-lg-12 pb-0 pt-0">
                          <label for="textarea" class="col-form-label p-0">Mobile Number</label>
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-lg-4"><v-autocomplete v-model="register.country_code" :items="codes" ref="countryCodeInput" item-text="text" item-value="value" :rules="[v => !!v || 'Select code']"><template #label>Code<span class="red--text"><strong>* </strong></span></template></v-autocomplete></div>
                        <div class="col-lg-7"><v-text-field type="number" v-model="register.mobile" ref="mobileInput" label="Mobile" :error-messages="(mob_error)?mob_msg:null" :rules="[v => !!v || 'Select Mobile']" hide-spin-buttons><template #label>Mobile<span class="red--text"><strong>* </strong></span></template></v-text-field></div>
                      </div>
                    </div>
                    <div class="whatsapp col-md-6 mt-0 pt-0">
                      <div class="row">
                        <div class="col-lg-12 pb-0 pt-0">
                          <label for="textarea" class="col-form-label p-0">Whatsapp Number</label>
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-lg-4"><v-autocomplete v-model="register.whatsapp_code" label="Code" :items="codes" item-text="text" item-value="value"></v-autocomplete></div>
                        <div class="col-lg-7"><v-text-field type="number" v-model="register.whatsapp_no" label="Mobile" hide-spin-buttons></v-text-field></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block">
                  <div class="enrollment-info">
                    <label for="textarea" class="col-form-label">I prefer <span class="red--text">*</span></label>
                    <v-select v-model="register.counsellor_type" :items="counsellor_type" item-text="text" item-value="value" :rules="[v => !!v || 'Select type']" ref="counsellorTypeInput" outlined></v-select>
                  </div>
                  <div class="fee-info row">
                    <div class="col-md-12 mb-0 pb-0">
                      <label for="textarea" class="col-form-label">What days work best for you?<span class="red--text">*</span></label>
                    </div>
                    <div class="col-md-2 col-sm-6 mb-0 pb-0">
                      <v-checkbox v-model="register.days" label="Monday" value="Monday" ref="daysInput"></v-checkbox>
                    </div>
                    <div class="col-md-2 col-sm-6 mb-0 pb-0">
                      <v-checkbox v-model="register.days" label="Tuesday" value="Tuesday" ref="daysInput"></v-checkbox>
                    </div>
                    <div class="col-md-2 col-sm-6 mb-0 pb-0">
                      <v-checkbox v-model="register.days" label="Wednesday" value="Wednesday" ref="daysInput"></v-checkbox>
                    </div>
                    <div class="col-md-2 col-sm-6 mb-0 pb-0">
                      <v-checkbox v-model="register.days" label="Thursday" value="Thursday" ref="daysInput"></v-checkbox>
                    </div>
                    <div class="col-md-2 col-sm-6 mb-0 pb-0">
                      <v-checkbox v-model="register.days" label="Friday" value="Friday" ref="daysInput"></v-checkbox>
                    </div>
                    <div class="col-md-2 col-sm-6 mb-0 pb-0">
                      <v-checkbox v-model="register.days" label="Saturday" value="Saturday" ref="daysInput"></v-checkbox>
                    </div>
                    <div class="red--text" style="font-size:12px;" v-if="!register.days.length&&!this.valid">Select day</div>
                  </div>
                  <div class="fee-info row">
                    <!-- <div class="col-md-12"> -->
                      <label for="textarea" class="col-form-label">What time works best for you?<span class="red--text">*</span></label>
                    <!-- </div> -->
                    <v-row>
                      <v-col cols="12" sm="4" md="4">
                        <v-checkbox v-model="register.timing" label="Morning" value="Morning" ref="timingInput"></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-checkbox v-model="register.timing" label="Afternoon" value="Afternoon" ref="timingInput"></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <v-checkbox v-model="register.timing" label="Evening" value="Evening" ref="timingInput"></v-checkbox>
                      </v-col>
                      <div class="red--text" style="font-size:12px;" v-if="!register.timing.length&&!this.valid">Select time</div>
                    </v-row>
                  </div>
                  <div class="fee-info row">
                    <div class="col-md-12">
                      <label for="textarea" class="col-form-label">Appointment date you prefer in KSA time zone (Note :this is not a confirmed appointment time until the counselor agree) <span class="red--text">*</span></label>
                    </div>
                    <div class="col-md-4 date-pc">
                      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="register.date" label="Select" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :rules="[v => !!v || 'Select day']" ref="dateInput"><template #label>Select Date<span class="red--text"><strong>* </strong></span></template></v-text-field>
                        </template>
                        <v-date-picker v-model="register.date" :active-picker.sync="activePicker" :min="(new Date(Date.now())).toISOString().substr(0, 10)"  @change="save"></v-date-picker>
                      </v-menu>
                      <!-- <v-date-picker v-model="date" ></v-date-picker> -->
                    </div>
                    <div class="col-md-4">
                    <label for="appt">Select a time<span class="red--text">*</span>: </label>
                      <input type="time" v-model="register.time" id="appt" name="appt" class="bordr-box" style="margin-left: 5px;" ref="timeInput">
                      <div class="red--text" v-if="!register.time && !this.valid" style="font-size:12px;">Select time</div>
                    </div>
                  </div><br>
                  <div class="enrollment-info">
                    <label for="textarea" class="col-form-label">Why are you seeking counseling for ? <span class="red--text">*</span></label>
                    <v-textarea type="text" v-model="register.enrollment_info" :rules="[v => !!v || 'Enter reason']" ref="enrollmentInput"></v-textarea>
                  </div>
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block">
                  <div class="enrollment-info">
                    <label for="textarea" class="col-form-label">What is your major concern? When is the onset ( starting period or any special event) of your problems?</label>
                    <v-textarea type="text" v-model="register.problem_info"></v-textarea>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">What is your married status? If married, how long you are married?</label>
                    <v-textarea type="text" v-model="register.marriage_info"></v-textarea>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">Are you working? Explain your professional life .</label>
                    <v-textarea type="text" v-model="register.professional_info"></v-textarea>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">Do you have children? If yes, how many? Any concerns on your parenting style ?</label>
                    <v-textarea type="text" v-model="register.parenting_info"></v-textarea>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">Do you feel anxious or depressed? Explain physiological symptoms and your feelings related to that.</label>
                    <v-textarea type="text" v-model="register.physiological_info"></v-textarea>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">Are you taking any medications? If yes, since when? Any note on that to help us like how do you feel? ( please note that we don't prescribe medications )</label>
                    <v-textarea type="text" v-model="register.medications_info"></v-textarea>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">Do you have any previous reports? (please attach below) Have you ever attended any therapy or counseling sessions before? If yes, explain .</label>
                    <v-textarea type="text" v-model="register.sessions_info"></v-textarea>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">Please attach your previous reports here</label>
                    <v-file-input accept="image/*" v-model="report" @change="addFileToS3(report,'pdf','report')" label="" placeholder="Upload"></v-file-input>
                    <v-progress-linear :value="report_progress" v-if="report && report_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                  </div>
                  <div class="address">
                    <label for="textarea" class="col-form-label">What do you expect from counseling sessions ? Any additional information to help us to help you?</label>
                    <v-textarea type="text" v-model="register.additional_info"></v-textarea>
                  </div>
                  <!-- <div class="address">
                    <label for="textarea" class="col-form-label">Speak to us your problem</label><br>
                    <audio id="recorder" muted hidden></audio>
                    <button class="btn btn-primary" v-on:click.prevent="startRecording()" v-if="!isRecording"><i class="fa fa-microphone"></i> Record</button>
                    <button class="btn btn-primary mr-2" v-if="isRecording"><i class="fa fa-microphone"></i>Recording ...</button>
                    <button class="btn btn-danger" v-on:click.prevent="stopRecording()" v-if="isRecording"><i class="fa fa-stop"></i> Stop</button>
                    <audio id="player" controls v-show="record&&!isRecording"></audio>
                  </div> -->
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block"> 
                  <v-radio-group v-model="register.counsellor" required :rules="[v => !!v || 'Select counsellor']" ref="counsellorInput">       
                    <div class="row">
                      <div class="col-lg-12">
                        <h3>Choose one from the list of counselors available <span class="red--text">*</span></h3>
                        <v-radio :value=1>
                          <template v-slot:label><b>A member of British Association of Counselling and Psychotherapy $60.00</b></template>
                        </v-radio>
                        <p class="counsiling-sub-txt">Available on Mondays, Thursdays, and Fridays only(5AM EST to 1PM EST10PM GMT to 6PM GMT)I am a counsellor who provides a safe, warm and comfortable place for clients to explore what is making them feel unhappy with their lives, and enable them to explore how they can move forward or adjust to what they are experiencing, to be the person they want to be and lead a happy fulfilling life.Our lives can change in a second sometimes this is a good experience, but when it changes and makes us feel unhappy, sometimes alone and overwhelmed with life, we want this to change, however this can be painful and difficult to do alone, I believe counselling gives the client a safe place to explore these feelings without being judged or directed and most important to be listened to.Most importantly Islamic principles will be implemented into therapy.I work with young people from the ages of 12, and adults I have experience of face to face, telephone and Skype counselling.I work with people from all walks of life and backgrounds, coming to me with a diverse range of problems. As a fully-qualified counsellor I am a member of the British Association of Counselling and Psychotherapy, and abide by their code of ethics. I am also a verified member of Psychology</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <v-radio :value=2>
                          <template v-slot:label><b>MALE COUNSELOR WITH ADVANCE COUNSELLING. $25.00</b></template>
                        </v-radio>
                        <p class="counsiling-sub-txt">Brother who is very hardworking, conscientious and caring counselor with extensive knowledge of offering advice, support, rehabilitation and guidance to clients to cope issues that maybe hampering their overall mental health.Having a proven ability to empathize with individuals whilst retaining an objective and realistic approach to their treatment and therapy. Comfortable working and communicating with people from all backgrounds and having experience of trauma, abuse, addiction, depression, marital difficulties and child-parent conflicts and mostly helping clients having Anxiety and Obsessive Compulsive Disorder.</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <v-radio :value=3>
                          <template v-slot:label><b>COUNSELLOR WITH DIPLOMA IN COUNSELLING, CBT $25.00</b></template>
                        </v-radio>
                        <p class="counsiling-sub-txt">Diploma in Counseling (Status: Ongoing , Australian university) Our counselling approach is based on the Quran, Sunnah and Islamic-family values upon the understanding of our pious predecessors. We strive our best to distinguish our efforts in counselling and hence not to be rooted in erroneous ideologies and deviant methodologies but to use proven successful methods which won't go against our religion and its foundation. We ask Allah to bless us with sincerity and adherence to the Sunnah and we strive to the best of our abilities</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <v-radio :value=4>
                          <template v-slot:label><b>Certified Counselling Psychology Practitioner and Teenage therapist $20.00</b></template>
                        </v-radio>
                        <p class="counsiling-sub-txt">Pay at our PayPal id (<a class="mailto:fromyourtutor@gmail.com">fromyourtutor@gmail.com</a>)before you send us your appointment request form</p>
                      </div>
                    </div>
                  </v-radio-group>         
                </div>
              </div>
              <div class="auth-box card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-lg-12">
                      <h3>Read Carefully AMCC's Counseling Service GENERAL Contract before you book an appointment:</h3>
                      <p><a href="https://tution-site.s3.ap-south-1.amazonaws.com/test/counselling-contract.pdf">click here to download the PDF file.</a></p>
                      <p><br>
                        <v-checkbox v-model="register.terms" value="true" :rules="[v => !!v || 'Select terms and conditions']" ref="termsInput">
                          <template v-slot:label>I agree to terms & conditions in the Counseling contract pdf mentioned <span class="red--text">*</span></template>
                        </v-checkbox>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <p>Please do the payment  <span style="color:red">*</span></p>
                      <div style="border-radius: 5px; padding:0px 10px 10px 10px; background: rgb(238, 238, 238) none repeat scroll 0% 0%;">
                        <div class="row">
                          <div class="col-md-6" style="width:222px">PayPal Directly:</div>
                          <div class="col-md-6">
                            <a href="https://www.paypal.com/paypalme/fromyourtutor21" target="_blank" class="btn btn-primary btn-sm text-white" style="padding:3px 10px">Click to Pay Now</a>
                          </div>
                        </div>
                      
                        <div class="row">
                          <div class="col-md-6" style="width:222px">Fromyourtutor's Website:</div>
                          <div class="col-md-6">
                            <a href="https://fromyourtutor.com/payment" target="_blank" class="btn btn-primary btn-sm text-white" style="padding:3px 10px">Click to Pay Now</a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6" style="width:222px">For Direct Bank Transfer:</div>
                          <div class="col-md-6">contact  <a href="mailto:amcc@fromyourtutor.com">amcc@fromyourtutor.com</a></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="register.payment_status != null">
                    <div class="col-lg-12">
                      <p>Payment done via  <span style="color:red">*</span></p>
                      <v-radio-group v-model="register.payment" :rules="[v => !!v || 'Select payment method']">
                        <v-radio label="PayPal Directly" :value="1"></v-radio>
                        <v-radio label="Fromyourtutor's Website" :value="2"></v-radio>
                        <v-radio label="Bank Account" :value="3"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12"> 
                      <div class="reciept">
                        <label for="formFile" class="form-label">Submit your fees screenshot here <span style="color:red">*</span></label>
                        <v-file-input accept="image/*" v-model="payment_screenshot" @change="addFileToS3(payment_screenshot,'image','payment')" :rules="[v => !!v || 'Upload screenshot']" label="" placeholder="Upload" ref="paymentInput"></v-file-input>
                        <v-progress-linear :value="payment_progress" v-if="payment_screenshot && payment_progress !== 100" color="light-blue" height="10" striped></v-progress-linear>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12"> 
                      <b-button type="submit" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-if="!loading"  v-on:click.prevent="submitRegisterForm">Submit Application</b-button>
                      <b-button type="button"  class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-if="loading"><i class="fa fa-spinner fa-spin"></i></b-button>
                    </div>
                  </div>
                </div>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">Registration successful</div>
    </b-modal>
    <b-modal id="recorder" ref="voice_recorder" hide-footer hide-header centered>
      <div class="" style="width:100%;height:100%;">
        <audio-recorder v-model="record" :attempts="1"/>
      </div>
      <div class="modal-footer justify-content-center" style="border-top:none;">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click.prevent="$refs['coice_recorder'].hide();">Cancel</button>
        <button type="button" class="btn btn-success" v-on:click="saveRecord()">Save</button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import axios from "axios";
import AWS from "aws-sdk";
export default {
  name:'counseling-register-form',
  data(){
    return{
      valid: true,
      can_register:0,
      countries: [],
      courses:[],
      codes:[],
      regions:[],
      student:{
        code:null,
        mobile:null,
        country:null
      },
      register: {
        name: null,
        email: null,
        country_code:null,
        country:null,
        nationality:null,
        mobile: null,
        gender: null,
        age:null,
        whatsapp_code:null,
        whatsapp_no:null,
        language:null,
        counsellor_type:null,
        days:[],
        timing:[],
        date:null,
        time:null,
        enrollment_info:null,
        problem_info:null,
        marriage_info:null,
        professional_info:null,
        parenting_info:null,
        physiological_info:null,
        medications_info:null,
        sessions_info:null,
        report:null,
        additional_info:null,
        record:null,
        counsellor:null,
        terms:null,
        payment_mode:null,
        payment:null,
      },
      auth_error: false,
      auth_msg: null,
      mobileRules:[
        v => !!v || 'Enter valid mobile',
        v => /\D/g.test(v) == false || 'Enter 10 digit mobile number',
        v => (v && v.length == 10) || 'Enter 10 digit mobile number'
      ],
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      mob_error:false,
      mob_msg:'',
      loading:false,
      counsellor_type:[
        {value:1,text:'Male counsellor'},
        {value:2,text:'Female counsellor'},
        {value:3,text:'Male or Female'}
      ],
      activePicker: null,
      date: null,
      menu: false,
      payment_screenshot:null,
      payment_progress:0,
      report:null,
      record:null,
      report_progress:null,
      record_progress:null,
      QRCode:false,
      mediaRecorder: null,
      stream:null,
      chunks : [],
      isRecording : false,
      constraints : {
        audio: true,
        video: false
      },
      recorderRef:null,
      gender:[
        {value:1,text:'Male'},
        {value:2,text:'Female'},
        {value:3,text:'Other'}
      ]
    }
  },
  beforeMount(){
    this.getCountries();
    this.getCourses();
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
    openModal: function (type = "") {
      this.open = (type == "")? this.open : type;
      if (this.open == "success") {
        this.$refs["success"].show();
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    startRecording() {
      this.playerRef = document.querySelector("#player");
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        if(this.isRecording) return
        this.isRecording = true;
        this.recorderRef = document.querySelector("#recorder");
        navigator.mediaDevices.getUserMedia(this.constraints).then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this))
      } else {
        console.log("getUserMedia is not supported on your browser!")
      }
    },
	
    stopRecording() {
      if (!this.isRecording) return
      this.isRecording = false;
      document.querySelector("#recorder").pause()
      this.recorderRef.pause();
      this.mediaRecorder.stop();
      this.addFileToS3(this.chunks,'audio','record');
      const blob = new Blob(this.chunks, { 'type': 'audio/mp3' });
      this.record = blob;
      const audioURL = window.URL.createObjectURL(blob);
      this.playerRef.src = audioURL;
      // this.chunks = []
      // this.stream.getAudioTracks().forEach(track => track.stop())
      // this.stream = null
    },

    handleSuccess(stream) {
		this.stream = stream
		this.stream.oninactive = () => {
			console.log("Stream ended!");
		};
		this.recorderRef.srcObject = this.stream;
		this.mediaRecorder = new MediaRecorder(this.stream);
		this.mediaRecorder.ondataavailable = this.onMediaRecorderDataAvailable.bind(this);
		this.mediaRecorder.onstop = this.onMediaRecorderStop.bind(this);
		this.recorderRef.play();
		this.mediaRecorder.start();
	},

    handleError(error) {
      console.log("navigator.getUserMedia error: ", error)
    },
    
    onMediaRecorderDataAvailable(e) { this.chunks.push(e.data);console.log(this.chunks); },
    
    onMediaRecorderStop() { 
        const blob = new Blob(this.chunks, { 'type': 'audio/mp3' })
        const audioURL = window.URL.createObjectURL(blob)
        this.playerRef.src = audioURL
        this.chunks = []
        this.stream.getAudioTracks().forEach(track => track.stop())
        this.stream = null
    },

    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          if(subject.is_active == 'Y'){
            this.courses.push({value:subject.subject_id,text:subject.subject_name});
          }
        })
      });
    },

    getCountries(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/countries',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.countries.forEach(country => {
          this.countries.push({value:country.id,text:country.name,code:country.phonecode,short:country.sortname});
          this.codes.push({value:country.phonecode, text:country.sortname+ '-' + country.phonecode})
        })
      })
    },

    getRegions(){
      this.countries.forEach(country => {
        if(country.value == this.register.country){
          this.register.country_code = country.code;
        }
      });
    },

    addFileToS3(file,type,field_name){
      this.loading = true;
      var access_key = process.env.VUE_APP_S3_ACCESS_KEY;
      var secret_key = process.env.VUE_APP_S3_SECRET_KEY;
      let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
    
      AWS.config.update({
        accessKeyId: keys.accessKeyId,
        secretAccessKey: keys.secretAccessKey,
        region: 'ap-south-1'
      });

      let s3BucketName = "tution-site"; 
      var d = new Date();
      var month = d.getMonth() + 1;
      var day = d.getDate();
      var current_date =d.getFullYear() +"/" +(month < 10 ? "0" : "") +month +"/" +(day < 10 ? "0" : "") +day;
      var timestamp = Math.floor(Date.now() / 1000);
      if(file){
        if(field_name=='record'){
          var data = new Blob([file],{type: "application/octet-binary"});
          this.isUploading++;
          var s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'counselling/'+field_name+'/'+ current_date+"/"+ timestamp + ".mp3",
              ACL: "public-read",
            },
          });
        }else{
          data = (field_name=='payment')?new Blob([file]):new Blob([file], { type: "application/"+type });
          var extension = file.name.split('.')[file.name.split('.').length-1];
          var name = file.name.split('.')[0];
          this.isUploading++;
          s3obj = new AWS.S3({
            params: {
              Bucket: s3BucketName,
              Key:
                'counselling/'+field_name+'/'+ current_date+"/"+name+ timestamp + "."+extension,
              ACL: "public-read",
            },
          });
        }
        var upload = s3obj.upload({ Body: data });
        let that = this;
        upload.on("httpUploadProgress", function (evt) {
          switch(field_name) {
              case 'payment': that.payment_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              case 'record': that.record_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              case 'report': that.report_progress = Math.floor((evt.loaded / evt.total) * 100); break;
              default: break;
          }
        }).send((err, data) => {
          this.loading = false;
          switch(field_name) {
              case 'payment': that.register.payment= data.Location; break;
              case 'record': that.register.record= data.Location; break;
              case 'report': that.register.report= data.Location; break;
              default: break;
          }
          this.isUploading--;
        });
      }
    },
    submitRegisterForm(e){
      e.preventDefault();
      this.loading = true;
      this.valid = this.$refs.form.validate() && this.register.time && this.register.timing.length>0 && this.register.days.length>0;
      this.auth_msg = null;
      this.auth_error = false;
      if(this.valid == true){
        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/counselling_form',this.register,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.status != "error"){
            this.openModal("success");
            setTimeout(() => {
              this.$refs["success"].hide();
              this.$router.push('/');
            },2000)
          }else{
            this.loading = false;
          }
        }).catch((err) => {
          this.loading = false;
          if(err.response){
            if(err.response.data.email){
              this.auth_error = true;
              this.auth_msg = 'Email is already registered with us'
            }
            if(err.response.data.mobile){
              this.mob_error = true;
              this.mob_msg = 'Mobile is already registered with us'
            }
          }else{
            console.log(err);
          }
        });
      }else{
        this.loading = false;
        if(this.register.name === null){
          this.$refs.nameInput.$el.querySelector('input').focus();
        }else if(this.register.email === null){
          this.$refs.emailInput.$el.querySelector('input').focus();
        }else if(this.register.age === null){
          this.$refs.ageInput.$el.querySelector('input').focus();
        }else if(this.register.gender === null){
          this.$refs.genderInput.$el.querySelector('input').focus();
        }else if(this.register.language === null){
          this.$refs.languageInput.$el.querySelector('input').focus();
        }else if(this.register.country === null){
          this.$refs.countryInput.$el.querySelector('input').focus();
        }else if(this.register.nationality === null){
          this.$refs.nationalityInput.$el.querySelector('input').focus();
        }else if(this.register.country_code === null){
          this.$refs.countryCodeInput.$el.querySelector('input').focus();
        }else if(this.register.mobile === null){
          this.$refs.mobileInput.$el.querySelector('input').focus();
        }else if(this.register.counsellor_type === null){
          this.$refs.counsellorTypeInput.$el.querySelector('input').focus();
        }else if(this.register.days.length === 0){
          this.$refs.daysInput.$el.querySelector('input').focus();
        }else if(this.register.timing.length === 0){
          this.$refs.timingInput.$el.querySelector('input').focus();
        }else if(this.register.date === null){
          this.$refs.dateInput.$el.querySelector('input').focus();
        }else if(this.register.time === null){
          this.$refs.timeInput.focus();
        }else if(this.register.enrollment_info === null){
          this.$refs.enrollmentInput.$el.querySelector('textarea').focus();
        }else if(this.register.counsellor === null){
          this.$refs.counsellorInput.$el.querySelector('input').focus();
        }else if(this.register.terms === null){
          this.$refs.termsInput.$el.querySelector('input').focus();
        }else if(this.register.payment === null){
          this.$refs.paymentInput.$el.querySelector('input').focus();
        }
      }
    }
  } 
}
</script>
<style>
.nregform .theme--light.v-input textarea{
    border:solid 1px #ebeeed;
    min-height: 38px;
    display: block;
    padding: 10px;
}

</style>
<style scoped>
.register-section{
    background: url(../../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
}
.register-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.register-block .auth-box {
    margin: 20px auto 0;
    max-width: 850px !important;
}
h3{
  font-weight: bold;
  margin-bottom: 20px;
}
.col-form-label{
  font-size: 15px;
  font-weight: bold;
  color: black;
  margin-top: 18px;
}
.v-input--checkbox{
  margin-top: -13px;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.card .card-block {
    padding: 1.25rem;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn:hover {
  color: #ffa25f;
}
.btn-lg {
    font-size: 14px;
    line-height: 1.3333333;
}
.bordr-box{
  border: solid 1px #cccccc;
  padding: 5px;
  border-radius: 5px;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}
.counsiling-sub-txt{
  font-size: 14px;
  line-height: 28px;
  padding-left: 24px;
  margin: 0px;
}
.v-radio.theme--light label b{
  color: rgb(196, 12, 12);
}
input.form-check-label{
  margin-right: 5px;
}
.form-check-label{
  padding-left: 8px;
}
.date-pc .v-input{
  padding-top: 0px;
  margin-top: 0px;
  
}
#bars {
    display: flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    bottom:0;
/*     background: black; */
}

.bar {
    position:absolute;
    background: #52467b;
    bottom: 1px;
    height: 1px;
    width: 10px;
    margin: 0px 4px;
    border-radius: 5px;
    animation: sound 0ms -600ms linear infinite alternate;
}

@keyframes sound {
    0% {
       opacity: .35;
        height: 3px; 
    }
    100% {
        opacity: 1;       
        height: 70px;        
    }
}

.bar:nth-child(1)  { left: 1px; animation-duration: 474ms; }
.bar:nth-child(2)  { left: 15px; animation-duration: 433ms; }
.bar:nth-child(3)  { left: 29px; animation-duration: 407ms; }
.bar:nth-child(4)  { left: 43px; animation-duration: 458ms; }
.bar:nth-child(5)  { left: 57px; animation-duration: 400ms; }
.bar:nth-child(6)  { left: 71px; animation-duration: 427ms; }
.bar:nth-child(7)  { left: 85px; animation-duration: 441ms; }
.bar:nth-child(8)  { left: 99px; animation-duration: 419ms; }
.bar:nth-child(9)  { left: 113px; animation-duration: 487ms; }
.bar:nth-child(10) { left: 127px; animation-duration: 442ms; }

</style>
