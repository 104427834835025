<template>
  <div class="dashboard-content">
    <div class="container pt-7">
      <div class="card">
        <div class="card-header">Bank Details</div>
        <div class="card-body">
          <form action="submit">
            <div class="personal-form-info">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="bank-name">Bank Name</label>
                    <v-text-field type="text" v-model="bank_details.name" outlined readonly></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="holder-name">Account Holder Name</label>
                    <v-text-field type="text" v-model="bank_details.holder_name" outlined readonly></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="account-number">Account Number</label>
                    <v-text-field type="text" v-model="bank_details.acc_number" outlined readonly></v-text-field>
                  </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                  <div class="form-group">
                    <label class="control-label" for="swift-code">Swift Code</label>
                    <v-text-field type="text" v-model="bank_details.swift_code" outlined readonly></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <p><small><span class="mr-2"><span class="badge-dot badge-danger"></span>If need any changes in the details above please contact admin</span></small></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name:'student-chatroom',
  data(){
    return {
      bank_details:{
        name:null,
        holder_name:null,
        acc_number:null,
        swift_code:null,
        tutor_id:null,
      }
    }
  },
  beforeMount(){
    this.getBankDetails();
  },
  methods:{
    getBankDetails(){
      let tutor_token = this.$cookies.get("tution-tutor")?this.$cookies.get("tution-tutor").token:null;
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor_bank',{headers:{"Authorization":'bearer '+tutor_token}}).then(res => {
        if(res.data.status == 1){
          let bank = res.data.data;
          this.bank_details.name = bank.bank_name;
          this.bank_details.holder_name = bank.account_holder_name;
          this.bank_details.acc_number = bank.account_number;
          this.bank_details.swift_code = bank.swift_code;
          this.bank_details.tutor_id = bank.tutor_id;
        }
      })
    }
  }
}
</script>
<style scoped>
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
}
.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}
.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 1px;
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
    line-height: 2.3;
}
</style>