<template>
  <div class="dashboard-content">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="dashboard-page-header mb-0 pb-3">
            <!-- <h3 class="dashboard-page-title">Students <a v-on:click="addStudentModal()" class="btn btn-default ml-3" id="trigger6" style="font-size:12px !important;">ADD STUDENT</a></h3> -->
            <p class="d-block mb-2">View all Counselling Forms</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="guestlist-management">
            <div class="card">
              <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs pl-0" id="myTab" role="tablist">
                  <li class="nav-item mb-0">
                    <a class="nav-link" :class="{'active': tab == 'counselling-form'}" @click="tab='counselling-form'" id="allguest-tab" data-toggle="tab" role="tab" aria-controls="allguest">Counselling Forms</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" :class="{'active show': tab == 'counselling-form'}" id="allguest" role="tabpanel" aria-labelledby="allguest-tab">
                  <div class="tab-content" id="guest-filter-content">
                    <div class="tab-pane fade show active" id="pills-single" role="tabpanel" aria-labelledby="pills-single-tab">
                      <div class="guest-list-table table-responsive mb-0">
                        <div class="single-guest-tab">
                          <div id="allguest-datatables_wrapper" class="dataTables_wrapper no-footer">
                            <table class="table dataTable no-footer mb-0" id="allguest-datatables" role="grid" aria-describedby="allguest-datatables_info">
                              <thead>
                                <tr role="row">
                                  <th>Sn.</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Reason</th>
                                  <th>Date&Time</th>
                                  <th>Actions</th>
                                </tr>
                              </thead> 
                              <tbody v-if="counselling_forms.length">
                                <tr v-for="(form,index) in counselling_forms" :key="index">
                                  <td v-if="form">{{index+1}}</td>
                                  <td v-if="form.name">{{form.name}}</td>
                                  <td v-if="form.email">{{form.email}}</td><td v-else>-</td>
                                  <td v-if="form.enrollment_info">{{form.enrollment_info}}</td><td v-else>-</td>
                                  <td v-if="form.date || data">{{form.date}}  {{ form.time }}</td><td v-else>-</td>
                                  <td>
                                    <a :href="'/tutn-auth/counselling/'+form.id" class="btn btn-outline-violate btn-xs mr-1">view</a>
                                    <!-- <a class="btn btn-outline-pink btn-xs" v-on:click.prevent="disableStudentForm(form.student_id,index)">delete</a> -->
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td class="empty-data text-center" colspan="6" rowspan="4">No Counselling Froms Listed</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-modal id="addStudent" ref="student-modal" size="lg" title="Add Student Deatils" hide-footer centered>
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="card">
              <div class="card-body">
                <v-form ref="form" lazy-validation v-model="valid">
                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Name<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.name" :rules="[v => (!!v && !/[^a-zA-Z ]/g.test(v)) || 'Enter valid name']" outlined ></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Email<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="text" v-model="register.email" :rules="emailRules" outlined :error-messages="auth_msg"></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Password<span class="red--text"><strong>* </strong></span></label>
                      <v-text-field type="password" v-model="register.password" :rules="passwordRules" outlined></v-text-field>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Country</label>
                       <v-autocomplete v-model="student.country" :items="countries" item-text="text" item-value="value" @input="getRegions()" searchable outlined clearable></v-autocomplete>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <div class="row">
                        <div class="col-lg-4 pt-0">
                          <label for="inputText3" class="col-form-label">Country code</label>
                          <v-autocomplete v-model="student.code" :items="codes" item-text="text" item-value="value"  @input="getCountry" outlined></v-autocomplete>
                        </div>
                        <div class="col-lg-8 pt-0">
                          <label for="inputText3" class="col-form-label">Mobile</label>
                          <v-text-field type="text" v-model="register.mobile" outlined :error-messages="mob_msg"></v-text-field>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Select gender</label>
                      <v-autocomplete v-model="register.gender" :items="gender" item-text="text" item-value="value" outlined ></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Region</label>
                       <v-autocomplete v-model="register.region" :items="regions" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 pt-0 pb-0">
                      <label for="inputText3" class="col-form-label">Select Courses</label>
                       <v-autocomplete v-model="register.course" :items="courses" item-text="text" item-value="value" searchable outlined clearable></v-autocomplete>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pt-0 pb-0">
                      <b-button type="submit" class="btn btn-default" v-if="!loading" v-on:click.prevent="postRegister">Submit</b-button>
                      <b-button type="button"  class="btn btn-default" v-if="loading"><i class="fa fa-spinner fa-spin"></i></b-button>
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal> -->
    <b-modal id="confirmation" ref="confirm-modal" title=" " hide-footer hide-header centered>
      <div class="container">
        <div class="icon-box text-center"><i class="far fa-times-circle"></i></div>		
        <h4 class="modal-title w-100 text-center" style="margin:30px 0 -10px;font-size:26px;">Are you sure?</h4>	
        <div class="modal-body text-center">
          <p>Do you really want to delete these records?</p>
        </div>		
        <div class="modal-footer justify-content-center" style="border-top:none;">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" v-on:click="hideModal('confirm-modal')">Cancel</button>
            <button type="button" class="btn btn-danger" v-on:click="deleteRecord">Delete</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">{{message}}</div>
    </b-modal>
    <b-modal id="error" ref="error" size="sm" title=" " body-bg-variant="danger" bod body-text-variant="light" hide-footer hide-header centered>
      <div class="container text-center">{{message}}</div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "admin-students",
  data(){
    return {
      tab: "counselling-form",
      counselling_forms:[],
      message:''
    }
  },
  beforeMount(){
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/counselling_form',{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
      if(res.data.status){
        this.counselling_forms = res.data.data;
      }else{
        this.counselling_forms = [];
      }
    });
  },
  methods:{
    hideModal(data){
      this.$refs[data].hide();
    },
    deleteRecord(){
      this.activate.status = 'N';
      this.activate.student_id = this.record.id;
      this.activate.type = this.record.form;

      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/student_disable',this.activate,{headers:{"Authorization":'Bearer '+(this.$cookies.get('tution-admin').token)}}).then(res => {
        if(res.data.status){
          if(this.activate.type == 'S'){
            this.students.splice(this.record.index,1);
          }else{
            this.student_forms.splice(this.record.index,1);
          }
          this.$refs["confirm-modal"].hide();
        }
      })
    }
  }
}
</script>
<style scoped>
.rsvp-summary.card {
    box-shadow: none;
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    box-shadow: 0 0 0 1px rgb(61 70 79 / 5%), 0 1px 3px 0 rgb(61 70 79 / 15%);
    margin-bottom: 30px;
    border-radius: 2px;
}
.summary-block {
    margin-bottom: 30px;
}
.bg-light-violate {
    background-color: #e1caf1 !important;
    border-color: #e1caf1;
}
.bg-light-green {
    background-color: #cfedad !important;
    border-color: #cfedad;
}
.card-body {
    padding: 20px;
}
.summary-count {
    font-size: 42px;
    color: #252328;
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 1;
}
.rsvp-summary .summary-text {
    color: #252328;
}
.rsvp-summary-section {
    margin-bottom: 30px;
}
p:last-child {
    margin: 0px;
}
.summary-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #6f6d72;
}
.guest-list-block, .budget-list-block {
    font-size: 18px;
}
.card-header {
    background: #fbfbfb;
    border-bottom: 1px solid #e6e5e7;
    color: #252328;
    padding: .75rem 1.25rem;
    margin-bottom: 0;
}
.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0;
}

.card-header-tabs .nav-item {
    font-size: 14px;
}
.nav-tabs .nav-item {
    margin-bottom: 5px;
}
.card-header-tabs .nav-item .nav-link.active {
    color: #0079df;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.guest-list-table table th {
    background-color: #fff;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding: 14px 20px;
    color: #252324;
}
table th{
  border-top: none;
}
.guest-list-table .table {
    font-size: 14px;
}
.card-header-tabs .nav-item .nav-link {
    color: #6f6d72;
    font-size: 16px;
    padding: 10px 30px;
}
.guest-list-table table td {
    background-color: #fdfdfd;
    border-bottom: 1px solid #eee;
    padding: 10px 16px;
}
.table td, .table th {
    vertical-align: baseline;
    border-top: none;
    font-weight: normal;
}
table{
  color: #5f5f5f;
}
.red--text{
  color: red;
}
.btn{
    border-radius: 2px;
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 19px;
}
.btn-default{
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn-default:hover {
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.red--text{
  color: red;
}
</style>