<template>
  <div class="study_materials">
    <!-- <div class="filter-form">
      <div class="container">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12" style="padding-top:19px !important;">
              <v-autocomplete v-model="filter_subject" :items="subjects" item-text="text" item-value="value" placeholder="Select Subject" clearable outlined></v-autocomplete>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
              <button class="btn btn-default btn-block w-100" v-on:click.prevent="searchSubject()">Search</button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="content">
      <div class="container">
        <div class="row" v-if="courses.length && !loading">
          <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12" v-for="course in courses" :key="course.id">
            <div class="vendor-thumbnail">
              <div class="vendor-img zoomimg" style="height:250px;">
                <a :href="'/video_course/'+course.name+'?id='+course.id"> 
                  <img :src="course.thumbnails[0].url" alt="" class="img-fluid" v-if="course.thumbnails.length" style="pointer-events:none;">
                  <img src="../assets/images/book.jpg" alt="" class="img-fluid" v-else style="pointer-events:none;">
                </a>
              </div>
              <div class="vendor-content">
                <h2 class="vendor-title">${{course.price}}</h2>
                <h2 class="vendor-title" style="white-space: pre-line;overflow: hidden;text-overflow: ellipsis;">{{course.name}}</h2>
                <p class="vendor-address">{{course.modules.length}} Module(s)</p>
                <p><a href="" @click.prevent="addToCart(course)" class="btn btn-success btn-sm" v-if="!course.cart">Add to Cart</a></p>
                <p>
                  <a href="" class="btn btn-secondary text-white btn-sm" v-if="course.cart">  Added  </a>
                  <a href="/cart" class="btn btn-success btn-sm ml-1" v-if="course.cart">Go to cart</a>
                </p>                <!-- <p>
                  <a href="" class="btn btn-secondary text-white btn-sm">  Added  </a>
                  <a href="/cart" class="btn btn-success btn-sm ml-1">Go to cart</a>
                </p> -->
                <p v-if="course.female_only==1" class="female_filter">Female only</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else-if="!courses.length && !loading">
          <div class="col-lg-12 col-md-12 text-center mb-15 empty-data">
            No materials available
          </div>
        </div>
        <div class="dashboard-vendor-list" style="margin-top:75px;text-align:center;" v-else>
          <pulseLoader :loading="loading" color="#d51439" size="100"></pulseLoader>
        </div>
        <div class="col pt-0" v-if="courses.length">
          <v-pagination v-model="page" :length="length" class="float-left" @input="handlePageChange"></v-pagination>
        </div>
        <!-- <div class="row" v-if="isLoadmore">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="pagination justify-content-center">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item active"><a class="page-link" v-on:click.prevent="loadmore()">Load More</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import pulseLoader from '@/components/dist/Loader';
export default {
  name: "videoCourse",
  components:{
    pulseLoader
  },
  data(){
    return {
      subjects: [],
      limit: 12,
      items:[],
      page:1,
      length:0,
      offset: 0,
      total: 0,
      courses:[],
      filter_subject:null,
      isLoadmore:true,
      user_id:null,
      loading:true
    }
  },
  async beforeMount(){
    window.scrollTo(0, 0);
    if(this.$cookies.get('order-cart-session-key')&&this.$cookies.get('order-cart-session-token')){
      console.log('');
    }else{
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/cart',{},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.Message == 'A new cart have been created for you!'){
          this.$cookies.set('order-cart-session-key',res.data.cartKey);
          this.$cookies.set('order-cart-session-token',res.data.cartToken);
        }
      })
    }

    if(this.$cookies.get('order-cart-session-key')&&this.$cookies.get('order-cart-session-token')){
      let cartKey = this.$cookies.get('order-cart-session-key');
      let cartToken = this.$cookies.get('order-cart-session-token');
        if(this.$cookies.get('tution-student')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
            if(res.data.message == 'Student retrieved successfully'){
              let student = res.data.data.student;
              this.user_id = student.user.user_id;
            }
          });
        }else if(this.$cookies.get('tution-tutor')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-tutor').token}}).then(res => {
            if(res.data.message == 'User found'){
              let tutor = res.data.data.users;
              this.user_id = tutor.user_id;
            }
          });
        }
        
      await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/getcart',{cartKey:cartKey,cartToken:cartToken,user_id:this.user_id},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message == 'Product listed successfully'){
          res.data.Items.forEach(item => {
            if(item.is_active == 'Y'&&item.item_type){
              this.items.push(item)
            }
          });
        }
      });
    }else{
      this.items = [];
    }
    this.getCourses();
    this.getSubjects();
  },
  methods:{
    getSubjects(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/subjects',{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        res.data.Subjects.forEach(subject => {
          if(subject.is_active == 'Y'){
            this.subjects.push({value:subject.subject_id, text:subject.subject_name});
          }
        })
      })
    },
    getCourses(){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/video_courses?page='+this.page+'&per_page='+this.limit,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        this.loading = false;
        if(res.data.courses.data.length>0){
          res.data.courses.data.forEach(course => {
            if(course.is_active == 1 && course.modules.length){
              let flag = false;
              if (this.items.some(e => e.course_id === course.id)) {
                flag = true;
              }

              if(flag == true){
                  course.cart = true;
              }else{
                  course.cart = false;
              }
            }
            this.courses.push(course);
          });
          this.limit = res.data.courses.per_page;
          this.offset = res.data.courses.from-1;
          this.total = res.data.courses.total;
          this.length = Math.ceil(res.data.courses.total/res.data.courses.per_page);
        }
      });
    },
    searchURL(){
      this.urls = [];
      this.courses=[];
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/video_courses?page='+this.page+'&per_page='+this.limit,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.products.data.length>0){
          res.data.courses.data.forEach(course => {
            this.courses.push(course);
          });
          this.limit = res.data.courses.per_page;
          this.offset = res.data.courses.from-1;
          this.total = res.data.courses.total;
          this.length = Math.ceil(res.data.courses.total/res.data.courses.per_page);
        }else{
          this.limit = res.limit;
          this.offset = res.offset;
          this.total = res.total;
          this.urls=[];
        }
      });
    },
    handlePageChange(value) {
        this.page = value;
        this.offset = this.limit * (this.page - 1);
        this.searchURL()
    },
    addToCart(product){
        let cartKey = this.$cookies.get('order-cart-session-key');
        let cartToken = this.$cookies.get('order-cart-session-token');

        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/addcarts',{productID:product.id,quantity:1,cartKey:cartKey,cartId:cartToken,user:this.user_id,item_type:3},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.message == 'Product added to Cart  successfully' || res.data.message == 'The Cart was updated with the given product information successfully'){
            this.$router.push('/course/cart');
          }
        })
      }
  }
}
</script>

<style scoped>
button.btn {
    font-size: 13px;
    height: 50px;
    font-weight: 400;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.zoomimg {
    position: relative;
    overflow: hidden;
}
.vendor-content {
    padding: 16px 16px 12px 16px;
    margin-bottom: 6px;
}
a {
    color: #252328;
    -webkit-font-smoothing: antialiased;
}
.vendor-img img {
    width: 100%;
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.vendor-title {
    font-size: 20px;
    margin: 0px 0px 5px 0px;
}
.vendor-address {
    font-size: 12px;
    font-weight: 400;
    color: #6f6d72;
    line-height: 18px;
}
p {
    margin: 0px 0px 20px 0px;
    -webkit-font-smoothing: antialiased;
}
.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.5;
}
.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.zoomimg:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.female_filter{
  position: absolute;
  right: 0;
  font-size: 15px;
  padding: 5px 0px 5px 0px;
  color: white;
  width: 100%;
  background-color: #ce0d3d;
  text-align: center;
}
</style>