<template>
  <section class="timer-section">
    <div class="timer-block">
      <div class="container-fluid text-center">
        <h2 class="text-white">Your calss start within</h2>
        <p class="text-white" v-if="timeRemaining > 0 && !is_loading">{{ formattedTime() }}</p>
        <p class="text-white" v-else-if="timeRemaining === 0 && !classStarted && !is_loading">Class is starting now!</p>
        <p class="text-white" v-else-if="currentTime < classEndTime  && !is_loading">
          <a :href="envProcessUrl" target="_blank" class="btn btn-warning text-white">Click here to join class</a>
        </p>
        <p class="text-white" v-else-if="!is_loading">Class Ended</p>
        <p class="text-white" v-else>...</p>
      </div>
  </div>
  </section>
</template>

<script>
import axios from 'axios'
var moment = require('moment-timezone');
export default {
  data(){
    return{
      timer: 0, // Initialize the timer with 0 seconds
      is_active:false,
      is_loading:true,
      classStartTime: null, // Replace this with your target time
      classEndTime:null,
      currentTime: new Date(),
      countdownInterval: null,
      classStarted: false,
      class : null,
    }
  },
  computed: {
    timeRemaining() {
      return this.classStartTime - this.currentTime;
    },
    envProcessUrl() {
      return process.env.VUE_APP_LIVE_CLASS_URL+'/'+this.class.video_key; // Adjust the environment variable name
    }
  },
  beforeMount(){
    let token = this.$cookies.get("tution-student")?this.$cookies.get("tution-student").token:(this.$cookies.get("tution-tutor")?this.$cookies.get("tution-tutor").token:(this.$cookies.get("tution-admin")?this.$cookies.get("tution-admin").token:null));
    axios.get(process.env.VUE_APP_TUTION_SITE_API+'/class/'+this.$route.params.key, {headers:{"Authorization":'Bearer '+token}}).then(res => {
      this.is_loading = false;
      this.class = res.data.class;
      if(res.data.status){
        if(res.data.type == 'Student'){
          this.is_active = true;
          this.classStartTime = new Date(this.class.start_date+'T'+this.class.start_time);
          this.classEndTime = new Date(this.class.start_date+'T'+this.class.end_time);
        }else if(res.data.type == 'Tutor'){
          this.is_active = true;
          let student_timezone = this.getTimeZoneByCountry(res.data.student.country.sortname);
          let converted_start_time = moment.tz(this.class.start_date+' '+this.class.start_time, student_timezone).tz(res.data.tutor.timezone);
          let converted_end_time = moment.tz(this.class.start_date+' '+this.class.end_time, student_timezone).tz(res.data.tutor.timezone);

          this.classStartTime = new Date(converted_start_time.format('YYYY-MM-DD')+'T'+converted_start_time.format('HH:mm'));
          this.classEndTime = new Date(converted_end_time.format('YYYY-MM-DD')+'T'+converted_end_time.format('HH:mm'));
        }else if(res.data.type == 'Admin'){
          this.is_active = true;
          this.classStartTime = new Date(this.class.start_date+'T'+this.class.start_time);
          this.classEndTime = new Date(this.class.start_date+'T'+this.class.end_time);
        }else{
          this.is_active = false;
        }
      }else{
        this.is_active = false;
      }
    });
  },
  methods: {
    formattedTime() {
      const seconds = Math.floor(this.timeRemaining / 1000) % 60;
      const minutes = Math.floor(this.timeRemaining / 1000 / 60) % 60;
      const hours = Math.floor(this.timeRemaining / 1000 / 60 / 60) % 24;
      return `${hours} hours ${minutes} minutes ${seconds} seconds`;
    },
    redirectToClass() {
      if (!this.classStarted) {
        this.classStarted = true;
        const newTab = window.open(process.env.VUE_APP_LIVE_CLASS_URL+'/'+this.class.video_key, '_blank');
        newTab.focus();
      }
    },
    getTimeZoneByCountry(countryCode) {
      const timeZones = moment.tz.names();
      for (const timeZone of timeZones) {
        const countryCodes = moment.tz.zone(timeZone).countries();
        if (countryCodes.includes(countryCode)) {
          return timeZone;
        }
      }
      return null;
    },
    checkRedirect() {
      if (this.currentTime >= this.classEndTime) {
        clearInterval(this.countdownInterval);
      } 
      // else if (this.currentTime >= this.classStartTime && !this.classStarted) {
      //   this.redirectToClass(); // Call the method to redirect to the class URL
      // }
    }
  },
  mounted() {
    this.checkRedirect(); // Initial check for redirect
    this.countdownInterval = setInterval(() => {
      this.currentTime = new Date();
      this.checkRedirect(); // Check for redirect on every interval
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  }
}
</script>

<style scoped>
  .timer-section{
      background: url(../assets/images/couple-bg-img.jpg)no-repeat;
      background-size: cover;
      height: 100%;
  }
</style>