<template>
  <section class="payment-section">
    <div class="payment-block">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12" v-show="!payed">
            <v-form class="md-float-material form-material" ref="form" lazy-validation v-model="valid">
              <div class="auth-box card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-md-12">
                      <h5 class="text-center" style="margin-bottom:15px !important;"><a href="/"><img src="../assets/images/logo.png" style="margin-bottom: 5px;pointer-events:none;" ></a><br>Donate Now</h5>
                      <p class="text-center" style="font-size:13px">Support us in Khair ( Al-Khair Academy) or Sponsor a student studies. Your support is much needed and appreciated.
Jazzakhallaahu Khairan</p>
                    </div>
                  </div> 
                  <div class="amount">
                    <v-text-field type="amount" placeholder="$"  v-model="payment.amount" :rules="amountRules" required><template #label><span style="font-size:18px !important;">Amount in USD($)<span class="red--text"><strong> * </strong></span></span></template></v-text-field>
                  </div>
                  <div class="email">
                    <v-text-field type="text" v-model="payment.email"><template #label><span>Email</span></template></v-text-field>
                  </div>
                  <div class="name">
                    <v-text-field type="text" v-model="payment.name"><template #label><span>Name</span></template></v-text-field>
                  </div>
                  <div class="terms">
                    <v-checkbox class="mt-0" v-model="terms"></v-checkbox><span>I agree the <a href="/terms">Terms & Conditions and giving permission to send email to my mail</a></span>
                  </div>
                  <div class="row" v-if="!isPaymen&&terms">
                    <div class="col-md-12">
                      <button class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100" v-on:click.prevent="toPayment"><b>Pay Now</b></button>
                    </div>
                  </div>
                  <div id="paypal-button-container" v-show="isPayment"></div>
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <img src="https://tution-site.s3.ap-south-1.amazonaws.com/test/donate.jpg" alt="donate"><br><br>
                      <p class="text-center color-black">Please share in groups and help us to reach the target amount to help the Masjid ibh umar</p>
                    </div> 
                  </div>-->
                </div>
              </div>
            </v-form>
          </div>
          <div class="col-sm-12" v-show="payed">
            <div class="auth-box card">
              <div class="card-block text-center">
                <h4 class="text-success" style="line-height:30px !important;">Thank you for your contribution. Please check more about our courses</h4>
                <p><strong>Amount : {{ payment.amount }}</strong></p>
                <a href="#" class="btn btn-primary btn-md btn-block waves-effect text-center m-b-20 w-100">Go to Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal id="success" ref="success" size="sm" title=" " body-bg-variant="success" bod body-text-variant="light" hide-footer hide-header centered>
        <div class="container text-center">
          Your payment has been completed successfully.
          <p v-if="payment.email">
            An email has to sent into your account.Kindly check your email for confirmation.
          </p>
        </div>
      </b-modal>
      <b-modal id="danger" ref="error" size="sm" title=" " body-bg-variant="danger" bod body-text-variant="light" hide-footer hide-header centered>
        <div class="container text-center">
          An error occurred while payment processing.
          <p>Please contact admin for further assistance.</p>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
import AWS from "aws-sdk";
import axios from 'axios';
export default {
  name: 'Donation',
  data(){
    return{
      payment:{
        email: null,
        amount: null,
        name:null,
        type: 2,
      },
      terms:true,
      payed: false,
      emailRules:[
        v => !!v || 'Enter valid email',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Enter valid email',
      ],
      amountRules:[
        v => !!v || 'Enter valid amount',
        v => /\D/g.test(v) == false || 'Enter valid amount',
      ],
      loading:false,
      valid: true,
      isPayment:false
    }
  },
  mounted(){
    const script = document.createElement('script');
    script.src="https://www.paypal.com/sdk/js?client-id="+process.env.VUE_APP_CLIENT_ID+"&disable-funding=credit";
    script.addEventListener("load", () => this.setLoaded());
    document.body.appendChild(script);
  },
  methods:{
    toPayment(e){
      e.preventDefault();
      this.valid = this.$refs.form.validate();
      if(this.valid){
        this.isPayment = true;
      }else{
        this.isPayment = false;
      }
    },
    setLoaded: function(){
      var self = this;
        window.paypal.Buttons({
          // Sets up the transaction when a payment button is clicked
          createOrder: function(data, actions) {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: self.payment.amount // Can reference variables or functions. Example: `value: document.getElementById('...').value`
                }
              }]
            })
          },
          // Finalize the transaction after payer approval
          onApprove: function(data, actions) {
          return actions.order.capture().then(function() {
            axios.post(process.env.VUE_APP_TUTION_SITE_API+'/store/payment',self.payment,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
              if(res.data.status === 1){
                if(self.payment.email){
                  var access_key = process.env.VUE_APP_SES_ACCESS_KEY;
                  var secret_key = process.env.VUE_APP_SES_SECRET_KEY;
                  let keys = { accessKeyId: access_key, secretAccessKey: secret_key };
                  AWS.config.update({
                    accessKeyId: keys.accessKeyId,
                    secretAccessKey: keys.secretAccessKey,
                    region: 'ap-south-1'
                  })
                  var params = {
                    Destination: { /* required */
                      // CcAddresses: [
                      //   self.student.user.email,
                      //   /* more items */
                      // ],
                      ToAddresses: [
                        self.payment.email,
                        'admin@fromyourtutor.com'
                        /* more items */
                      ]
                    },
                    Message: { /* required */
                      Body: { /* required */
                        Html: {
                          Charset: "UTF-8",
                          Data: '<table style="width: 600px; margin: 0 auto;" width="600"><tr><td style="text-align: center;"><br><img src="https://tution-site.s3.ap-south-1.amazonaws.com/test/logo.png" alt="logo"><br></td></tr><tr><td><br><table cellpadding="10" style="background:#fff; border-radius: 30px; width:560px; margin:0 auto; "><tr><td style="font-family: arial; font-size:18px; text-align: center; color: #086e00;"><br>Your payment has been successfully completed.</td></tr><tr><td style="font-family: arial; font-size:20px; text-align: center; color: #000;">Paid to <strong>fromyourtutor.com</strong> $'+self.payment.amount+'<br></td></tr><tr><td style="font-family: arial; font-size:16px; text-align: center; color: #000; line-height: 28px;"><br></td></tr><tr><td style="font-family: arial; font-size:16px; text-align: center; color: #000; line-height: 28px;">Name of the student:'+self.payment.name+'</td></tr><tr><td style="font-family: arial; font-size:16px; text-align: center; color: #000; line-height: 28px;">Course enrolled or reason for the payments:'+self.payment.course+'</td></tr></table></td></tr></table>'
                        },
                        // Text: {
                        //   Charset: "UTF-8",
                        //   Data: 'Your order has been successfully placed.',
                        // }
                      },
                      Subject: {
                        Charset: 'UTF-8',
                        Data: 'Payment completed'
                      }
                      },
                    Source: 'info@fromyourtutor.com', /* required */
                    ReplyToAddresses: [
                      'admin@fromyourtutor.com',
                      /* more items */
                    ],
                  };

                  // Create the promise and SES service object
                  var sendPromise = new AWS.SES({apiVersion: '2010-12-01'}).sendEmail(params).promise();

                  // Handle promise's fulfilled/rejected states
                  sendPromise.then(
                    function(data) {
                      if(data.MessageId){
                        self.$refs['success'].show();
                        setTimeout(function(){
                          self.$refs['success'].hide();
                          location.reload();
                        },3000)
                      }
                    }).catch(
                    function(err) {
                      console.error(err, err.stack);
                  });
                }else{
                  self.$refs['success'].show();
                  setTimeout(function(){
                    self.$refs['success'].hide();
                    location.reload();
                  },3000)
                }
              }else{
                self.$refs['error'].show();
                setTimeout(function(){
                  self.$refs['error'].hide();
                  location.reload();
                },3000)
              }
            });
            // var element = document.getElementById('paypal-button-container');
            // element.innerHTML = '';
            // element.innerHTML = '<h5>you\'re payment has been completed successfully.</h5>';
            self.payed = true;
          });
          }
        }).render('#paypal-button-container');
    }
  }
}
</script>
<style scoped>
.payment-section{
    background: url(../assets/images/couple-bg-img.jpg)no-repeat;
    background-size: cover;
    height: 100%;
}
.payment-block {
    margin: 30px auto;
    min-height: 93.6vh;
}
.color-black{
  color: #000;
}
.payment-block .auth-box {
    margin: 20px auto 0;
    max-width: 450px !important;
}
.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
    border: none;
    margin-bottom: 30px;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #fff;
}
.card .card-block {
    padding: 1.25rem;
}
h5 {
    font-size: 16px;
    line-height: 26px;
}
.btn-primary:hover {
    color: #fff;
    background-color: #03a28f;
    border-color: #03a28f;
}
.btn:hover {
  color: #ffa25f;
}
.btn-lg {
    font-size: 14px;
    line-height: 1.3333333;
}
img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    line-height: normal;
    vertical-align: middle;
}
a:active, a:hover {
    outline: 0;
    text-decoration: none;
}
a:hover {
    color: #ce0d3d;
    text-decoration: none;
}
a {
    color: #252328;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}
.terms{
  display: flex;
  flex-direction: row;
}
</style>