<template>
  <div>
    <video ref="videoPlayer" class="video-js" style="width:100%;"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from 'axios';
export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
  },
  data() {
    return {
      player: null,
      id: null,
    }
  },
  mounted() {
    this.id = this.$vnode.key;
    this.getPlayer();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    getPlayer(){
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        // this.player.log('onPlayerReady', this.id);
        if(this.$route.path.split('/')[1] == 'student'){
          this.token = this.$cookies.get('tution-student').token;
          var self = this;
          this.player.on("ended", function(){ 
            axios.put(process.env.VUE_APP_TUTION_SITE_API+'/activate_module/'+self.id,{},{headers:{"Authorization":'Bearer '+self.token}}).then(res => {
              if(res.data.message == 'updated'){
                self.$emit('activated', res.data.module);
              }
            });
          });
        }else if(this.$route.path.split('/')[1] == 'order'){
          self = this;
          this.player.on("ended", function(){ 
            axios.put(process.env.VUE_APP_TUTION_SITE_API+'/order/activate_module/'+self.id,{key:self.$route.params.transactionID},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
              if(res.data.message == 'updated'){
                self.$emit('activated', res.data.module);
              }
            });
          });
        }
      });
    }
  }
}
</script>