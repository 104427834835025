<template>
  <div class="study_materials">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="font-size:14px; text-align:center; line-height:25px; color:#979797">
            <div class="certification">
            <p><i class="fa fa-quote-left"></i> Al-hamdu lillah I can access it and I'm working
              my way through each one, very impressed so
              far, *may Allah make these resources of great
              benefit*.<i class="fa fa-quote-right"></i> </p>
              <p><i class="fa fa-quote-left"></i> Allahumma Bärik I found the content to be
              excellent and very well thought out and put
              together with the focus being to nurture
              children upon the true fitrah! Best of all it is
              all correct and authentic. May Allah bless
              all those involved for their efforts and
              hardwork. in shã Alläh I will continue to
              look through the rest as you upload them
              to the drive and provide any feedback. <i class="fa fa-quote-right"></i> </p>
              <p>Student of knowledge United Kingdom</p></div>
          </div>
        </div>
        <div class="row product-details">
            <div class="col-md-6">
                <carousel
                    :starting-image="2"
                    :images="images"
                    v-if="this.images.length"
                    :auto-slide-interval="10000"
                    :show-progress-bar="true"
                ></carousel>
            </div>
            <div class="col-md-6" v-if="book">
                <h1>{{book.title}}</h1>
                <p>Type: <span class="text-success">{{book.type}} </span>| Format: <span class="text-success">{{book.format}}</span></p>
                <div class="price" v-if="book.price.startsWith('$')">{{book.price}}</div>
                <div class="price" v-else>${{book.price}}</div>
                <p><a href="" class="btn btn-success btn-sm mr-1" @click.prevent="addToCart(book)" v-if="!book.cart">Add to Cart</a> 
                <a href="/cart" class="btn btn-success btn-sm mr-1" v-if="book.cart">Go to cart</a>
                <a :href="book.demo" class="btn btn-outline-primary" v-if="book.demo">Download Demo</a></p>
                <p v-if="book.details">{{book.details}}</p>
            </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p class="text-center shopCopyright">Copyright 2021 Fromyourtutor.com. All Rights Reserved <br>
              No part of this may be reproduced in any language,
              printed in any form or any electronic or mechanical
              means including but not limited to photocopying,
              recording, or otherwise, without specific verbal or
              written consent from Fromyourtutor.Com
              <br>
              Contact us at:
              info@fromyourtutor.com
              admin@fromyourtutor.com</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="carousel.js"></script>
<script>
import axios from 'axios'
import Carousel from "@/components/dist/Carousal.vue";
export default {
  name: "studyMaterials",
  components:{
    Carousel
  },
  data(){
    return {
      user_id:null,
      product:null,
      book:null,
      items:[],
      images: []
    }
  },
  async beforeMount(){
    window.scrollTo(0, 0);
    this.getMaterial(this.$route.query.id);
    if(this.$cookies.get('cart-session-key')&&this.$cookies.get('cart-session-token')){
      console.log('');
    }else{
      axios.post(process.env.VUE_APP_TUTION_SITE_API+'/cart',{},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.Message == 'A new cart have been created for you!'){
          this.$cookies.set('cart-session-key',res.data.cartKey);
          this.$cookies.set('cart-session-token',res.data.cartToken);
        }
      })
    }

    if(this.$cookies.get('cart-session-key')&&this.$cookies.get('cart-session-token')){
      let cartKey = this.$cookies.get('cart-session-key');
      let cartToken = this.$cookies.get('cart-session-token');
        if(this.$cookies.get('tution-student')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/student',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-student').token}}).then(res => {
            if(res.data.message == 'Student retrieved successfully'){
              let student = res.data.data.student;
              this.user_id = student.user.user_id;
            }
          });
        }else if(this.$cookies.get('tution-tutor')){
          await axios.get(process.env.VUE_APP_TUTION_SITE_API+'/tutor/me',{headers:{"Authorization":'bearer '+this.$cookies.get('tution-tutor').token}}).then(res => {
            if(res.data.message == 'User found'){
              let tutor = res.data.data.users;
              this.user_id = tutor.user_id;
            }
          });
        }
        
        await axios.post(process.env.VUE_APP_TUTION_SITE_API+'/getcart',{cartKey:cartKey,cartToken:cartToken,user_id:this.user_id},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data.message == 'Product listed successfully'){
          res.data.Items.forEach(item => {
            if(item.is_active == 'Y'){
              this.items.push(item)
            }
          });
        }
      });
    }else{
      this.items = [];
    }
  },
  methods:{
    getMaterial(id){
      axios.get(process.env.VUE_APP_TUTION_SITE_API+'/material/'+id,{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
        if(res.data&&res.data.data.is_active=='Y'&&res.data.data.uploaded_file){
          this.product = res.data.data;
          this.product.thumbnail.split(' ').forEach((item,index)=>{
            this.images.push({id:index+1,big:item,thumb:item});
          })
          console.log(this.images);
          let flag = false;
          if(this.items.length > 0){
            this.items.forEach((item,index) => {
              if(this.product.product_id == item.product_id){
                flag = true;
                this.items.splice(index,1)
              }
            })
            if(flag == true){
              this.book = {id:this.product.product_id, title:this.product.name,price:this.product.price,type:this.product.type,format:this.product.file_format.name,thumbnail:this.product.thumbnail,details:this.product.details,demo:this.product.demo,cart:true}
            }else{
              this.book = {id:this.product.product_id, title:this.product.name,price:this.product.price,type:this.product.type,format:this.product.file_format.name,thumbnail:this.product.thumbnail,details:this.product.details,demo:this.product.demo,cart:false}
            }
          }else{
            this.book = {id:this.product.product_id, title:this.product.name,price:this.product.price,type:this.product.type,format:this.product.file_format.name,thumbnail:this.product.thumbnail,details:this.product.details,demo:this.product.demo,cart:false}
          }
        }else{
          this.book = null;
        }
      });
    },

    addToCart(product){
        let cartKey = this.$cookies.get('cart-session-key');
        let cartToken = this.$cookies.get('cart-session-token');

        axios.post(process.env.VUE_APP_TUTION_SITE_API+'/addcarts',{productID:product.id,quantity:1,cartKey:cartKey,cartId:cartToken,user:this.user_id,item_type:2},{headers:{"Authorization":'Bearer '+process.env.VUE_APP_GUEST_TOKEN}}).then(res => {
          if(res.data.message == 'Product added to Cart  successfully' || res.data.message == 'The Cart was updated with the given product information successfully'){
            this.$router.push('/cart');
          }
        })
      }
      // else{
      //   // this.$cookies.set("previousURL", {location: window.location.href, path:'/study_materials'});
      //   // this.$router.push('/login');
      //   this.$router.replace({name:'student-login', params:{return:true}});
      // }
    // }
  }
}
</script>
<style>
.card-carousel .thumbnails .thumbnail-image{
  width: 140px !important;
}
</style>

<style scoped>

button.btn {
    font-size: 13px;
    height: 50px;
    font-weight: 400;
}
.btn-default {
    color: #fff;
    background-color: #0079df;
    border-color: #0079df;
}
.btn {
    padding: 10px 20px !important;
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 2px;
    text-transform: uppercase !important;
    letter-spacing: 1px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    word-wrap: break-word;
    white-space: normal !important;
}
.btn-default:hover{
    color: #fff;
    background-color: #e93c3c;
    border-color: #e93c3c;
}
.zoomimg {
    position: relative;
    overflow: hidden;
}
.vendor-content {
    padding: 16px 16px 12px 16px;
    margin-bottom: 6px;
}
a {
    color: #252328;
    -webkit-font-smoothing: antialiased;
}
.vendor-img img {
    width: 100%;
}
.zoomimg img {
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.vendor-title {
    font-size: 20px;
    margin: 0px 0px 5px 0px;
}
.vendor-address {
    font-size: 12px;
    font-weight: 400;
    color: #6f6d72;
    line-height: 18px;
}
p {
    margin: 0px 0px 20px 0px;
    -webkit-font-smoothing: antialiased;
}
.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 1.5;
}
.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.zoomimg:hover img {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>